/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class SelectedTests.js
 * This JS file is intended to create a component that showing the selected test list
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { TestPackageListIcon } from "../../../../assets/icons";
import { useSelector } from "react-redux";

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
  },
  card: {
    margin: "10px",
    "& .MuiCardHeader-title": {
      color: "#634699",
      fontWeight: "bold",
    },
  },
  button: {
    "& .MuiButton-root": {
      backgroundColor: "#fff",
      border: "1px solid #634699",
      color: "#000",
    },
  },
});

function Row(props) {
  const { row } = props;
  const CLASSES = USE_STYLES();
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <TestPackageListIcon className={CLASSES.icon} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          style={{ fontWeight: "bold", color: "#634699" }}
        >
          {row.name}
          <span
            style={{ fontWeight: "bold", color: "gray", marginLeft: "10px" }}
          >
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: row?.sectioncurrency,
              maximumSignificantDigits: 3,
            }).format(row?.sectioncost)}
          </span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SelectedTests = () => {
  const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );

  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );
  const getListToRender = () => {
    return customizedList ? customizedList : filteredContents;
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} m={2}>
        {getListToRender() && getListToRender().length ? (
          getListToRender().map(
            (topic, index) =>
              topic.section !== 0 &&
              (topic.modules && topic.modules.length !== 0 ? (
                <TableContainer component={Paper} key={index}>
                  <Table key={index}>
                    <TableBody>
                      <Row key={topic.id} row={topic} />
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null)
          )
        ) : (
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell
              component="th"
              scope="row"
              style={{ fontWeight: "bold", color: "#634699" }}
            >
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
              >
                no tests found
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </Grid>
    </Grid>
  );
};

export default SelectedTests;
