/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class OrderConfirmation.js
 * This JS file is intended for creating component that giving the layout of  the order confirmation screen.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { useHistory,useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import SelectedTests from "./SelectedTests";
import OrderConfirmationText from "./OrderConfirmationText";
import OrderBill from "./OrderBill";
import { useDispatch, useSelector } from "react-redux";
import { resetCustomizedList } from "../../../../webservice/students/actions/TestPackageListAction";
import { Scrollbars } from "react-custom-scrollbars-2";

const USE_STYLES = makeStyles({
  OrderConfirmationContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
});

const ORDERCONFIRMATION = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  // eslint-disable-next-line no-unused-vars
  const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const CLASSES = USE_STYLES();
  const backClickHandler = () => {
    dispatch(resetCustomizedList());
    history.goBack();
  };
  return (
    <Scrollbars style={{ height: 450 }}>
      <Box px={2}>
        <Grid item xs={12}>
          <Button
            sx={{ color: "#634699", fontWeight: "bold", textTransform: "none" }}
            onClick={backClickHandler}
          >
            Back
          </Button>
        </Grid>
        <Grid
          container
          spacing={2}
          className={CLASSES.OrderConfirmationContainer}
        >
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} md={8}>
                <OrderConfirmationText />
              </Grid>
              <Grid item xs={12} md={8}>
                <SelectedTests />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} md={4}>
                <OrderBill />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
};

export default ORDERCONFIRMATION;
