/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class SuccessAttemptNew.jsx
 * This JSX file is intended to give a Timeup popup screen after Test Attempt.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 20 April 2023
 */
import { Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import successattempt from "./../../../../assets/images/Timeupnew.png";
import { useSelector } from "react-redux";

const USE_STYLES = makeStyles(() => ({
  examContainer: {
    paddingTop: "65px",
    paddingBottom: "65px",
    justifyContent: "center",
  },
  testContainer: {
    padding: "1%",
    width: "698px",
    boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    background: "#FFFFFF",
  },
}));

const TimerExpiredNew = () => {
    const HISTORY = useHistory();
  const CLASSES = USE_STYLES();
  const { validateTestCode } = useSelector((state) => state.guestAccessState);

  const handleClick = () => {
    if (validateTestCode?.usertype === "offline") {
      HISTORY.push("/login/mocktestform");
    } else {
      HISTORY.push("/my-test-packages");
    }
  };
    return (
        <div>
        <Grid container item xs={12} className={CLASSES.examContainer}>
          <Card className={CLASSES.testContainer}>
            <Grid
              container item
              style={{ marginBottom: "20px", marginTop: "35px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={4} />
              <Grid item xs={3.7}>
                <img
                  src={successattempt}
                  alt="success"
                  width="175.06px"
                  height="180.08px"
                />
              </Grid>
              <Grid item xs={4} />
            </Grid>
            <Typography
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "140%",
                textAlign: "center",
              }}
            >
             Times UP! 
            </Typography>
            <Typography
              sx={{
                color: "#634699",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
              {" "}
              Your test has been submitted ,Your results will be notified to you!{" "}
            </Typography>
            <Typography
              sx={{
                color: "#634699",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
              Till then....
              <span
                style={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "140%",
                  textAlign: "center",
                }}
              >
                ENJOY!
              </span>
            </Typography>
            <Typography
              sx={{
                color: "Black",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
              ALL THE BEST !{" "}
            </Typography>
  
            <Grid
              container item
              sx={{ marginBottom: "25px", marginTop: "15px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  textTransform: "none",
                  width: "177px",
                  height: "35px",
                  backgroundColor: "#634699",
                  border: "1px solid #634699",
                  borderRadius: "5px",
                  minWidth: "4px",
                  align: "center",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
                }}
              >
                {" "}
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  Go Home
                </Typography>
              </Button>
            </Grid>
          </Card>
        </Grid>
      </div>
    );
}

export default TimerExpiredNew;
