/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestAttemptScreen.js
 * This JS file is for showing the test attempt screen
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Typography,
  Card,
  LinearProgress,
  linearProgressClasses,
  Button,
  Dialog,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import LOGO from "../../../main/ui_component/logo";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QUESTION_SCREEN from "./QuestionScreen";
import {
  finishAttempt,
  reviewTestAttempt,
  resetAnswers,
  fetchQuizStatus,
} from "../../../../webservice/students/actions/TestAttemptAction";
import TimerExpired from "./TimerExpired";
import { fetchQuizDetails } from "../../../../webservice/students/actions/MyTestPackageAction";

const USE_STYLES = makeStyles({
  testScreen: {
    backgroundColor: "#F8F9FA",
    padding: "10px",
  },
  logoDiv: {
    paddingLeft: "10%",
    paddingTop: "15px",
    textAlign: "center",
  },
  logoContainer: {
    padding: "10px",
    height: "60px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  examContainer: {
    paddingLeft: "10%",
    paddingTop: "15px",
  },
  testContainer: {
    padding: "5%",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  attemptedquestion: {
    border: "1px solid #634699",
    width: "40px",
    height: "40px",
    padding: "10px",
    textAlign: "center",
    borderRadius: "2px",
    margin: "5px",
  },
  skippedquestion: {
    border: "1px solid #634699",
    width: "40px",
    height: "40px",
    padding: "10px",
    textAlign: "center",
    color: "#000",
    opacity: "0.4",
    borderRadius: "2px",
    margin: "5px",
  },
  linearProgress: {
    marginTop: "12px",
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFF1F3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#634699",
    },
  },
});
let timeInterval;

const TEST_ATTEMPT_SCREEN = (props) => {
  const params = useParams();
  const quizid = params.quizid;
  const { quizList } = useSelector((state) => state.mytestpackagestate);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { questionList } = useSelector((state) => state.testAttemptState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const [showConfirmationScreen, setConfirmationScreen] = useState("ongoing");
  const [question, setQuestion] = useState("");
  const [maxQuestion, setmaxQuestion] = useState("");
  const [ reviewArray,setReviewArray ] = useState(new Array(questionList?.length).fill(false));
  const { userid } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  const attemptidd = quizStatus?.attemptid
    ? quizStatus?.attemptid
    : startattempt?.attempt?.id;
  const CLASSES = USE_STYLES();

  const collectValue = (current, max) => {
    setQuestion(current);
    setmaxQuestion(max);
  };

  useEffect(() => {
    dispatch(fetchQuizDetails());
    dispatch(fetchQuizStatus(userid, quizid));
  }, []);
  const [countdown, setCountDown] = useState(0);

  useEffect(() => {
    let isMounted = true;
    timeInterval = setInterval(() => {
      isMounted && setCountDown((prevCountdown) => prevCountdown + 1);
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, []);

  function fancyTimeFormat(duration) {
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const handleSubmit = (id) => {
    dispatch(finishAttempt(attemptidd));
    dispatch(resetAnswers());
    props?.onClose();
  };

  const handleReview = () => {
    setConfirmationScreen("ongoing");
  };

  useEffect(() => {
    if (showConfirmationScreen === "submitted" && attemptidd)
      dispatch(reviewTestAttempt(attemptidd));
  }, [showConfirmationScreen]);

  const { reviewAttempt } = useSelector((state) => state.testAttemptState);

  return (
    <Grid container className={CLASSES.testScreen} direction="column">
      <Grid item>
        <Grid container className={CLASSES.logoDiv}>
          <Grid item xs={6} sm={4} md={2}>
            <Card className={CLASSES.logoContainer}>
              <LOGO width="100%" />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={CLASSES.examContainer}>
          <Grid item xs={10}>
            <Card className={CLASSES.testContainer}>
              <Grid container spacing={2}>
                {quizList && quizList.quizzes && quizList.quizzes.length
                  ? quizList.quizzes.map((quiz) => {
                      if (quiz.id === parseInt(quizid))
                        if (countdown === quiz.timelimit) {
                          clearInterval(timeInterval);
                          if (showConfirmationScreen !== "expired")
                            setConfirmationScreen("expired");
                        }
                      return quiz.id === parseInt(quizid) ? (
                        <Fragment key={quiz.id}>
                          {showConfirmationScreen === "submitted" ? (
                            <>
                              <Grid item xs={7} />
                              <Grid item xs={0}>
                                <Typography
                                  variant="h2"
                                  sx={{
                                    fontWeight: 400,
                                    fontStyle: "regular",
                                    color: "#000000",
                                    fontSize: "24px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  Time
                                  <span
                                    style={
                                      quiz.timelimit - countdown > 10
                                        ? {
                                            fontWeight: 700,
                                            fontStyle: "regular",
                                            color: "#634699",
                                            fontSize: "24px",
                                            lineHeight: "33.6px",
                                          }
                                        : {
                                            fontWeight: 700,
                                            fontStyle: "regular",
                                            color: "red",
                                            fontSize: "24px",
                                            lineHeight: "33.6px",
                                          }
                                    }
                                  >
                                    {fancyTimeFormat(
                                      quiz.timelimit - countdown
                                    )}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2} textAlign="center">
                                  <Grid item xs={12}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontStyle: "regular",
                                        color: "#634699",
                                        fontSize: "40px",
                                        lineHeight: "56px",
                                      }}
                                    >
                                      CAUTION!
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontStyle: "regular",
                                        color: "#000000",
                                        fontSize: "26px",
                                      }}
                                    >
                                      Are you sure you want to submit test?
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {reviewAttempt &&
                                    reviewAttempt?.questions?.length
                                      ? reviewAttempt.questions?.map(
                                          (question, index) =>
                                            reviewArray[index]==true ? (
                                                question.status ===
                                                "Answer saved" ? (
                                                  <Grid
                                                    key={index}
                                                    item
                                                    className={
                                                      CLASSES.attemptedquestion
                                                    }
                                                    sx={{
                                                      position: 'relative',
                                                      '&:before': {
                                                        backgroundColor: "red",
                                                        position: 'absolute',
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        height: 5,
                                                        content: '""',
                                                        zIndex: 10,
                                                      },
                                                    }}
                                                  >
                                                    {question.slot}
                                                  </Grid>
                                                ) : question.status ===
                                                  "Not yet answered" ? (
                                                  <Grid
                                                    key={index}
                                                    item
                                                    className={
                                                      CLASSES.skippedquestion
                                                    }
                                                    sx={{
                                                      position: 'relative',
                                                      '&:before': {
                                                        backgroundColor: "red",
                                                        position: 'absolute',
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        height: 5,
                                                        content: '""',
                                                        zIndex: 10,
                                                      },
                                                    }}
                                                  >
                                                    {question.slot}*
                                                  </Grid>
                                                ) : null
                                              
                                            ) : (
                                              question.status ===
                                                "Answer saved" ? (
                                                  <Grid
                                                    key={index}
                                                    item
                                                    className={
                                                      CLASSES.attemptedquestion
                                                    }
                                                  >
                                                    {question.slot}
                                                  </Grid>
                                                ) : question.status ===
                                                  "Not yet answered" ? (
                                                  <Grid
                                                    key={index}
                                                    item
                                                    className={
                                                      CLASSES.skippedquestion
                                                    }
                                                  >
                                                    {question.slot}*
                                                  </Grid>
                                                ) : null
                                            )
                                          
                                        )
                                      : null}
                                  </Grid>

                                  <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                  >
                                    <Button
                                      onClick={handleSubmit}
                                      variant="contained"
                                      sx={{
                                        width: "20%",
                                        backgroundColor: "#634699",
                                        "&:hover": {
                                          backgroundColor: "#634699",
                                        },
                                        padding: "10px 40px",
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                  >
                                    <Button
                                      onClick={handleReview}
                                      variant="contained"
                                      sx={{
                                        width: "20%",
                                        color: "#634699",
                                        backgroundColor: "#F8F9FA",
                                        "&:hover": {
                                          backgroundColor: "#F8F9FA",
                                        },
                                        padding: "10px 40px",
                                      }}
                                    >
                                      Review
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : showConfirmationScreen === "expired" ? (
                            <TimerExpired submit={handleSubmit} />
                          ) : (
                            <>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant="h2"
                                  sx={{
                                    fontWeight: 700,
                                    fontStyle: "regular",
                                    color: "#634699",
                                    fontSize: "40px",
                                    lineHeight: "56px",
                                  }}
                                >
                                  {quiz.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={2} />
                              <Grid item xs={12} md={4}>
                                <Typography
                                  variant="h2"
                                  sx={{
                                    fontWeight: 400,
                                    fontStyle: "regular",
                                    color: "#000000",
                                    fontSize: "24px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  time
                                </Typography>
                                <span
                                  style={
                                    quiz.timelimit - countdown > 10
                                      ? {
                                          fontWeight: 700,
                                          fontStyle: "regular",
                                          color: "#634699",
                                          fontSize: "24px",
                                          lineHeight: "33.6px",
                                        }
                                      : {
                                          fontWeight: 700,
                                          fontStyle: "regular",
                                          color: "red",
                                          fontSize: "24px",
                                          lineHeight: "33.6px",
                                        }
                                  }
                                >
                                  {questionList[0]?.message ===
                                  "No questions in quiz" ? (
                                    <Fragment>00:00</Fragment>
                                  ) : (
                                    fancyTimeFormat(quiz.timelimit - countdown)
                                  )}
                                </span>
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <Typography
                                  variant="h2"
                                  sx={{
                                    fontWeight: 700,
                                    fontStyle: "regular",
                                    color: "#634699",
                                    fontSize: "24px",
                                    lineHeight: "33.6px",
                                  }}
                                >
                                  Progress
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <LinearProgress
                                  className={CLASSES.linearProgress}
                                  variant="determinate"
                                  value={(question / maxQuestion) * 100}
                                />
                              </Grid>
                              <Grid item xs={3} sm={4} md={2}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {question}/{maxQuestion}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <QUESTION_SCREEN
                                  takeCurrentQuestion={(
                                    currentValue,
                                    maxValue
                                  ) => {
                                    collectValue(currentValue, maxValue);
                                  }}
                                  onConfirmation={(confirm) => {
                                    setConfirmationScreen(confirm);
                                  }}
                                  onReview={() => {
                                    setConfirmationScreen("ongoing");
                                  }}
                                  onRefreshPage={handleSubmit}
                                  reviewArray={reviewArray}
                                  changeReviewArray={setReviewArray}
                                />
                              </Grid>
                            </>
                          )}
                        </Fragment>
                      ) : null;
                    })
                  : null}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TEST_ATTEMPT_SCREEN;
