/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestDetailCard.js
 * This JS file is intended to create a card component that showing the details of a particular test package.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseById,
  fetchCoursePrice,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const USE_STYLES = makeStyles({
  cardAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    background: "#634699",
    textTransform: "none",
    paddingLeft: "50px",
    paddingRight: "50px",
    "&:hover": { backgroundColor: "#634699" },
  },
});

const TEST_DETAIL_CARD = () => {
  const CLASSES = USE_STYLES();
  const params = useParams();
  const courseid = params.id;
  const dispatch = useDispatch();
  const { CourseById } = useSelector((state) => state.testPackageListReducer);
  const { courseprice } = useSelector((state) => state.testPackageListReducer);
const { coursecontents } = useSelector(
  (state) => state.testPackageListReducer
);

const Checkalreadyenroll = coursecontents && coursecontents.length ? coursecontents
  .filter(topic => topic.section !== 0 && topic.modules && topic.modules.length !== 0)
  .map(topic => {
    return topic.secvisible;
  }) : [];



const isButtonDisabled = Checkalreadyenroll.includes(true);
  useEffect(() => {
    dispatch(fetchCourseById(courseid));
    return () => {
      dispatch(fetchCourseById(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(fetchCoursePrice(courseid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        {courseprice && courseprice.length
          ? courseprice.map((prices, index) => (
              <Fragment key={index}>
                {CourseById && CourseById.courses.length
                  ? CourseById.courses.map((datas, index) => (
                      <Fragment key={index}>
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={6} p={1}>
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "30px",
                                    lineHeight: "56px",
                                  }}
                                >
                                  {datas.displayname}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={6}
                                display="flex"
                                justifyContent="flex-end"
                                p={1}
                              >
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "30px",
                                    lineHeight: "56px",
                                  }}
                                >
                                  {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: prices?.currency,
                                    maximumSignificantDigits: 3,
                                  }).format(prices?.cost)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6} p={1}>
                                <Typography
                                  sx={{
                                    color: "#b5b4b5",
                                    fontWeight: "300",
                                    fontStyle: "normal",
                                    fontSize: "19.37px",
                                    lineHeight: "27.11px",
                                  }}
                                >
                                  {datas.summary.replace(/<[^>]+>/g, "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={6}
                                display="flex"
                                justifyContent="flex-end"
                                p={1}
                              >
                                <CardActions
                                  disableSpacing
                                  className={CLASSES.cardAction}
                                >
                                  <Button
                                    component={Link}
                                    to={`/test-packages/orderconfirmation/${datas.id}`}
                                    variant="contained"
                                    className={CLASSES.button}
                                    disabled={isButtonDisabled}
                                  >
                                    Enroll
                                  </Button>
                                </CardActions>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Fragment>
                    ))
                  : null}
              </Fragment>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default TEST_DETAIL_CARD;
