/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class MatchNew.jsx
 * This JSX file is for showing the Match Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 03 Feb 2023
 */
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import parse from "html-react-parser";
import { List, Button } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import "./style.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { romanNumbers } from "../../../../utils/AppConstants";

const USE_STYLES = makeStyles(() => ({
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

const MatchNew = ({
  question,
  thirdLevelIndex,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  secondLevelIndex,
  show,
  type,
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const CLASSES = USE_STYLES();

  const [items, setItems] = useState({
    left: [],
    right: [],
  });
useEffect(() => {
    // Reset the items state when the question changes
    setItems({
      left: question.matchoptions.columna.map((content, index) => ({
        id: `left-${index}`,
        content: content,
      })),
      right: question.matchoptions.columnb.map((item) => ({
        id: `right-${item.index}`,
        content: item.value ||item,
      })),
    });
  }, [question]);
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || destination.droppableId === "left" || source.droppableId === "left") {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const column = [...items[source.droppableId]];
    const [removed] = column.splice(source.index, 1);
    column.splice(destination.index, 0, removed);

    setItems((prevState) => ({
      ...prevState,
      [source.droppableId]: column,
    }));

    const updatedAnswerList = column.map((item) => {
      const rightItem = question.matchoptions.columnb.find((rItem) => rItem.index === parseInt(item.id.split("-")[1]));
      return rightItem ? rightItem.index : null;
    });
    

    // Update the answer list in your state
    if (questionStatusResponse[count]?.type === "multiquestion") {
      questionStatusResponse[count].subQuestions[secondLevelIndex].answer = updatedAnswerList;
      questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answered";
    } else {
      questionStatusResponse[count].answer = updatedAnswerList;
    }
  };

  // useEffect(() => {
  //   let updatedList = [];

  //   if (questionStatusResponse[count]?.type === "multiquestion") {
  //     updatedList = questionStatusResponse[count].subQuestions[secondLevelIndex]?.answer || [];
  //   } else {
  //     updatedList = questionStatusResponse[count]?.answer || [];
  //   }

  //   const leftColumn = question.matchoptions.columna.map((content, index) => ({
  //     id: `left-${index}`,
  //     content: content,
  //   }));

  //   const orderedColumn = leftColumn.sort((a, b) => {
  //     const aIndex = updatedList.indexOf(a.content);
  //     const bIndex = updatedList.indexOf(b.content);
  //     return aIndex - bIndex;
  //   });

  //   setItems((prevState) => ({
  //     ...prevState,
  //     left: orderedColumn,
  //   }));
  // }, [count, questionStatusResponse, question.matchoptions.columna, secondLevelIndex]);

  return (
    <Grid container sx={{ marginTop: "2%" }}>
      <Grid item xs={1.5} md={1.5} />
      <Grid item xs={5.5} md={7.5}>
        {/* <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "10px",
          }}
        >
          {count + 1}.
        </Typography> */}
        {/* <Typography
          variant="h2"
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "16px",
            lineHeight: "150%",
            paddingBottom: "15px",
          }}
        >
          Choose the correct Answer:
        </Typography> */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "10px",
          }}
        >
          {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
        </Typography>{" "}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "16px",
            lineHeight: "150%",
            paddingBottom: "30px",
          }}
        >
          {parse(question?.submqtext || question?.questiontext || question.question)}
        </Typography>
        <>{show ? <Grid item container className={CLASSES.divContainer} /> : null}</>
        {!show ? (
          ""
        ) : (
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            Answer
          </Typography>
        )}
        {!show ? (
          ""
        ) : (
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "14px",
              lineHeight: "140%",
              paddingBottom: "15px",
              // textAlign:"center"
            }}
          >
            Arrange&nbsp;
            <span
              style={{
                fontWeight: 400,
                fontStyle: "regular",
                color: "#000000",
                fontSize: "14px",
                lineHeight: "140%",
                paddingBottom: "15px",
                // textAlign:"center"
              }}
            >
              the appropriate objects (In column B ) to the respective statements (in Column A )
            </span>
          </Typography>
        )}
        <br />
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              width: question?.submqtext || question?.questiontext ? "100%" : "170%",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="match-the-following">
                <div className="column">
                  {items.left.map((item) => (
                    <div key={item.id} className="row">
                      {/* {item.content.includes('<img') ? (
      <img
        src={item.content.match(/src="(.*?)"/)[1]}
        width="100%" // Replace with desired width
        height="100%" // Replace with desired height
      />
    ) : (
      parse(item.content)
    )} */}
                      {/* {item.content.includes('<img') ? (
      <>
        <img
          src={item.content.match(/src="(.*?)"/)[1]}
          width="100%" // Replace with desired width
          height="100%" // Replace with desired height
        />
        {parse(item.content.replace(/<img(.*?)\/?>/g, ''))}
      </>
    ) : (
      parse(item.content)
    )} */}
                      {item.content.includes("<img") ? (
                        <>
                          <div style={{ height: "70%" }}>
                            <img src={item.content.match(/src="(.*?)"/)[1]} style={{ width: "100%", height: "100%" }} />
                          </div>
                          <Typography
                            variant="h2"
                            sx={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#634699",
                              fontSize: "14px",
                              // paddingLeft: "10px",
                            }}
                          >
                            {parse(item.content.replace(/<img(.*?)\/?>/g, ""))}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: "14px",
                            // paddingLeft: "10px",
                          }}
                        >
                          {parse(item.content)}
                        </Typography>
                      )}
                    </div>
                  ))}
                </div>

                <Droppable droppableId="right">
                  {(provided) => (
                    <div
                      style={{ left: "450px", position: "absolute" }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="column"
                    >
                      {items.right.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!show}>
                          {(provided,snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="rows"
                              style={{
                                backgroundColor: snapshot.isDragging ? "#634699" : "#fff",

                                color: snapshot.isDragging ? " #fff" : "#634699",

                                ...provided.draggableProps.style,
                              }}
                            >
                              <Typography
                                variant="h2"
                                sx={{
                                  fontWeight: 400,
                                  fontStyle: "regular",
                                  fontSize: "14px",
                                  // paddingLeft: "10px",
                                  // color: "#634699",
                                  // textAlign:"left"
                                }}
                              >
                                {item.content}
                              </Typography>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchNew;
