/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class ShortAnswerNew.jsx
 * This JSX file is for showing the ShortAnswer Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 02 Feb 2023
 */
import React from "react";
import { Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { romanNumbers } from "../../../../utils/AppConstants";

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ck-editor__main > .ck-editor__editable": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "&.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        color: "#634699",
        minHeight: "150.1px",
        borderColor: "#634699",
        border: "0.771363px solid #634699",
        borderRadius: "0px 0px 9.25635px 9.25635px",
        background: "transparent",
        "&.ck .ck-placeholder:before": {
          color: "#634699",
          fontWeight: 400,
          fontStyle: "regular",
          fontSize: "13.8568px",
          lineHeight: "140%",
        },
      },
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "9.25635px 9.25635px 0px 0px",
    },
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

const ShortAnswerNew = ({
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  show,
  secondLevelIndex,
  level,
  thirdLevelIndex,
  setthirdLevelIndex,
  type,
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  const classes = useStyles();
  const [editorData, setEditorData] = useState("");
  const HANDLE_CHANGE = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    handleChange(data);
  };
  useEffect(() => {
    if (level === 2) {
      setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex].answer);
    } else if (level === 1) {
      setEditorData(questionStatusResponse[count]?.answer);
    } else if (level === 3) {
      setEditorData(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
    }
  }, [count, secondLevelIndex, thirdLevelIndex, level]);
  return (
    <div>
      <Grid container sx={{ marginTop: "2%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.2} md={7.3}>
          {/* <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            1.
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "15px",
            }}
          >
            Answer the following questions:-
          </Typography> */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "14px",
              lineHeight: "140%",
              paddingTop: type === "text" ? "0px" : "10px",
            }}
          >
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {parse(question?.questiontext || question.question || question.subquestiontext || question.submqtext)}
          </Typography>
          {show ? (
            <React.Fragment>
              <Grid item container className={classes.divContainer} />
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingBottom: "5px",
                }}
              >
                Answer
              </Typography>

              <Grid item xs={8.8}>
                <div className={classes.richTextEditor}>
                  <CKEditor
                    data={editorData}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      console.log("editor ready to use", editor);
                    }}
                    onChange={(event, editor) => {
                      HANDLE_CHANGE(event, editor);
                    }}
                    config={{ placeholder: "Please type your answer here...." }}
                  />
                </div>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ShortAnswerNew;
