/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TrueFalseNew.jsx
 * This JSX file is for showing the True or False Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 01 Feb 2023
 */
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { McqIcon } from "../../../../assets/icons";
import { useEffect } from "react";

const USE_STYLES = makeStyles(() => ({
  icon: {
    height: "10px",
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));
const TrueFalseNew = ({ question, selectedAnswer, setSelectedAnswer, handleChange, count, show }) => {
  const CLASSES = USE_STYLES();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  useEffect(() => {
    if (questionStatusResponse[count]?.answer !== "") {
      questionStatusResponse[count]?.answer === 1 ? setSelectedAnswer("True") : setSelectedAnswer("False");
    }
  }, [count, questionStatusResponse, setSelectedAnswer]);

  return (
    <div>
      <Grid container sx={{ marginTop: "2%" }}>
        <Grid item xs={1.5}  md={1.5}/>
        <Grid item xs={5.2} md={7.3}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            Q{question.slot ? question.slot : ""}
          </Typography>{" "}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "30px",
            }}
          >
            {parse(question?.questiontext || question.question)}
          </Typography>
          <Grid item container className={CLASSES.divContainer} />
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "5px",
            }}
          >
            Options:
          </Typography>
          {(question?.answers || question?.truefalseanswer)?.map((option, index) => {
            const answer = option.answer || option;
            const parsedOption = parse(answer);
            return (
              <Box
                key={index}
                onClick={() => {
                  handleChange(parsedOption === "True" ? 1 : 0);
                  setSelectedAnswer(parsedOption);
                }}
                sx={{
                  background: selectedAnswer === parsedOption ? "#634699" : "#FFFFFF",
                  boxShadow:
                  !show
                  ? "none"
                  : selectedAnswer === parsedOption ? "1px 7px 25px #634699" : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                  borderRadius: "5px",
                  padding: "1%",
                  fontWeight: "400",
                  color: selectedAnswer === parsedOption ? "#FFFFFF" : "#000000",
                  width: "250px",
                  margin: "1% 0% 0% 0%",
                  display: "flex",
                  alignItems: "center",
                  cursor: !show ? "auto" : "pointer",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: !show ? "none" : "#634699", color: !show ? "none" : "#FFFFFF" },
                }}
              >
                <McqIcon
                  sx={{
                    stroke: selectedAnswer === parsedOption ? "#FFFFFF" : "#634699",
                  }}
                  className={CLASSES.icon}
                />
                <Box>{parsedOption}</Box>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default TrueFalseNew;
