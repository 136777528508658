import React, { useState } from "react";
import parse from "html-react-parser";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import SubQuestionAnswerArea from "./SubQuestionAnswerArea";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#634699",
  textTransform: "none",
  align: "left",
  borderColor: "#634699",
  display: "flex",
  justifyContent: "flex-start",

  "&:hover": {
    color: "#fff",
    backgroundColor: "#634699",
    borderColor: "#634699",
  },
}));
const Subquestion = (props) => {
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState({
    questionDetails: [],
    questionIndex: 0,
  });

  const setAnswerArray = (answer) => {
    props?.setSelectedAnswer(answer, 0);
  };

  const HANDLE_CLICK_OPEN = () => {
    setOpen(true);
  };
  const HANDLE_CLOSE = (event, reason) => {
    // if (reason === "backdropClick") return;
    setOpen(false);
  };
  return (
    <div>
      <Scrollbars style={{ height: 330 }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "22.4px",
          }}
        >
          {parse(props?.question?.clozemainquestiontext)}
        </Typography>
        <div style={{ marginLeft: "1rem" }}>
          {props?.question?.clozeoptions &&
            props?.question?.clozeoptions.map((sqn, sid) => (
              <>
                <ul>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontStyle: "regular",
                      color: "#634699",
                      fontSize: "16px",
                      lineHeight: "22.4px",
                    }}
                  >
                    {parse(sqn?.questiontext ? sqn?.questiontext : "")}
                  </Typography>
                </ul>
                <ColorButton
                  onClick={() => {
                    setQuestionData({
                      questionDetails: sqn,
                      questionIndex: sid,
                    });
                    HANDLE_CLICK_OPEN();
                  }}
                  sx={{ marginLeft: "3rem", width: "48vw" }}
                  variant="outlined"
                >
                  {props.selectedAnswer
                    ? props.selectedAnswer[sid]?.subanswer == null ||
                      props.selectedAnswer[sid]?.subanswer == ""
                      ? "Please type your answer here..."
                      : "Click to view your answer"
                    : "Please type your answer here..."}
                </ColorButton>
              </>
            ))}
        </div>

        <SubQuestionAnswerArea
          onClose={HANDLE_CLOSE}
          open={open}
          questions={questionData.questionDetails}
          questionIndex={questionData.questionIndex}
          acceptAnswerArray={(value) => setAnswerArray(value)}
          {...props}
        />
      </Scrollbars>
    </div>
  );
};

export default Subquestion;
