/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestCustomize.js
 * This JS file is intended to create a component that performing the drag and drop feature for customizing the test list.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Table,
  Dialog,
  Alert,
  AlertTitle
} from "@mui/material";
import { TestPackageListIcon } from "../../../../assets/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchCourseContents,
  customizeList,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useParams, Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const usestyles = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    margin: "10px",
  },
});

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  }
};
const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));
const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));

const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: 'inherit',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const TestCustomize = () => {
  const params = useParams();
  const [modal, setModal] = useState(false);
  const courseid = params.id;
  const history = useHistory();
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCourseContents(courseid, userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userid } = useSelector((state) => state.authState);

  const taskStatus = {
    all: {
      name: "all",
      items: coursecontents,
    },
    customized: {
      name: "Customized",
      items: [],
    },
  };

  const clickHandler = () => {
    dispatch(customizeList(columns.customized.items));
    if (columns.customized.items.length !== 0) {
      history.push(`/test-packages/orderconfirmation/${courseid}`);
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columns, setColumns] = useState(taskStatus);
  const classes = usestyles();
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={12} md={6}>
          <SEARCH>
            <SEARCH_ICON_WRAPPER>
              <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
            </SEARCH_ICON_WRAPPER>
            <STYLED_INPUT_BASE
              type="text"
              placeholder="Search Topics"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />{" "}
          </SEARCH>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} md={6} p={1}>
            <Button
              onClick={() => {
                history.push(`/test-packages/${courseid}`);
              }}
              sx={{
                width: "100%",
                backgroundColor: "#E2DDEC",
                color: "#634699",
                fontWeight: "bold",
                textTransform: "none",
                "&:hover": { backgroundColor: "#E2DDEC" },
              }}
            >
              Cancel Customisation
            </Button>
          </Grid>
          <Grid item xs={12} md={6} p={1}>
            <Button
              onClick={clickHandler}
              sx={{
                width: "100%",
                backgroundColor: "#634699",
                color: "#fff",
                fontWeight: "bold",
                textTransform: "none",
                "&:hover": { backgroundColor: "#634699" },
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        {modal ? (
          <Dialog
            open={modal}
            onClose={() => {
              setModal(false);
            }}
          >
            <Alert severity="success" color="info">
              <AlertTitle>Alert</AlertTitle>
              Please select atleast one topic!
            </Alert>
          </Dialog>
        ) : (
          ""
        )}
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Grid item container xs={6} key={columnId}>
                <Grid item container>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <Grid
                          item
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "#634699"
                              : "#E7E5EE",
                            padding: 10,
                            width: "98%",
                            minHeight: 500,
                          }}
                        >
                          {column?.items
                            .filter((val) => {
                              if (searchTerm === "") {
                                return val;
                              } else if (
                                val.name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .map(
                              (item, index) =>
                                item.section !== 0 &&
                                (item.modules && item.modules.length !== 0 ? (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                    index={index}
                                    isDragDisabled={item.secvisible === true}
                                    onClick={() => alert("click")}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <Grid
                                          item
                                          xs={12}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#fff"
                                              : "#fff",
                                            color: "#634699",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <Table key={index}>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell>
                                                  <TestPackageListIcon
                                                    className={classes.icon}
                                                  />
                                                </TableCell>

                                                <TableCell
                                                  align="left"
                                                  width="100%"
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "#634699",
                                                  }}
                                                >
                                                  {item.name} -{" "}
                                                  {/* {item.sectioncost} */}
                                                  {new Intl.NumberFormat(
                                                    "en-IN",
                                                    {
                                                      style: "currency",
                                                      currency:
                                                        item?.sectioncurrency,
                                                      maximumSignificantDigits: 3,
                                                    }
                                                  ).format(item?.sectioncost)}
                                                </TableCell>

                                                <TableCell
                                                  align="center"
                                                  margin="18px"
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "16px",
                                                    color: "#634699",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {item.secvisible === true ? (
                                                    <p>Enrolled</p>
                                                  ) : null}
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </Grid>
                                      );
                                    }}
                                  </Draggable>
                                ) : null)
                            )}

                          {provided.placeholder}
                        </Grid>
                      );
                    }}
                  </Droppable>
                </Grid>
              </Grid>
            );
          })}
        </DragDropContext>
      </Grid>
    </>
  );
};

export default TestCustomize;
