/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class success.js
 * This JS file is intended to give a success message screen after Test Attempt.
 *
 * @author tincy.thomas@centelon.com
 * @since 31 May 2022
 */

import React from "react";
import { Grid, Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Schedule from "../../dashboard/schedule";
import Progress from "../../dashboard/progress";
import TESTEXPLORE from "./TestExplore";
import { useHistory } from "react-router-dom";

const USE_STYLES = makeStyles({
  btn: {
    width: "30%",

    "@media (max-width:900px)": {
      width: "80%",
    },
  },

  testPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
    height: "calc(100vh - 42vh)",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRadius: "10px",
      padding: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#634699",
    },
  },
  paperContainer: {
    padding: "40px",
  },
});

const SuccessAttempt = () => {
  const CLASSES = USE_STYLES();
  const HISTORY = useHistory();
  return (
    <Box px={2}>
      <Grid container spacing={2} className={CLASSES.testPackageContainer}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Button
                sx={{
                  color: "#634699",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
            </Grid>

            <Grid item xs={12} md={8}>
              <Paper className={CLASSES.paperContainer}>
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontSize: "40px",
                    lineHeight: "140%",
                    textAlign: "center",
                  }}
                >
                  Congratulations!
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontSize: "24px",
                    lineHeight: "36.4px",
                    textAlign: "center",
                  }}
                >
                  Your test has been submitted, Your
                  <br />
                  results will be notified to you!
                  <br />
                  Till then....ENJOY!
                  <br />
                  ALL THE BEST!
                </Typography>
                <br />
                <Grid align="center">
                  <Button
                    onClick={() => HISTORY.push("/my-test-packages")}
                    className={CLASSES.btn}
                    style={{
                      backgroundColor: "#634699",
                      textTransform: "none",
                      fontStyle: "normal",
                      fontWeight: "700px",
                      fontSize: "19.3651px",
                      lineHeight: "140%",
                      color: "#F1F1F1",
                      padding: "10px",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    My Test Packages
                  </Button>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={6} md={6}>
              <Button
                onClick={() => HISTORY.push("/test-packages")}
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#E2DDEC",
                  color: "#634699",
                  fontWeight: "bold",
                  padding: "15px",
                  "&:hover": { backgroundColor: "#E2DDEC" },
                }}
                fullWidth
              >
                Explore
              </Button>
            </Grid>
            <Grid item xs={12} md={8}>
              <TESTEXPLORE />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={4}>
              <Progress />
            </Grid>
            <Grid item xs={12} md={4}>
              <Schedule />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessAttempt;
