/**All right reserved under Cenablecare Edutech © 2022 */
/**
 * @class views/students/course/content/index.js
 * This JS file is intended to give an overall layout to the test packages section.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */
import React, { useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TestPackageList from "./TestPackageList";
import Schedule from "../../dashboard/schedule";
import Progress from "../../dashboard/progress";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
const USE_STYLES = makeStyles({
  testPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
});
const TESTPACKAGES = () => {
  const CLASSES = USE_STYLES();
  const scrollbarsRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  }, [location.pathname]); 
  return (
    <Scrollbars  ref={scrollbarsRef}
    style={{ height: 500 }}
   >
      <Box px={2}>
        <Grid
          container
          spacing={2}
          columnSpacing={5}
          className={CLASSES.testPackageContainer}
        >
          <Grid item xs={12} md={8}>
            <Grid container direction="column">
              <Grid item xs={12} md={8}>
                <TestPackageList />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} md={4}>
                <Progress />
              </Grid>
              <Grid item xs={12} md={4}>
                <Schedule />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
};
export default TESTPACKAGES;
