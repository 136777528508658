/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class checkout.js
 * This JS file is intended to create a checkout form for proceed with the payment
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Snackbar,
  Dialog,
} from "@mui/material";
import { countries } from "../content/../../../../assets/data/countries";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  submitNotification,
  fetchCourseList,
} from "../../../../webservice/students/actions/TestPackageListAction";
import {
  fetchUserField,
  fetchUserId,
} from "../../../../webservice/students/actions/AuthAction";
import { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { styled } from "@mui/styles";
import LoadingSpinner from "../../../main/login/LoadingSpinner";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});
const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});

const USE_STYLES = makeStyles({
  orderCheckoutContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const CHECKOUT = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const [open, setOpen] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const cost = params.price;
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country: "AU",
    address: "",
    refcode: "",
  });

  const { firstname, lastname, email, country, address, refcode } = state;

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    setFormErrors(e);
  };

  const [showCard, setShowCard] = useState(false);
  const [method, setMethod] = useState("cash");

  const HANDLERADIOCHANGE = (event) => {
    setMethod(event.target.value);
    if (event.target.value === "card") {
      setShowCard(true);
    } else if (event.target.value === "Paypal") {
      setShowCard(false);
    } else if (event.target.value === "cash") {
      setShowCard(false);
    } else if (event.target.value === "bank") {
      setShowCard(true);
    }
  };
  const { userid } = useSelector((state) => state.authState);
  const { userfield } = useSelector((state) => state.authState);
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const { notification } = useSelector((state) => state.testPackageListReducer);
  useEffect(() => {
    dispatch(fetchUserField(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const courseid = params.id;
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const filterArray = courseList?.filter((x) => x.id === parseInt(courseid));

  useEffect(() => {
    if (userfield) {
      setState({
        firstname: userfield[0]?.fullname.split(" ").slice(0, -1).join(" "),
        lastname: userfield[0]?.fullname.split(" ").slice(-1).join(" "),
        email: userfield[0]?.email,
        country: "AU",
        address: "",
        refcode: "",
      });
    }
  }, [userfield]);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(state));
    setIsSubmitted(true);
    setOpen(true);
    setIsLoading(true);
  };

  useEffect(() => {
    if (isSubmitted && Object.keys(formErrors).length === 0) {
      let sectionid = [];
      if (customizedList?.length) {
        customizedList.forEach((element) => {
          sectionid.push(element.id);
        });
      }

      let selectedid = 0;
      if (sectionid.length) {
        selectedid = sectionid.join(",");
      }
      if (
        !userid ||
        !courseid ||
        !cost ||
        !firstname ||
        !lastname ||
        !address ||
        !method ||
        !country ||
        !email
      ) {
        return;
      }
      dispatch(
        submitNotification(
          userid,
          courseid,
          cost,
          firstname,
          lastname,
          address,
          method,
          country,
          email,
          selectedid,
          refcode
        )
      );

      if (notification) setMethod("");
    }
  }, [isSubmitted, formErrors]);

  const validate = (values) => {
    const errors = {};

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const fname = /^[a-zA-Z]+$/;
    const address = /^[a-zA-z0-9/\\''().&@*$,-\s]+$/;
    const id = /^[A-Za-z0-9]+$/;
    if (method === "bank") {
      if (!id.test(values.refcode)) {
        errors.refcode =
          "Transaction ID must only contain alphanumeric characters";
      } else if (values.refcode.length < 12 || values.refcode.length > 18) {
        errors.refcode = "Please enter valid Transaction ID";
      }
    }

    if (!address.test(values.address)) {
      errors.address =
        "Address must only contain alphanumeric characters with a few special characters.";
    }

    if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email format";
    }

    if (!fname.test(values.firstname)) {
      errors.firstname = "First name should be only alphabetical characters";
    }

    if (!fname.test(values.lastname)) {
      errors.lastname = "Last name should be only alphabetical characters";
    }

    return errors;
  };

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    } else {
      setOpen(false);
      if (Object.keys(formErrors).length === 0 && isSubmitted && !isLoading) {
        history.push("/test-packages");
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (typeof notification === "number") {
      setIsLoading(false);
    }
  }, [notification]);

  return (
    <Scrollbars style={{ height: 400 }}>
      <Box px={2}>
        <Grid item xs={12}>
          <Button
            sx={{ color: "#634699", fontWeight: "bold", textTransform: "none" }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Grid>
        <Card sx={{ paddingLeft: "30px" }}>
          <form onSubmit={handleSubmit}>
            <Grid container className={CLASSES.orderCheckoutContainer}>
              <Grid item xs={12} sm={6}>
                <Box width="75%">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "40px",
                          lineHeight: "56px",
                        }}
                      >
                        Checkout
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: "26px",
                          lineHeight: "36.4px",
                        }}
                      >
                        Personal Details
                      </Typography>

                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <CssTextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            type="text"
                            name="firstname"
                            id="firstname"
                            onChange={handleChange}
                            value={firstname}
                            required
                          />
                          <p style={{ color: "red" }}>{formErrors.firstname}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <CssTextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            type="text"
                            name="lastname"
                            id="lastname"
                            onChange={handleChange}
                            value={lastname}
                          />
                          <p style={{ color: "red" }}>{formErrors.lastname}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <CssTextField
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            required
                            type="email"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={email}
                          />
                          <p style={{ color: "red" }}>{formErrors.email}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <CssFormControl fullWidth required>
                            <InputLabel htmlFor="country">Country</InputLabel>
                            <Select
                              label="Country"
                              variant="outlined"
                              required
                              name="country"
                              id="country"
                              onChange={handleChange}
                              value={country || "AU"}
                            >
                              <MenuItem value={countries[13].name}>
                                {countries[13].name}
                              </MenuItem>
                              {countries.map((value, index) => (
                                <MenuItem key={index} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </CssFormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <CssTextField
                            multiline
                            required
                            rows={4}
                            maxRows={6}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            name="address"
                            onChange={handleChange}
                            value={address}
                          />
                          <p style={{ color: "red" }}>{formErrors.address}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3} py={3}>
                  {showCard && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#634699",
                            fontWeight: 700,
                            fontSize: "21.26px",
                            lineHeight: "29.77px",
                          }}
                        >
                          Bank Transaction
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <CssTextField
                          label="Transaction ID"
                          variant="outlined"
                          fullWidth
                          required
                          type="text"
                          name="refcode"
                          id="refcode"
                          onChange={handleChange}
                          value={refcode}
                        />{" "}
                        <p style={{ color: "red" }}>{formErrors.refcode}</p>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 400,
                            fontSize: "26px",
                            lineHeight: "36.4px",
                          }}
                        >
                          Payment Method
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <RadioGroup
                          row
                          aria-label="payment"
                          name="row-radio-buttons-group"
                          defaultValue="cash"
                        >
                          <FormControlLabel
                            value="Card"
                            control={
                              <Radio
                                sx={{
                                  color: "#634699",
                                  "&.Mui-checked": {
                                    color: "#634699",
                                  },
                                }}
                              />
                            }
                            label="Card Payment"
                            name="card"
                            disabled
                            onChange={HANDLERADIOCHANGE}
                          />
                          <FormControlLabel
                            value="Paypal"
                            control={
                              <Radio
                                sx={{
                                  color: "#634699",
                                  "&.Mui-checked": {
                                    color: "#634699",
                                  },
                                }}
                              />
                            }
                            label="Paypal"
                            name="paypal"
                            disabled
                            onChange={HANDLERADIOCHANGE}
                          />
                          <FormControlLabel
                            value="cash"
                            control={
                              <Radio
                                sx={{
                                  color: "#634699",
                                  "&.Mui-checked": {
                                    color: "#634699",
                                  },
                                }}
                              />
                            }
                            label="Cash"
                            name="cash"
                            onChange={HANDLERADIOCHANGE}
                          />
                          <FormControlLabel
                            value="bank"
                            control={
                              <Radio
                                sx={{
                                  color: "#634699",
                                  "&.Mui-checked": {
                                    color: "#634699",
                                  },
                                }}
                              />
                            }
                            label="Bank Transaction"
                            name="bank"
                            onChange={HANDLERADIOCHANGE}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="flex-end"
                      direction="column"
                    >
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 400,
                            fontSize: "45.45px",
                            lineHeight: "63.63px",
                          }}
                        >
                          Total{" "}
                          <span
                            style={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "57.74px",
                              lineHeight: "80.04px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency:
                                filterArray &&
                                filterArray[0]?.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(cost)}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={isLoading}
                          sx={{
                            textTransform: "none",
                            marginTop: 1,
                            backgroundColor: "#634699",
                            "&:hover": { backgroundColor: "#634699" },
                          }}
                        >
                          Checkout
                        </Button>
                        <Dialog
                          open={open}
                          onClose={handleCloses}
                          className={CLASSES.snackbar}
                        >
                          <Alert
                            onClose={handleCloses}
                            icon={isLoading ? false : null}
                            severity={
                              Object.keys(formErrors).length === 0 &&
                              isSubmitted
                                ? "success"
                                : "warning"
                            }
                          >
                            {Object.keys(formErrors).length === 0 &&
                            isSubmitted ? (
                              <div>
                                {isLoading ? (
                                  <LoadingSpinner />
                                ) : (
                                  <p>
                                    Please wait!
                                    <br />
                                    Payment must be verified
                                  </p>
                                )}
                              </div>
                            ) : (
                              <p>You must make some modifications</p>
                            )}
                          </Alert>
                        </Dialog>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    </Scrollbars>
  );
};

export default CHECKOUT;
