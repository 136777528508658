const initialState = {
    validateUser:null,
    validateOtp:null,
    validateTestCode:null
  };

const GuestAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case "VALIDATE_USER":
          return {
            ...state,
            validateUser: action.payload,
          };
          case "VALIDATE_OTP":
            return {
              ...state,
              validateOtp: action.payload,
            };
            case "RESET_VALIDATE_OTP":
            return {
              ...state,
              validateOtp: action.payload,
            };
            case "VALIDATE_TESTCODE":
            return {
              ...state,
              validateTestCode: action.payload,
            };
            case "RESET_VALIDATE_USER":
              return {
                ...state,
                validateUser: action.payload,
              };
            
        default:
          return state;
      }
}

export default GuestAccessReducer