/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/students/test/packages/CompletedTests.js
 * This JS file is intendend to create a card component for showing the tests that are completed.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { useEffect } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { Checkfeedbackwhite, Feedbackiconbtn, GradeIcon, Scheduleiconbtn, Schedulewhite, TestPackageListIcon } from "../../../../assets/icons";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuizDetails } from "../../../../webservice/students/actions/MyTestPackageAction";

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    width: 26,
    height: 26,
  },
  gradeIcon: {
    "& .MuiSvgIcon-root": {
      fill: "#E2DDEC",
    },
  },
});

const COMPLETEDTESTS = (props) => {
  const classes = USE_STYLES();
  const params = useParams();
  const courseid = params.subject;
  const { quizList } = useSelector((state) => state.mytestpackagestate);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchQuizDetails());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <Grid container>
      <Grid item xs={2} md={1}>
        <TestPackageListIcon className={classes.icon} />
      </Grid>
      <Grid item xs={4} md={5}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            fontStyle: "regular",
            lineHeight: "22px",
            color: "#634699",
          }}
        >
          {props.module.name}
        </Typography>
      </Grid>
      <Grid item xs={4} md={6}>
        <Grid container display="flex" justifyContent="flex-end">
          <Grid
            item
            xs={3}
            md={3}
            display="flex"
            justifyContent="flex-end"
            className={classes.gradeIcon}
          >
            {props.module.finalstrgrade ? <GradeIcon /> : null}
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "40px",
                fontStyle: "regular",
                lineHeight: "30px",
                color: "#634699",
              }}
            >
              {props.module.finalstrgrade ? (
                props.module.finalstrgrade
              ) : (
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1rem",
                    fontStyle: "regular",
                    lineHeight: "1.375rem",
                    color: "#634699",
                  }}
                >
                  Not Graded
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end">
      {quizList && quizList.quizzes && quizList.quizzes.length
        ? quizList.quizzes.map((quiz,index) => {
            return quiz.coursemodule === props.module.id ? (
              
              <Button key={index}
              variant="contained"
              component={Link}
              to={`${courseid}/feedback/${quiz.id}`}
              sx={{
                textTransform: "none",
                backgroundColor: "#634699",
                "&:hover": {
                  backgroundColor: "#634699",
                },
                marginRight: "10px",
              }}
            >
              <Checkfeedbackwhite sx={{ fontSize: 22, }} />
                          &nbsp;Check Feedback
            </Button>
              
            ) : null;
          })
        : null}   
        <Button
          component={Link}
          to="/schedule"
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#634699",
            "&:hover": {
              backgroundColor: "#634699",
            },
          }}
        >
            <Scheduleiconbtn sx={{ fontSize: 22 }} />
                  &nbsp;Schedule meeting
        </Button>
      </Grid>
    </Grid>
  );
};

export default COMPLETEDTESTS;
