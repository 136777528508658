/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class schedule/index.js
 * This JS file is the main entry point to the schedule folder.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 25 Feb 2022
 */

 import React, { useEffect, useState } from "react";
 import { makeStyles } from "@mui/styles";
 import {
   Grid,
   Box,
   Button,
   FormControl,
   Select,
   MenuItem,
   InputLabel,
   FormHelperText,
   Typography,
 } from "@mui/material";
 
 import { useHistory } from "react-router-dom";
 import Progress from "../dashboard/progress";
 import Schedule from "../dashboard/schedule";
 import Calendar from "react-calendar";
 import "./calender.css";
 import { Route, Switch } from "react-router-dom";
 import ScheduleForm from "./ScheduleForm";
 import { useDispatch, useSelector } from "react-redux";
 import { fetchTestDetails } from "../../../webservice/students/actions/MyTestPackageAction";
 import {
   getFilteredEvents,
   resetBookedTimesState,
 } from "../../../webservice/students/actions/ScheduleAction";
 import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
 import { Scrollbars } from "react-custom-scrollbars-2";
 
 const USE_STYLES = makeStyles({
   scheduleContainer: {
     overflow: "hidden",
     overflowY: "auto",
     paddingBottom: "100px",
     marginTop: "5px",
   },
   formcontrol: {
     "& .MuiOutlinedInput-notchedOutline": {
       borderStyle: "none",
     },
     "& .MuiInputLabel-formControl": {
       color: "#634699",
       fontWeight: "bold",
     },
     "& .MuiSvgIcon-root": {
       fill: "#634699",
       fontSize: "2rem",
     },
     "& .MuiInputBase-formControl": {
       textAlign: "center",
       color: "#634699",
       backgroundColor: "#E2DDEC",
     },
   },
 });
 
 const MYSCHEDULE = () => {
   const [date, setDate] = useState(new Date());
   const [state, setState] = useState(true);
   const [test, setTest] = useState("");
   const [section, setSection] = useState("");
   const [arrayToFilter, setArrayToFilter] = useState(null);
   const [filteredEvents, setFilteredEvents] = useState([]);
   const [enableCalender, setEnableCalender] = useState(false);
 
   const dispatch = useDispatch();
   const { userid } = useSelector((state) => state.authState);
   const { testList } = useSelector((state) => state.mytestpackagestate);
   const { contentList } = useSelector((state) => state.mytestpackagestate);
   const { scheduleList } = useSelector((state) => state.dashboardState);
   const { events } = useSelector((state) => state.scheduleState);
 
   let month = date.getMonth() + 1;
   const year = date.getFullYear();
 
   useEffect(() => {
     if (test && section) {
       dispatch(getFilteredEvents(userid, test, section, month, year));
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [test, section]);
 
   useEffect(() => {
     if (events) {
       if (events?.tutoravailable) setEnableCalender(true);
       else setEnableCalender(false);
 
       if (events?.events?.length) {
         let eventList = events.events?.map(
           (event) =>
             new Date(event.timestart * 1000)
               .toLocaleDateString()
               .split("T", 1)[0]
         );
         setFilteredEvents(eventList);
       }
     }
   }, [events]);
 
   useEffect(() => {
     if (scheduleList?.length) {
       let dateList = scheduleList.reduce((r, a) => {
         let dt = new Date(a.timestart * 1000).toDateString();
         r[dt] = r[dt] || [];
         r[dt].push(a);
         return r;
       }, Object.create(null));
       setArrayToFilter(dateList);
     }
   }, [scheduleList]);
 
   const handlePackageChange = (event) => {
     setTest(event.target.value);
   };
   const handleSectionChange = (event) => {
     setSection(event.target.value);
   };
 
   useEffect(() => {
     dispatch(fetchUserId());
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
 
   useEffect(() => {
     dispatch(fetchTestDetails(test, userid));
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [test, userid]);
 
   const onChange = (date) => {
     setDate(date);
     setState(false);
   };
 
   const handleBackButtonClick = () => {
     dispatch(resetBookedTimesState());
     history.goBack();
   };
 
   let history = useHistory();
   const classes = USE_STYLES();
   return (
     <Scrollbars style={{ height: 500 }}>
       <Box px={2}>
         <Grid item xs={12}>
           <Button
             sx={{ color: "#634699", fontWeight: "bold", textTransform: "none" }}
             onClick={handleBackButtonClick}
           >
             Back
           </Button>
         </Grid>
         <Grid container spacing={3} className={classes.scheduleContainer}>
           <Grid item xs={12} md={8}>
             <Grid container spacing={2} direction="column">
               <Box px={3}>
                 <Grid item container spacing={2}>
                 <Grid item xs={12} sm={12} md={6} display="flex">
                 <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "40px",
                    lineHeight: "56px",
                  }}
                >
                  My Schedule
                </Typography>
                   </Grid>
                   <Grid item xs={12} sm={6} md={3}>
                     <FormControl fullWidth className={classes.formcontrol}>
                       {/* <InputLabel id="demo-simple-select-helper-label">
                         Test Package
                       </InputLabel> */}
                       <Select
                         value={test}
                         id="grouped-select"
                         label="Grouping"
                         displayEmpty
                         onChange={handlePackageChange}
                      defaultValue={""}

                       >
                         <MenuItem
                                //  key={index}
                                //  value={test.id}
                                value=""
                                 sx={{
                                   color: "#634699",
                                   backgroundColor: "white",
                                   "&.Mui-selected":{
                                    background: "#634699",color: "white"
                                  }
                                 }}
                               >
                                 Test Package
                               </MenuItem>
                         {testList && testList.length
                           ? testList.map((test, index) => (
                               <MenuItem
                                 key={index}
                                 value={test.id}
                                 sx={{
                                   color: "#634699",
                                   backgroundColor: "white",
                                   "&.Mui-selected":{
                                    background: "#634699",color: "white"
                                  }
                                 }}
                               >
                                 {test.displayname}
                               </MenuItem>
                             ))
                           : null}
                       </Select>
                     </FormControl>
                   </Grid>
                   <Grid item xs={12} sm={6} md={3}>
                     <FormControl fullWidth className={classes.formcontrol}>
                       {/* <InputLabel id="demo-simple-select-helper-label">
                         Section
                       </InputLabel> */}
                       <Select
                         value={section}
                         id="grouped-select"
                         label="Grouping"
                         displayEmpty
                         onChange={handleSectionChange}
                         defaultValue={""}
                       >
                        <MenuItem
                                  value=""
                                 sx={{
                                   color: "#634699",
                                   backgroundColor: "white",
                                   "&.Mui-selected":{
                                    background: "#634699",color: "white"
                                  }
                                 }}
                               >
                                 Section
                               </MenuItem>
                          {contentList && contentList.length
                 ? contentList.map(
                     (contentData, index) =>
                       contentData.section !== 0 &&
                       (contentData.modules &&
                       contentData.modules.length !== 0 ? ( (
                               <MenuItem
                                 key={index}
                                 value={contentData.id}
                                 sx={{
                                   color: "#634699",
                                   backgroundColor: "white",
                                   "&.Mui-selected":{
                                    background: "#634699",color: "white"
                                  }
                                 }}
                               >
                                 {contentData.name}
                               </MenuItem>
                             )): null))
                           :null}
                       </Select>
                     </FormControl>
                   </Grid>
                   <Grid item xs={12} sm={3} md={12} display="flex" justifyContent="flex-end">
                     <Box display="flex" alignItems="center">
                       <FormHelperText error>
                         {events && !enableCalender
                           ? "Tutor not assigned!"
                           : null}
                       </FormHelperText>
                     </Box>
                   </Grid>
                 </Grid>
               </Box>
               <Switch>
                 <Route path="/schedule" exact>
                   <Grid item xs={12}>
                     {state ? (
                       <div
                         className="calender-wrapper"
                         style={{
                           pointerEvents: enableCalender ? "auto" : "none",
                           opacity: enableCalender ? 1 : 0.4,
                         }}
                       >
                         <Calendar
                           calendarType="US"
                           onChange={onChange}
                           value={new Date()}
                           tileClassName={({ date, view }) => {
                             if (
                               filteredEvents &&
                               filteredEvents?.find(
                                 (x) =>
                                   x ===
                                   date.toLocaleDateString().split("T", 1)[0]
                               )
                             )
                               return "highlight";
                           }}
                           tileDisabled={({ date, view }) => {
                             let currDate = new Date();
                             let prevDate = currDate.setDate(
                               currDate.getDate() - 1
                             );
                             return new Date(date).getTime() < prevDate
                               ? true
                               : arrayToFilter &&
                                 arrayToFilter[new Date(date).toDateString()]
                                   ?.length >= 3
                               ? true
                               : false;
                           }}
                           prev2Label={null}
                           next2Label={null}
                         />
                       </div>
                     ) : (
                       <ScheduleForm
                         date={date}
                         sectionid={section}
                         packageid={test}
                       />
                     )}
                   </Grid>
                 </Route>
               </Switch>
             </Grid>
           </Grid>
           <Grid item xs={12} md={4}>
             <Grid container spacing={2} direction="column">
               <Grid item xs={12} md={4}>
                 <Progress />
               </Grid>
               <Grid item xs={12} md={4}>
                 <Schedule />
               </Grid>
             </Grid>
           </Grid>
         </Grid>
       </Box>
     </Scrollbars>
   );
 };
 
 export default MYSCHEDULE;