import React from "react";
import { Fragment, useState } from "react";
import parse from "html-react-parser";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Pagination,
  FormHelperText,
  Collapse,
  IconButton,
  ListItem,
  List,
  Dialog,
  DialogContent,
} from "@mui/material";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizAttempt } from "../../../../webservice/students/actions/MyTestPackageAction";
import { makeStyles } from "@mui/styles";
import { getThumbnail } from "../../../../utils/AppConstants";
import {
  ExpandFilledIcon,
  ExpandIcon,
  Scheduleiconbtn,
} from "../../../../assets/icons";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MultiChoiceNew from "./multiChoiceNew";
import TrueFalseNew from "./TrueFalseNew";
import MatchNew from "./MatchNew";
import ShortAnswerNew from "./ShortAnswerNew";
import EssayNew from "./essayNew";
// import SubquestionNew from "./SubquestionNew";
import OfflineHeader from "../../../../layouts/main/header/OfflineHeader";
import LOGO from "../../../main/ui_component/logo";
import MultiAnswer from "./MultiAnswer";
import flag from "./../../../../assets/images/flag.png";

const USE_STYLES = makeStyles({
  pagination: {
    "& .MuiButtonBase-root": {
      border: "transparent",
      color: "#000000",
      fontWeight: "700",
      borderRadius: "5px",
    },
    "& .Mui-selected": {
      backgroundColor: "#634699",
      color: "#fff",
      "&:hover": { backgroundColor: "#634699" },
    },
  },
});

const OfflineQuestionFeedbacknew = () => {
  const classes = USE_STYLES();
  let history = useHistory();
  const params = useParams();
  const quizid = params.quizid;
  const questionattemtid = params.questionname;
  const [open, setOpen] = useState(false);
  const [expandOpen, setExpandOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [count, setCount] = useState(0);
  const { quizAttemptDetail } = useSelector(
    (state) => state.mytestpackagestate
  );
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  useEffect(
    () => {
      if (userid) dispatch(fetchQuizAttempt(userid, quizid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [userid],
    quizid
  );

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(parseInt(questionattemtid));
  }, [questionattemtid]);

  const HANDLECHANGE = (event, value) => {
    setPage(value);
  };

  const HANDLEPREVCLICK = () => {
    setPage(page - 1);
  };
  const HANDLENEXTCLICK = () => {
    if (page === quizAttemptDetail[0].questionlist.length) {
      setOpenDialog(true);
    } else {
    setPage(page + 1);}
  };
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return quizAttemptDetail ? (
    <div>
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
    <LOGO width="12%" />
  </div>

  <Grid container sx={{ padding: "5% 2% 5% 19%" }}>
    <div
      style={{
        // background: "#FFFFFF",
        // boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginRight: "187.5px"
      }}
    >
    {/* <Grid container spacing={2}> */}
      <OfflineHeader />

      <Fragment>
      <div style={{padding:"10px 10px 10px 0px",margin:"20px"}}>
          <Grid item container xs={12}>
            {/* <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "40px",
                  lineHeight: "56px",
                }}
              >
                {quizAttemptDetail[0]?.sectionname}
              </Typography>
            </Grid> */}
          </Grid>
          <Fragment>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={8} sm={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: 24,
                  }}
                >
                  Question{" "}
                  {quizAttemptDetail[0].questionlist[page - 1].questionorder}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={0.5}>
                <StarBorderPurple500OutlinedIcon />
              </Grid> */}
              {/* <Grid
                item
                xs={12}
                md={3.5}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  component={Link}
                  to="/schedule"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#634699",
                    "&:hover": {
                      backgroundColor: "#634699",
                    },
                  }}
                >
                  <Scheduleiconbtn sx={{ fontSize: 22 }} />
                  &nbsp;Schedule meeting
                </Button>
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card
                sx={{
                  paddingBottom: "25px",
                  width: "100%",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={11}>
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "multichoice" ? (
                        <MultiChoiceNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          selectedAnswer={selectedAnswer}
                          setSelectedAnswer={setSelectedAnswer}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "truefalse" ? (
                        <TrueFalseNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          selectedAnswer={selectedAnswer}
                          setSelectedAnswer={setSelectedAnswer}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "match" ? (
                        <MatchNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          count={count}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "shortanswer" ? (
                        <ShortAnswerNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "essay" ? (
                        <EssayNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "multianswer" ? (
                        <MultiAnswer
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={1}
                      sm={12}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontStyle: "regular",
                          color: "#634699",
                          fontSize: 40,
                          lineHeight: "33px",
                        }}
                      >
                        {quizAttemptDetail[0].questionlist[page - 1].mark > "0"
                          ? `+${formatter.format(
                              quizAttemptDetail[0].questionlist[page - 1].mark
                            )}`
                          : formatter.format(
                              quizAttemptDetail[0].questionlist[page - 1].mark
                            )}
                        <span
                          style={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: 15,
                            lineHeight: "33px",
                          }}
                        >
                          Points
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        {quizAttemptDetail[0].questionlist[
                          page - 1
                        ]?.filesattached?.map((file, index) => {
                          let type = file?.filetype?.split("/")?.pop();
                          let thumbnail = getThumbnail(
                            file?.fileurl || file?.basedata,
                            type
                          );

                          return (
                            <Grid key={`thumbnail-${index + 1}`} item xs={3}>
                              <img src={thumbnail} alt="" width="90%" />
                              <FormHelperText>
                                {file?.filename || ""}
                              </FormHelperText>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {quizAttemptDetail[0].questionlist[page - 1].responsesummary !==
            "" ? (
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      marginTop: "10px",
                      paddingLeft: "16px",
                      background: open ? "#634699" : "#FFFFFF",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => setOpen(!open)}
                          aria-label="expand"
                          size="small"
                          sx={{
                            transform: !open ? "rotate(0deg)" : "rotate(90deg)",
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                        >
                          {open ? (
                            <ExpandFilledIcon sx={{ fontSize: 18 }} />
                          ) : (
                            <ExpandIcon sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: open ? "#FFFFFF" : "#634699",
                            fontSize: "16px",
                            lineHeight: "140%",
                            padding: "10px",
                          }}
                        >
                          Your Answer
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    {quizAttemptDetail[0].questionlist[page - 1].qtype ===
                      "match" ||
                    quizAttemptDetail[0].questionlist[page - 1].qtype ===
                      "multianswer" ? (
                      <Grid item xs={12}>
                        {quizAttemptDetail[0].questionlist[page - 1]
                          .responsesummary !== null ? (
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#000",
                              fontSize: 16,
                              lineHeight: "22px",
                              padding: "8px",
                              marginLeft: "92px",
                            }}
                          >
                             <Grid item xs={12}>
                              <Grid
                                container
                                sx={{
                                  width: "107%",
                                }}
                              >
                                <Grid item xs={7} md={4}>
                                  <div>
                                    <Typography
                                      variant="h2"
                                      sx={{
                                        fontWeight: 700,
                                        fontStyle: "regular",
                                        color: "#634699",
                                        fontSize: "18px",
                                        lineHeight: "140%",
                                        marginLeft: "50px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      Column A
                                    </Typography>
                                  </div>
                                  <div className="App">
                                    <List
                                      className="list-containers"
                                      sx={{ paddingTop: 0 }}
                                    >
                                      {quizAttemptDetail[0].questionlist[
                                        page - 1
                                      ].matchanswer.columna.map((i, index) => (
                                        <React.Fragment key={index}>
                                          {" "}
                                          <ListItem
                                            key={index}
                                            className="item-containers"
                                          >
                                            <Typography
                                              variant="h2"
                                              sx={{
                                                fontWeight: 400,
                                                fontStyle: "regular",
                                                color: "#634699",
                                                fontSize: "14px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {parse(i)}
                                            </Typography>
                                          </ListItem>
                                          <div
                                            style={{
                                              marginTop: "-40px",
                                              marginLeft: "195px",
                                              wordWrap: "break-word",
                                              height: "auto",
                                              padding: "5px",
                                              marginBottom: "15px",
                                              borderTop: " 1px solid #634699",
                                              background: "#F8F9FA",
                                            }}
                                          >
                                            {" "}
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </List>
                                  </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                  <Typography
                                    variant="h2"
                                    sx={{
                                      fontWeight: 700,
                                      fontStyle: "regular",
                                      color: "#634699",
                                      fontSize: "18px",
                                      lineHeight: "140%",
                                      marginLeft: "50px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Column B
                                  </Typography>

                                  <div className="App">
                                    <List
                                      className="list-container"
                                      sx={{ paddingTop: 0 }}
                                    >
                                      {quizAttemptDetail[0].questionlist[
                                        page - 1
                                      ].matchanswer.columnb.map((i, index) => (
                                        <React.Fragment key={index}>
                                          {" "}
                                          <ListItem
                                            key={index}
                                            className="item-container"
                                          >
                                            <Typography
                                              variant="h2"
                                              sx={{
                                                fontWeight: 400,
                                                fontStyle: "regular",
                                                color: "#634699",
                                                fontSize: "14px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {parse(i)}
                                            </Typography>
                                          </ListItem>
                                        </React.Fragment>
                                      ))}
                                    </List>
                                  </div>
                                </Grid>
                                <Grid item xs={8} />
                              </Grid>
                            </Grid>
                          </Typography>
                        ) : null}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {quizAttemptDetail[0].questionlist[page - 1]
                          .responsesummary !== null ? (
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#000",
                              fontSize: 16,
                              lineHeight: "22px",
                              padding: "8px",
                              marginLeft: "30px",
                            }}
                          >
                            {parse(
                              quizAttemptDetail[0].questionlist[page - 1]
                                .responsesummary
                            )}
                          </Typography>
                        ) : null}
                      </Grid>
                    )}
                  </Collapse>
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            {quizAttemptDetail[0].questionlist[page - 1].comment !== "" ? (
              <Grid container >
                <Grid item xs={12}>
                  <Card
                    sx={{
                      marginTop: "10px",
                      paddingLeft: "16px",
                      background: expandOpen ? "#634699" : "#FFFFFF",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => setExpandOpen(!expandOpen)}
                          aria-label="expand"
                          size="small"
                          sx={{
                            transform: !expandOpen
                              ? "rotate(0deg)"
                              : "rotate(90deg)",
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                        >
                          {expandOpen ? (
                            <ExpandFilledIcon sx={{ fontSize: 18 }} />
                          ) : (
                            <ExpandIcon sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: expandOpen ? "#FFFFFF" : "#634699",
                            fontSize: "16px",
                            lineHeight: "140%",
                            padding: "10px",
                          }}
                        >
                          Feedback
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                    {quizAttemptDetail[0].questionlist[page - 1].comment ? (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#000",
                            fontSize: 16,
                            lineHeight: "22px",
                            padding: "8px",
                            marginLeft: "30px",
                          }}
                        >
                          {parse(
                            quizAttemptDetail[0].questionlist[page - 1].comment
                          )}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Collapse>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {quizAttemptDetail[0].questionlist[page - 1].rightanswer !== "" ? (
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      marginTop: "10px",
                      paddingLeft: "16px",
                      background: expand ? "#634699" : "#FFFFFF",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => setExpand(!expand)}
                          aria-label="expand"
                          size="small"
                          sx={{
                            transform: !expand
                              ? "rotate(0deg)"
                              : "rotate(90deg)",
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                        >
                          {expand ? (
                            <ExpandFilledIcon sx={{ fontSize: 18 }} />
                          ) : (
                            <ExpandIcon sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: expand ? "#FFFFFF" : "#634699",
                            fontSize: "16px",
                            lineHeight: "140%",
                            padding: "10px",
                          }}
                        >
                          Correct Answer
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expand} timeout="auto" unmountOnExit>
                    {quizAttemptDetail[0].questionlist[page - 1].qtype ===
                      "match" ||
                    quizAttemptDetail[0].questionlist[page - 1].qtype ===
                      "multianswer" ? (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#000",
                            fontSize: 16,
                            lineHeight: "22px",
                            padding: "8px",
                            marginLeft: "92px",
                          }}
                        >
                          <Grid item xs={12}>
                            <Grid
                              container
                              sx={{
                                width: "107%",
                              }}
                            >
                              <Grid item xs={7} md={4}>
                                <div>
                                  <Typography
                                    variant="h2"
                                    sx={{
                                      fontWeight: 700,
                                      fontStyle: "regular",
                                      color: "#634699",
                                      fontSize: "18px",
                                      lineHeight: "140%",
                                      marginLeft: "50px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Column A
                                  </Typography>
                                </div>
                                <div className="App">
                                  <List
                                    className="list-containers"
                                    sx={{ paddingTop: 0 }}
                                  >
                                    {quizAttemptDetail[0].questionlist[
                                      page - 1
                                    ].matchrightanswer.columna.map(
                                      (i, index) => (
                                        <React.Fragment key={index}>
                                          {" "}
                                          <ListItem
                                            key={index}
                                            className="item-containers"
                                          >
                                            <Typography
                                              variant="h2"
                                              sx={{
                                                fontWeight: 400,
                                                fontStyle: "regular",
                                                color: "#634699",
                                                fontSize: "14px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {parse(i)}
                                            </Typography>
                                          </ListItem>
                                          <div
                                            style={{
                                              marginTop: "-40px",
                                              marginLeft: "195px",
                                              wordWrap: "break-word",
                                              height: "auto",
                                              padding: "5px",
                                              marginBottom: "15px",
                                              borderTop: " 1px solid #634699",
                                              background: "#F8F9FA",
                                            }}
                                          >
                                            {" "}
                                          </div>
                                        </React.Fragment>
                                      )
                                    )}
                                  </List>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <Typography
                                  variant="h2"
                                  sx={{
                                    fontWeight: 700,
                                    fontStyle: "regular",
                                    color: "#634699",
                                    fontSize: "18px",
                                    lineHeight: "140%",
                                    marginLeft: "50px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  Column B
                                </Typography>

                                <div className="App">
                                  <List
                                    className="list-container"
                                    sx={{ paddingTop: 0 }}
                                  >
                                    {quizAttemptDetail[0].questionlist[
                                      page - 1
                                    ].matchrightanswer.columnb.map(
                                      (i, index) => (
                                        <React.Fragment key={index}>
                                          {" "}
                                          <ListItem
                                            key={index}
                                            className="item-container"
                                          >
                                            <Typography
                                              variant="h2"
                                              sx={{
                                                fontWeight: 400,
                                                fontStyle: "regular",
                                                color: "#634699",
                                                fontSize: "14px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {parse(i)}
                                            </Typography>
                                          </ListItem>
                                        </React.Fragment>
                                      )
                                    )}
                                  </List>
                                </div>
                              </Grid>
                              <Grid item xs={8} />
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#000",
                            fontSize: 16,
                            lineHeight: "22px",
                            padding: "8px",
                            marginLeft: "30px",
                          }}
                        >
                          {
                            quizAttemptDetail[0].questionlist[page - 1]
                              .rightanswer
                          }
                        </Typography>
                      </Grid>
                    )}
                  </Collapse>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: 16,
                  lineHeight: "140%",
                  padding: "5px",
                  marginRight: "20px",
                }}
              >
                Questions
              </Typography>
              <Pagination
                variant="outlined"
                shape="rounded"
                className={classes.pagination}
                count={quizAttemptDetail[0].questionlist.length}
                page={page}
                onChange={HANDLECHANGE}
                hideNextButton
                hidePrevButton
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container sx={{ marginTop: "16px" }}>
                <Grid item xs={4} md={8} />
                <Grid
                  item
                  xs={4}
                  md={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={HANDLEPREVCLICK}
                    disabled={page === 1}
                    sx={{
                      background: "#634699",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      textTransform: "none",
                      marginLeft: "10px",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {" "}
                    <ArrowLeftIcon />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        fontSize: "14px",
                        lineHeight: "140%",
                      }}
                    >
                      Prev
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={HANDLENEXTCLICK}
                    // disabled={page === quizAttemptDetail[0].questionlist.length}
                    sx={{
                      background: "#634699",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      textTransform: "none",
                      marginLeft: "10px",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        fontSize: "14px",
                        lineHeight: "140%",
                        textAlign: "center",
                      }}
                    >
                      Next
                    </Typography>
                    <ArrowRightIcon />
                  </Button>
                  <Dialog
                    open={openDialog}
                    PaperProps={{
                      style: {
                        background: "#FFFFFF",
                        boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                        borderRadius: "5px",
                        padding: "5%",
                        width: "1000px",
                      },
                    }}
                  >
                    <DialogContent>
                      <Grid container item display="flex" alignItems="center" justifyContent="center">
                        <Grid item xs={4} />
                        <Grid item xs={3.7}>
                          <img src={flag} alt="success" width="120.06px" height="130.08px" />
                        </Grid>
                        <Grid item xs={4} />
                      </Grid>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "40px",
                          lineHeight: "60px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        FINISH!
                      </Typography>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 400,
                          fontSize: "18px",
                          lineHeight: "36px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        It seems you have Reviewed all the Questions
                      </Typography>
                    </DialogContent>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => setOpenDialog(false)}
                        sx={{
                          background: "#FFFFFF",
                          color: "#634699",
                          marginBottom: "15px",
                          borderRadius: "5px",
                          textTransform: "none",
                          border: "1px solid #634699",
                          fontWeight: 400,
                          fontSize: "14px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                            color: "#634699",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Go Back
                      </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => history.push("/login/mocktestform")}
                        sx={{
                          background: "#634699",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          textTransform: "none",
                          // marginLeft: "10px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          fontWeight: 400,
                          fontSize: "14px",
                          "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
                        }}
                      >
                         Try again 
                      </Button>
                    </div>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </Fragment>
    {/* </Grid> */}
    </div></Grid></div>
  ) : (
    ""
  );
};

export default OfflineQuestionFeedbacknew;
