/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/index.js
 * This JS file is intended to provide the action methods with respect to dashboard page
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { getStudentList, getTaskList, getScheduleList } from "../api";

export const fetchUserList = (userid) => async (dispatch) => {
  try {
    const getResult = await getStudentList(userid);
    dispatch({ type: "GET_USERS", payload: getResult });
  } catch (error) {
    return error;
  }
};

export const fetchTaskList = () => async (dispatch) => {
  try {
    const getTasks = await getTaskList();
    dispatch({ type: "GET_TASKS", payload: getTasks });
  } catch (error) {
    return error;
  }
};

export const fetchScheduleList = () => async (dispatch) => {
  try {
    const getSchedule = await getScheduleList();
    dispatch({ type: "GET_SCHEDULE", payload: getSchedule });
  } catch (error) {
    return error;
  }
};
