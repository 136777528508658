/**All right reserved under Cenablecare Edutech © 2022 */
/**
 * @class TestPackageList.js
 * This JS file is intended to create a component used for listing the test packages coming under one subject
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Dialog,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { AddToCartIcon, ExpandIcon, TestPackageListIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseList,
  fetchCourseContents,
  fetchGradeSubject,
  carteditemsaction,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useEffect } from "react";
import Image from "../../../../assets/images/collapse-testicon.png";
import Pic from "../../../../assets/images/collapse-sessionicon.png";
import { useState } from "react";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import mailicon from "../../../../assets/images/cartaddedimage.png"
// import mailicon from "../../../assets/images/cartaddedimage.png";



const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #634699",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));

const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));
const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
const USE_STYLES = makeStyles({
  testContainer: {
    padding: "50px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
   popover: {
    '& .MuiPopover-paper': {
      marginTop: '127px',
      marginLeft: '16px',
    },
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop:"10px",
  },
  carticon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop:"5px",
    "&:hover": {
      backgroundColor: "rgb(99 70 153 / 40%)",
    },
  },
  card: {
    margin: "10px",
    "& .MuiCardHeader-title": {
      color: "#634699",
      fontWeight: "bold",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  test: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  session: {
    backgroundImage: `url(${Pic})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  select: {
    '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      // paddingRight: '10px',
      overflow: "inherit"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      marginRight:"-24px"
    },
    "&:after": {
      borderColor: "transparent",
    },
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      // fontSize: "2rem",
      marginRight: "15px",
    },
    "& .MuiInputBase-formControl": {
      textAlign: "center",
      color: "#634699",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px rgb(0 0 0 / 2%)",
    borderRadius: "5px",
    },
  },
});
const TEST_PACKAGE_LIST = () => {
  const [expanded, setExpanded] = React.useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [hover,sethover]=useState(false);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [showSyllabus, setShowSyllabus] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const HANDLE_EXPAND_CLICK = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
    dispatch(fetchCourseContents(id, userid));
  };
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const { userid } = useSelector((state) => state.authState);
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );
  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );
  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  useEffect(() => {
    dispatch(fetchGradeSubject());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { gradeSubjectList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
  };
  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const getSearchResult = () => {
    let list = courseList.filter((val) => {
      if (searchTerm === "" && selectedGrade === "" && selectedSubject === "") {
        return val;
      } else if (
        val.displayname.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedGrade
          ? val.customfields[0].name === "Grades" &&
            val.customfields[0].valueraw === selectedGrade.toString()
          : true) &&
        (selectedSubject
          ? val.customfields[1].name === "Subjects" &&
            val.customfields[1].valueraw === selectedSubject.toString()
          : true)
      ) {
        return val;
      }
    });
    if (list.length) return list;
    else return [{ format: "no-topics" }];
  };
  // const { cartItems } = useSelector(
  //   (state) => state.testPackageListReducer
  // );
  const [cartOpen, setCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const handleAddToCart = (itemList) => {
    dispatch(carteditemsaction(itemList));
    setLastAddedItem(itemList);
    setCartOpen(true);
  };
  const handleCartClose = () => {
    setCartOpen(false);
    setLastAddedItem(null);
  };
  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "40px",
                  lineHeight: "56px",
                }}
              >
                Test Packages
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid
                container
                spacing={1}
                marginBottom="10px"
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item xs={3} md={4.5}>
                  <FormControl
                    fullWidth
                    className={CLASSES.formcontrol}
                    variant="outlined"
                  >
                    {/* <InputLabel sx={{ background: "white" }} shrink>
                      Grade
                    </InputLabel> */}
                    <Select
                    sx={{overflow: "inherit"}}
                      id="grouped-select"
                      label="Grouping"
                      displayEmpty
                      onChange={handleGradeChange}
                      className={CLASSES.select}
                      defaultValue={""}
                      IconComponent={() => (
                        <ExpandIcon sx={{ fontSize: 12,transform: "rotate(90deg)"}} />
                      )}
                    >
                      <MenuItem
                       className={CLASSES.popover}
                        value=""
                        sx={{
                          color: "#634699",
                          backgroundColor: "transparent",
                          "&.Mui-selected":{
                            background: "#634699",color: "white"
                          }
                        }}
                      >
                        Grade
                      </MenuItem>
                      {gradeSubjectList && gradeSubjectList?.grades?.length
                        ? gradeSubjectList?.grades?.map((grade, index) => (
                            <MenuItem
                            className={CLASSES.popover}
                              key={index}
                              value={grade.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                                "&.Mui-selected":{
                                  background: "#634699",color: "white"
                                }
                              }}
                            >
                              {grade.gradename}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} md={4.5}>
                  <FormControl
                    fullWidth
                    className={CLASSES.formcontrol}
                    variant="outlined"
                  >
                    {/* <InputLabel sx={{ background: "white" }} shrink={true}>
                      Subject
                    </InputLabel> */}
                    <Select
                      id="grouped-select"
                      label="Grouping"
                      displayEmpty
                      onChange={handleSubjectChange}
                      className={CLASSES.select}
                      defaultValue={""}
                      IconComponent={() => (
                        <ExpandIcon sx={{ fontSize: 12,transform: "rotate(90deg)"}} />
                      )}
                    >
                      <MenuItem
                        value=""
                        sx={{
                          color: "#634699",
                          backgroundColor: "white",
                        }}
                      >
                        Subject
                      </MenuItem>
                      {selectedGrade
                        ? gradeSubjectList && gradeSubjectList?.mapping?.length
                          ? gradeSubjectList?.mapping?.map((grade, index) => {
                              return (
                                grade.grade === selectedGrade &&
                                (grade?.gradesubjects?.length
                                  ? grade?.gradesubjects?.map(
                                      (subject, index) => (
                                        <MenuItem
                                          key={index}
                                          value={subject.subjectid}
                                          sx={{
                                            color: "#634699",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          {subject.subjectname}
                                        </MenuItem>
                                      )
                                    )
                                  : null)
                              );
                            })
                          : null
                        : gradeSubjectList && gradeSubjectList?.subjects?.length
                        ? gradeSubjectList?.subjects?.map((subject, index) => (
                            <MenuItem
                              key={index}
                              value={subject.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                              }}
                            >
                              {subject.subject}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SEARCH>
            <SEARCH_ICON_WRAPPER>
              <SearchIcon sx={{fontSize:18,margin:"5px"}}/>
            </SEARCH_ICON_WRAPPER>
            <STYLED_INPUT_BASE
              type="text"
              placeholder="Search Test Packages"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />{" "}
          </SEARCH>
        </Grid>
        <Grid item xs={12} md={12}>
          {courseList && courseList.length
            ? getSearchResult().map((data, index) => {
                let quizCount = 0;
                let assignCount = 0;
                return data.format === "topics" &&
                  data.categoryid === 2 &&
                  data.visible === 1 ? (
                  <Fragment key={data.id}>
                    <Grid item xs={12} key={data.id}>
                      <Card sx={{ marginBottom: "10px" }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={2} md={1}>
                              <TestPackageListIcon className={CLASSES.icon} />
                            </Grid>
                            {/* <Grid item xs={4} md={4}> */}
                            <Grid item xs={7} md={7}>
                              <Typography
                                sx={{
                                  color: "#634699",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  lineHeight: "38.4px",
                                }}
                              >
                                {data.displayname}
                                <span
                                  style={{
                                    color: "#718096",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "38.4px",
                                    marginLeft:"20px"
                                  }}
                                >
                                {data.customfields[0].name === "Grades" && data.customfields[0].value !== null && !isNaN(data.customfields[0].value)
    ? data.customfields[0].value + (
        parseInt(data.customfields[0].value) % 100 === 11 || parseInt(data.customfields[0].value) % 100 === 12 || parseInt(data.customfields[0].value) % 100 === 13
          ? "th"
          : parseInt(data.customfields[0].value) % 10 === 1
            ? "st"
            : parseInt(data.customfields[0].value) % 10 === 2
              ? "nd"
              : parseInt(data.customfields[0].value) % 10 === 3
                ? "rd"
                : "th"
      )
    : data.customfields[0].value !== null ? data.customfields[0].value : ""
  }
                                </span>
                                <br />
                                <span
                                  style={{
                                    color: "white",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "16.8px",
                                  }}
                                >
                                  {data.summary.replace(/<[^>]+>/g, "")}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={3} md={1.5}>
                              {data?.paypalenrollments.map(
                                (pricedata, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      color: "#634699",
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      lineHeight: "38.4px",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: pricedata?.currency,
                                      maximumSignificantDigits: 3,
                                    }).format(pricedata?.cost)}
                                  </Typography>
                                )
                              )}
                            </Grid>
                            {/* <Grid item xs={2} md={1}></Grid> */}
                            <Grid item xs={2} md={1.5}>
                              <IconButton className={CLASSES.carticon} onClick={() => handleAddToCart(data)}>
                                <AddToCartIcon sx={{fontSize:20}}/>
                              </IconButton>
                              {cartOpen && lastAddedItem === data && (
        // <CartPopup cartItems={[data]}  />
        <Dialog
        open={cartOpen}
        onClose={handleCartClose}
        PaperProps={{
          style: {
            maxHeight: "none",
          },
        }}
        BackdropProps={{ invisible: true }}
      >
        <Grid container item xs={12}>
          <Card
            className={CLASSES.testContainer}
            sx={{ minWidth: 600 }}
          >
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={mailicon} alt="mailicon" width="100px" height="100px" />
            </Grid>
            <br />

            <Typography
            variant="h1"
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
             {data.displayname}<br/>
             successfully carted

            </Typography>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
            </Grid>
          </Card>
        </Grid>
      </Dialog>
      )}
                            </Grid>
                            <Grid item xs={3} md={1}>
                            <EXPAND_MORE
                                  expand={expanded === index}
                                  onClick={() =>
                                    HANDLE_EXPAND_CLICK(index, data.id)
                                  }
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandIcon sx={{fontSize:22}}/>
                                </EXPAND_MORE>
                              {/* <Button
                                variant="outlined"
                                style={{
                                  color: "gray",
                                  border: "1px solid #634699",
                                  textTransform: "none",
                                }}
                                onClick={() =>
                                  HANDLE_EXPAND_CLICK(index, data.id)
                                }
                                aria-expanded={expanded}
                              >
                                View Syllabus
                              </Button> */}
                            </Grid>
                           
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      {coursecontents && coursecontents.length
                        ? coursecontents.map((content, indexcontent) => {
                            if (
                              content &&
                              content.modules &&
                              content.modules.length
                            ) {
                              let quizlist = content.modules.filter(
                                (e) => e.modname === "quiz"
                              );
                              if (quizlist.length) {
                                quizCount = quizCount + quizlist.length;
                              }
                              let assignlist = content.modules.filter(
                                (e) => e.modname === "assign"
                              );
                              if (assignlist.length) {
                                assignCount = assignCount + assignlist.length;
                              }
                            }
                            return coursecontents.length - 1 ===
                              indexcontent ? (
                              <Collapse in={expanded === index} key={index}>
                                <Card >
                                  {/* <CardContent> */}
                                    <Grid item xs={12}>
                                      <Grid container>
                                        <Grid item md={1}></Grid>
                                        <Grid
                                          item
                                          md={5}
                                          xs={12}
                                          className={CLASSES.session}
                                        >
                                          <span
                                            style={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "106.667px",
                                              lineHeight: "160px",
                                            }}
                                          >
                                            {filteredContents.length}
                                          </span>
                                          <Typography
                                            sx={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "12px",
                                              lineHeight: "150%",
                                            }}
                                          >
                                            Sessions
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          xs={12}
                                          className={CLASSES.test}
                                        >
                                          <span
                                            style={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "106.667px",
                                              lineHeight: "160px",
                                            }}
                                          >
                                            {quizCount}
                                          </span>
                                          <Typography
                                            sx={{
                                              color: "#634699",
                                              fontWeight: 700,
                                              fontSize: "12px",
                                              lineHeight: "150%",
                                            }}
                                          >
                                            Tests
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid container>
                                      <Grid item xs={8} sx={{ marginTop: "22px" }}>
                                            {showSyllabus && (
                                              <div>
                                                {filteredContents.map((item) => (
                                                  <Grid>
                                                    <List key={item.id}>
                                                      <Typography
                                                        sx={{
                                                          color: "#634699",
                                                          fontWeight: 400,
                                                          fontSize: "16px",
                                                          lineHeight: "140%",
                                                          marginLeft: "50px",
                                                        }}
                                                      >
                                                        {item.name}
                                                      </Typography>
                                                    </List>
                                                  </Grid>
                                                ))}
                                                
                                              </div>
                                            )}
                                          </Grid>
                                      <Grid item xs={4}>
                                        {/* <Grid item xs={2.5}> */}
                                          <Grid container direction="column">
                                            <Grid item>
                                              <Typography
                                                sx={{
                                                  color: "#634699",
                                                  fontWeight: 700,
                                                  fontSize: "1.25vw",
                                                  lineHeight: "150%", width:"200px",textAlign:"center",
                                                }}
                                              >
                                                {data.verificationstatus}
                                              </Typography>
                                            </Grid>
                                            {data.enroluistat === false &&
                                            filteredContents.length !== 0 ? (
                                              <Grid item>
                                                {/* <Button
                                                  component={Link}
                                                  to={`/test-packages/orderconfirmation/${data.id}`}
                                                  variant="outlined"
                                                  fullWidth
                                                  sx={{
                                                    marginTop: 1,
                                                    color: "#634699",
                                                    borderColor: "#634699",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                      borderColor: "#634699",
                                                    },
                                                  }}
                                                >
                                                  Enroll
                                                </Button> */}
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                            {data.cancustomize === 1 &&
                                            filteredContents.length !== 0 ? (
                                              <Grid item>
                                                <Button
                                                  component={Link}
                                                  to={`/test-packages/${data.id}`}
                                                  onMouseOver={()=>sethover(true)} 
     onMouseOut={()=>sethover(false)} 
                                                  variant="outlined"
                                                  // fullWidth
                                                  sx={{
                                                    marginTop: 1,marginLeft:"50px",
                                                    color: "#fff",
                                                    borderColor: "#634699",
                                                    textTransform: "none",
                                                    backgroundColor: "#634699",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#7C55C5",
                                                      borderColor: "#634699",
                                                    },
                                                  }}
                                                >
                                                  {hover?"Customise":"Customise"}
                                                </Button>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              textTransform: "none",
                                              background: "rgba(224, 218, 235, 0.74)",
                                              color: "#634699",
                                              boxShadow: "none",
                                              fontWeight: "bold",
                                              padding: "8px",
                                              marginTop: "20px",
                                              borderRadius: "4px",
                                              "&:hover": { background: "rgba(224, 218, 235, 0.74)", boxShadow: "none" },
                                            }}
                                            fullWidth
                                            onClick={() => setShowSyllabus(!showSyllabus)}
                                          >
                                            {showSyllabus ? "Show Less" : "Show Test Syllabus"}
                                          </Button>
                                        </>
                                    </Grid>
                                  {/* </CardContent> */}
                                </Card>
                              </Collapse>
                            ) : null;
                          })
                        : null}
                    </Grid>
                  </Fragment>
                ) : data.format === "no-topics" ? (
                  <Grid item xs={12} key={data.format} textAlign="center">
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "140%",
                      }}
                    >
                      No Result Found..!
                    </Typography>
                  </Grid>
                ) : null;
              })
            : null}
        </Grid>
      </Grid>
    </>
  );
};
export default TEST_PACKAGE_LIST;
