/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class FileUploadPreview.js
 * This JS file is for handling the File Preview Functionality
 *
 * @author tincy.thomas@centelon.com
 * @since 31 May 2022
 */

import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Card,
  Typography,
  ImageListItem,
  ImageList,
  Dialog,
  Snackbar,
  Alert,
  FormHelperText,
} from '@mui/material';
import LOGO from '../../../main/ui_component/logo';
import { makeStyles } from '@mui/styles';
import { PreviewComponent, PlusComponent } from '../../../../assets/icons';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { uploadedFiles } from '../../../../webservice/students/actions/TestAttemptAction';
import FileThumbnail from './FileThumbnail';
import noPreview from '../../../../assets/images/no-preview.png';
import { getBase64 } from '../../../../utils/AppConstants';

const USE_STYLES = makeStyles({
  testScreen: {
    backgroundColor: '#F8F9FA',
    padding: '0% 5%',
  },
  logoDiv: {
    padding: '3% 10%',
    textAlign: 'center',
  },
  uploadDiv: {
    padding: '5% 10%',
    textAlign: 'center',
    height: '100%',
  },
  logoContainer: {
    padding: '10px',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  uploadContainer: {
    padding: '9%',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  divContainer: {
    border: '3px dotted #634699',
    borderRadius: '4px',
    height: '400px',
  },
  preview: {
    width: '10%',
    height: 'auto',
  },
  gradeIcon: {
    '& .MuiSvgIcon-root': {
      fill: '#fff',
    },
  },
  image: {
    width: '11.12vw',
    height: 'auto',
    marginTop: '4%',
    position: 'relative',
    cursor: 'pointer',
  },
  thumbnailContainer: {
    position: 'relative',
  },
  delete: {
    position: 'absolute',
    top: -5,
    right: -10,
    color: 'red',
    cursor: 'pointer',
    zIndex: 1,
  },
});

const FileUploadPreview = (props) => {
  const classes = USE_STYLES();
  const [selectedImage, setSelectedImage] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'warning',
    message: '',
  });
  const { uploadfileList } = useSelector((state) => state.testAttemptState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const [imageUrl, setImageUrl] = useState(uploadfileList);
  const dispatch = useDispatch();

  const tobase64Handler = async (files) => {
    const filePathsPromises = [];

    files.forEach((file) => {
      filePathsPromises.push(getBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File, index) => ({
      filename: files[index].name,
      filetype: files[index].type,
      basedata: base64File,
    }));
    return mappedFiles;
  };

  const handleUpload = () => {
    // dispatch(uploadedFiles(imageUrl));
    if (selectedImage !== null) {
      if (selectedImage.length > 0) {
        let filesLength = selectedImage.length;

        tobase64Handler(selectedImage).then((filesArray) => {
          props?.onUpload(filesArray, filesLength);
          setSelectedImage([]);
          props?.onClose();
        });
      } else {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: 'warning',
          message: 'Please select file(s) to upload!',
        });
      }
    }
  };

  const processFile = async (e) => {
    try {
      let files = [...e?.target?.files];
      files.forEach((file, index) => {
        if (
          props?.fileSizeLimit?.value &&
          file.size >= props?.fileSizeLimit?.value
        ) {
          setSnackbar({
            ...snackbar,
            open: true,
            severity: 'error',
            message: `${file.name}: File size limit exceeds!`,
          });
          files.splice(index, 1);
        }
      });
      setSelectedImage([...selectedImage, ...files]);
    } catch (error) {
      return error;
    }
  };

  const onCloseHandler = () => {
    setSnackbar({ ...snackbar, open: false, message: '' });
  };

  const handleThumbnailDelete = (index) => {
    let array = [...selectedImage];
    array.splice(index, 1);
    setSelectedImage(array);
  };

  return (
    <Dialog onClose={props.onClose} fullScreen open={props.open}>
      <Grid container className={classes.testScreen} direction='column'>
        <Grid item>
          <Grid container className={classes.logoDiv}>
            <Grid item xs={12} md={3}>
              <Card className={classes.logoContainer}>
                <LOGO width='50%' />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container className={classes.uploadDiv}>
            <Grid item xs={12} md={10}>
              <Card className={classes.uploadContainer}>
                {previewImage ? (
                  <Grid
                    item
                    container
                    alignItems='center'
                    justifyContent='center'
                    className={classes.divContainer}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: 'regular',
                        color: '#634699',
                        fontSize: '1.5vw',
                        lineHeight: '33.6px',
                      }}
                    >
                      <img
                        src={
                          previewImage === 'no-preview'
                            ? noPreview
                            : previewImage
                        }
                        alt='preview'
                        style={{ width: '30.12vw', height: '40vh' }}
                      />
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    alignItems='center'
                    justifyContent='center'
                    className={classes.divContainer}
                  >
                    <PreviewComponent className={classes.preview} />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: 'regular',
                        color: '#634699',
                        fontSize: '1.5vw',
                        lineHeight: '33.6px',
                      }}
                    >
                      PREVIEW IMAGE
                    </Typography>
                  </Grid>
                )}
                <Grid item container xs={12} spacing={1}>
                  {uploadfileList.length ? (
                    <Grid item container xs={10} md={10}>
                      <ImageList
                        sx={{ height: '30vh' }}
                        cols={3}
                        rowHeight={164}
                      >
                        {imageUrl &&
                          imageUrl.map((uploadedFile, index) => {
                            return (
                              <ImageListItem key={index}>
                                <img
                                  src={uploadedFile}
                                  alt='uploadedFile'
                                  className={classes.image}
                                  onClick={() => {
                                    setPreviewImage(uploadedFile);
                                  }}
                                />
                              </ImageListItem>
                            );
                          })}
                      </ImageList>
                    </Grid>
                  ) : (
                    <FileThumbnail
                      classes={classes}
                      selectedImage={selectedImage}
                      setPreviewImage={setPreviewImage}
                      handleDelete={handleThumbnailDelete}
                    />
                  )}

                  <Grid item container xs={3} md={2}>
                    <Grid item container xs={12} alignItems='end'>
                      <input
                        type='file'
                        id='select-image'
                        style={{ display: 'none' }}
                        multiple
                        onChange={processFile}
                      />
                      <label htmlFor='select-image'>
                        <Button
                          variant='contained'
                          color='primary'
                          component='div'
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#C4C4C4',
                            width: '5.75vw',
                            height: '15.33vh',

                            padding: '5px 40px',
                            '&:hover': { backgroundColor: '#C4C4C4' },
                          }}
                        >
                          <PlusComponent />
                        </Button>
                      </label>
                      {props?.fileSizeLimit?.name && (
                        <FormHelperText error>
                          (Max. {props?.fileSizeLimit?.name})
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems='end'
                      direction='row'
                    >
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => handleUpload()}
                        color='primary'
                        component='div'
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#634699',
                          '&:hover': { backgroundColor: '#634699' },
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={props.onClose}
                        component='span'
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#ffffff',
                          color: '#634699',
                          padding: '5px 40px',
                          '&:hover': { backgroundColor: '#ffffff' },
                        }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={onCloseHandler}
      >
        <Alert
          onClose={onCloseHandler}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default FileUploadPreview;
