/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class FeedbackNotes.js
 * This JS file is intended to display the feedback details
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 *  @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import { Card, Typography, CardContent, Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { fetchFeedbackList } from "../../../webservice/students/actions/FeedbackAction";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";

const USE_STYLES = makeStyles({
  card: {
    width: "100%",
    margin: "auto",
    height: "377.5px",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
});

const FEEDBACK = () => {
  const classes = USE_STYLES();
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  const { allfeedbacks } = useSelector((state) => state.feedbackState);
  useEffect(() => {
    dispatch(fetchUserId());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchFeedbackList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Typography
              style={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "25.2px",
                paddingLeft: "21px",
                paddingTop: "28px",
              }}
            >
              Feedback Notes
            </Typography>

            <Scrollbars style={{ height: 330 }}>
              <CardContent>
                {allfeedbacks && allfeedbacks.length ? (
                  allfeedbacks.map((feedback, index) => {
                    return (
                      <Fragment key={index}>
                        {feedback.slice(0, 3).map((subItems, sIndex) => {
                          return (
                            <Fragment key={sIndex}>
                              <Typography
                                sx={{
                                  color: "#828282",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                  marginTop: "20px",
                                }}
                              >
                                {subItems.quizname}
                                <br />
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#A0AEC0",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "18px",
                                  marginTop: "20px",
                                }}
                              >
                                {subItems.overallfeedback.replace(
                                  /<[^>]+>/g,
                                  ""
                                )}
                              </Typography>
                              <Divider />
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      textAlign: "center",
                    }}
                  >
                    No Feedbacks found
                    <br />
                  </Typography>
                )}
              </CardContent>
            </Scrollbars>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default FEEDBACK;
