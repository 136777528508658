/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class OrderConfirmationText.js
 * This JS file is intended to create a component that showing a confirmation message
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Almostthereimage from "../../../../assets/images/Almostthereimage.png";

// import Almostthereimage from "../../../assets/images/Almostthereimage.png";


const USE_STYLES = makeStyles({
  card: {
    paddingTop: "25px",
    paddingBottom: "25px",
  },
});

const ORDER_CONFIRMATION_TEXT = () => {
  const CLASSES = USE_STYLES();
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );
  const filteredContents = coursecontents?.filter(
    (x) => x.section !== 0 && x.modules && x.modules.length !== 0
  );

  const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );
  const getListToRender = () => {
    return customizedList ? customizedList : filteredContents;
  };
  return (
    <Grid container sx={{display:"flex",justifyContent:"center"}}>
      <Grid item xs={12}>
     
      {/* <Grid item xs={12}> */}

        <Card className={CLASSES.card}>
          <CardContent>
            <Grid sx={{display:"flex"}}>
          <Grid item xs={2.5} >
      <img src={Almostthereimage} alt="pic" width="123.27px" height="123.6px" />
      </Grid>
      <Grid item xs={1}/>
      <Grid item xs={8.5}>
            <Typography
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "40px",
                lineHeight: "56px",
              }}
            >
              Almost there!
            </Typography>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: 400,
                fontSize: "20px",
                lineHeight: "36.4px",
              }}
            >
              We are only one step away from your customized course just like
              you ordered !
            </Typography></Grid></Grid>
            <Grid item container display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "33.6px",
                }}
              >
                {getListToRender()?.length}/{filteredContents?.length}
                <br />
                <span
                  style={{
                    color: "#634699",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "140%",
                  }}
                >
                  selected
                </span>
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid></Grid>
    // </Grid>
  );
};

export default ORDER_CONFIRMATION_TEXT;
