/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class LoginForm.js
 * This JS file is for designing the login form component
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 Feb 2022
 */
import classes from "./login.module.css";
import {
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { loginUser } from "../../../webservice/students/actions/AuthAction";
import { encrypt, decrypt } from "../../../encrypt";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
});

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "36.08px",
  height: "18.54px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "transparent" : "transparent",
        opacity: 1,
        border: "1px solid #fff",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "14.53px",
    height: "14.53px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "transparent" : "transparent",
    opacity: 1,
    border: "1px solid #fff",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const LOGINFORM = () => {
  const HISTORY = useHistory();
  const [error, setError] = useState(true);
  const dispatch = useDispatch();
  const [field, setField] = useState({
    email: "",
    password: "",
    isChecked: false,
    showPassword: false,
  });
  const { email, password, isChecked, showPassword } = field;
  const { user } = useSelector((state) => state.authState);
  const cred = localStorage.getItem("credentials");

  useEffect(() => {
    if (cred) {
      const credentials = JSON.parse(decrypt(cred));
      setField(credentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("userToken", encrypt(user));
      HISTORY.push("/test-packages");
    } else {
      setError(!error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isChecked && email !== "") {
      localStorage.setItem(
        "credentials",
        JSON.stringify(encrypt({ email, password, isChecked }))
      );
    }
    dispatch(loginUser(email, password));
    setField({ email: "", password: "", isChecked: false });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setField({ ...field, [name]: value });
  };

  const handleCheckbox = (e) => {
    setField({ ...field, isChecked: e.target.checked });
    if (!e.target.checked) {
      localStorage.clear();
      setField({ email: "", password: "", isChecked: false });
    }
  };

  const handleClickShowPassword = () => {
    setField({
      ...field,
      showPassword: !field.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit}>
        <Grid item container direction="column" xs={12}>
          <Grid item>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "35px",
                lineHeight: "130%",
                color: "#FFFFFF",
              }}
            >
              Welcome Back
            </Typography>
          </Grid>
          <Grid>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "140%",
                color: "#C4C4C4",
                marginBottom: "40px",
              }}
            >
              Enter your username and password to sign in
            </Typography>
          </Grid>

          {error && <p style={{ color: "red" }}>Invalid email/password</p>}
          <Grid item>
            <FormControl>
              <CssTextField
                sx={{
                  input: { color: "#fff" },
                  width: "25vw",
                  marginBottom: "15px",
                  ["@media (max-width:700px)"]: { width: "68vw" },
                }}
                InputLabelProps={{ style: { color: "#A0AEC0" } }}
                label="Your Username"
                required
                fullWidth
                id="inputEmail"
                name="email"
                onChange={handleChange}
                value={email}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <CssTextField
                sx={{
                  input: { color: "#fff" },
                  width: "25vw",
                  marginBottom: "15px",
                  ["@media (max-width:700px)"]: { width: "68vw" },
                }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                required
                onChange={handleChange}
                InputLabelProps={{ style: { color: "#A0AEC0" } }}
                label="Your Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility style={{ color: "#fff" }} />
                        ) : (
                          <VisibilityOff style={{ color: "#fff" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={isChecked}
              onChange={handleCheckbox}
              control={<CustomSwitch sx={{ m: 1 }} />}
              label={
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "12.026px",
                    lineHeight: "150%",
                    color: "#FFFFFF",
                  }}
                >
                  Remember me
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: "#FFFFFF",
                color: "#634699",
                textTransform: "none",
                borderRadius: "5px",
              }}
              type="submit"
              variant="contained"
              className={classes.btnstyle}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "150%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",

                  color: "#634699",
                }}
              >
                Login{" "}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Typography className={classes.regtext}>
              Don 't have an account? &nbsp;
              <Link
                to="/signup"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
              >
                <b>Sign Up</b>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
export default LOGINFORM;
