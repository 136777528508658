/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/AuthReducer.js
 * This JS file is intended to provide the reducer for authentication.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

const initialState = {
  user: null,
  admin: [],
  userid: null,
  userdetails: null,
  userToken: null,
  userfield: null,
  existingEmail: [],
  existingUsername: [],
  picture: [],
  updateuser: [],
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "GET_USER_ID":
      return {
        ...state,
        userid: action.payload,
      };
    case "GET_USER_DETAILS":
      return {
        ...state,
        userdetails: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: action.payload,
      };
    case "GET_SIGNUP":
      return {
        ...state,
        admin: action.payload,
      };
    case " CREATE_TOKEN":
      return {
        ...state,
        userToken: action.payload,
      };

    case "GET_USER":
      return {
        ...state,
        userfield: action.payload.data,
      };

    case "GET_EXISTING_EMAIL":
      return {
        ...state,
        existingEmail: action.payload.data,
      };
    case "GET_EXISTING_USERNAME":
      return {
        ...state,
        existingUsername: action.payload.data,
      };
    case "RESET_VALIDATIONS":
      return {
        ...state,
        existingUsername: action.payload,
        existingEmail: action.payload,
      };
    case "RESET_SIGNUP_ADMINVALUE":
      return {
        ...state,
        admin: action.payload,
      };
    case "UPDATE_PICTURE":
      return {
        ...state,
        picture: action.payload.data,
      };
    case "UPDATE_USER":
      return {
        ...state,
        updateuser: action.payload,
      };

    default:
      return state;
  }
};
export default AuthReducer;
