/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class SignUp.js
 * This JS file is for designing the layout for Sign up page.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import React, { useState, useEffect } from "react";
import classes from "./SignUp.module.css";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signUp,
  createToken,
  checkExistingEmail,
  checkExistingUsername,
  resetValidations,
  resetAdminValue,
} from "../../../webservice/students/actions/AuthAction";
import { styled } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#E2E8F0",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E2E8F0",
    },
    "&:hover fieldset": {
      borderColor: "#E2E8F0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E2E8F0",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SIGNUP = () => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    cpassword: "",
    phone2: "",
    showPassword: false,
    showCPassword: false,
    showError: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { admin } = useSelector((state) => state.authState);
  const { existingUsername } = useSelector((state) => state.authState);
  const { existingEmail } = useSelector((state) => state.authState);
  const [formErrors, setFormErrors] = React.useState({});
  const [successMessage, setsuccessMessage] = React.useState(null);

  const HISTORY = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (admin) {
      if (admin[0]?.id) {
        let id = admin[0]?.id;
        dispatch(createToken(id));
        setOpen(true);
        dispatch(resetAdminValue());
        setsuccessMessage("  Your account created successfully! ");
        setState({
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          password: "",
          cpassword: "",
          phone2: "",
          showPassword: false,
          showCPassword: false,
          showError: false,
        });
      }
    }
  }, [admin, HISTORY]);

  const {
    firstname,
    lastname,
    username,
    email,
    password,
    cpassword,
    phone2,
    showPassword,
    showCPassword,
    showError,
  } = state;

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(state));
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (
      isSubmitted &&
      Object.keys(formErrors).length === 0 &&
      showError === false
    ) {
      dispatch(signUp(firstname, lastname, username, email, password, phone2));
      dispatch(resetValidations());
    }
  }, [isSubmitted, formErrors]);

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const handleClickShowCPassword = () => {
    setState({
      ...state,
      showCPassword: !state.showCPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;
    const fname = /^[a-zA-Z]+$/;
    const uname = /^[a-z0-9]+$/;
    var phone = new RegExp(/^[0-9\b\s+()-]+$/);

    if (!phone.test(values.phone2)) {
      errors.phone2 = "Please provide valid phone number";
    } else if (values.phone2.length < 10 || values.phone2.length > 12) {
      errors.phone2 = "Please enter valid mobile number";
    }

    if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email format";
    }

    if (!passwordRegex.test(values.password)) {
      errors.password =
        "Password must contain an uppercase, lowercase, numbers, special characters(@,!,*,$,^,) and minimum 8 and maximum 15 characters length";
    }

    if (!fname.test(values.firstname)) {
      errors.firstname = "First name should be only alphabetical characters";
    }

    if (!fname.test(values.lastname)) {
      errors.lastname = "Last name should be only alphabetical characters";
    }

    if (!uname.test(values.username)) {
      errors.username =
        "Username must have only lowercase alphanumeric characters";
    }
    return errors;
  };
  const onCloseHandler = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (password === cpassword) {
      setState({
        ...state,
        showError: false,
      });
    } else {
      setState({
        ...state,
        showError: true,
      });
    }
  }, [cpassword]);
  const handleChange = (e) => {
    if (e.target.name === "email") {
      dispatch(checkExistingEmail(e.target.name, e.target.value));
    } else if (e.target.name === "username") {
      dispatch(
        checkExistingUsername(e.target.name, e.target.value.toLowerCase())
      );
    }
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    setFormErrors(e);
  };

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.welcome}>
          <Typography variant="h1" sx={{ color: "#ffffff" }}>
            Welcome!
          </Typography>
          <Typography className="normal">
            Welcome to the awesome world of Cenable Care!{" "}
          </Typography>
          <Typography className="normal">
            Create your account for free!
          </Typography>
          <Grid>
            <Paper elevation={20} className={classes.paper}>
              <Grid className={classes.signupicons}>
                <Typography style={{ color: "#634699" }}>
                  <strong>Create New Account</strong>
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit}>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginBottom:"10px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        First Name
                      </Typography>

                      <CssTextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="firstname"
                        id="firstname"
                        size="small"
                        sx={{ marginBottom: "15px" }}
                        placeholder="Your first name*"
                        onChange={handleChange}
                        value={firstname}
                        required
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.firstname}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom:"10px",
                        }}
                      >
                        {" "}
                        Last Name
                      </Typography>

                      <CssTextField
                        sx={{ marginBottom: "15px" }}
                        placeholder="Your last name*"
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="lastname"
                        onChange={handleChange}
                        value={lastname}
                        id="lastname"
                        required
                        size="small"
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.lastname}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom:"10px",
                        }}
                      >
                        {" "}
                        User Name
                      </Typography>

                      <CssTextField
                        sx={{ marginBottom: "15px" }}
                        placeholder="Your user name*"
                        variant="outlined"
                        type="text"
                        fullWidth
                        size="small"
                        name="username"
                        onChange={handleChange}
                        value={username}
                        id="username"
                        required
                        error={
                          existingUsername && existingUsername[0]?.id
                            ? true
                            : false
                        }
                        helperText={
                          existingUsername[0]?.id
                            ? "Username Already Exists"
                            : null
                        }
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.username}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom:"10px",
                        }}
                      >
                        {" "}
                        Mobile No.
                      </Typography>

                      <CssTextField
                        sx={{ marginBottom: "15px" }}
                        placeholder="Your Mobile no.*"
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="phone2"
                        onChange={handleChange}
                        value={phone2}
                        id="phone2"
                        required
                        size="small"
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.phone2}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom:"10px",
                        }}
                      >
                        {" "}
                        Email
                      </Typography>

                      <CssTextField
                        sx={{ marginBottom: "15px" }}
                        placeholder="Your email*"
                        variant="outlined"
                        type="text"
                        fullWidth
                        size="small"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        id="email"
                        required
                        error={existingEmail?.length !== 0 ? true : false}
                        helperText={
                          existingEmail?.length !== 0
                            ? "Email Already Exists"
                            : null
                        }
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.email}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom:"10px",
                        }}
                      >
                        {" "}
                        Password
                      </Typography>

                      <CssTextField
                        placeholder="Your password*"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        name="password"
                        onChange={handleChange}
                        value={password}
                        id="password"
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff style={{ color: "#634699" }} />
                                ) : (
                                  <Visibility style={{ color: "#634699" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "15px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.password}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textAlign: "left",
                          marginBottom:"10px"
                        }}
                      >
                        {" "}
                        Confirm Password
                      </Typography>

                      <CssTextField
                        placeholder="Confirm your password*"
                        variant="outlined"
                        type={showCPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        name="cpassword"
                        onChange={handleChange}
                        value={cpassword}
                        id="cpassword"
                        required
                        error={showError ? true : false}
                        helperText={
                          showError ? "Passwords are not matching" : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showCPassword ? (
                                  <VisibilityOff style={{ color: "#634699" }} />
                                ) : (
                                  <Visibility style={{ color: "#634699" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "-2px",
                          fontSize: "12px",
                        }}
                      >
                        {formErrors.password}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Button
                        style={{
                          backgroundColor: "#634699",
                          color: "#FFFFFF",
                          marginBottom: "10px",
                        }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.btnstyle}
                      >
                        <b>Sign up</b>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {successMessage && (
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={onCloseHandler}
                  >
                    <Alert
                      onClose={onCloseHandler}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      {successMessage}
                    </Alert>
                  </Snackbar>
                )}
                <Typography className={classes.regtext}>
                  Already have an account?
                  <span>
                    {" "}
                    <Link
                      to="/login"
                      style={{ textDecoration: "none", color: "#634699" }}
                    >
                      Sign in
                    </Link>
                  </span>
                </Typography>
                &nbsp;
              </form>
            </Paper>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SIGNUP;
