/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class index.js
 * This JS file is intended to reuse the header component.
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { Fragment } from "react";
import accountIcon from "../../../assets/images/sign-in.png";
import { createStyles, makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import {
  Toolbar,
  AppBar,
  Box,
  Card,
  CardHeader,
  Avatar,
  Grid,
  Button,
  Typography,
  Divider,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  fetchUserDetails,
} from "../../../webservice/students/actions/AuthAction";
import { useEffect } from "react";
import Image from "../../../assets/images/headerbg.png";
// import Image from "../../../assets/images/dashboard-bg.png";

import settings from "../../../assets/images/setting.png";
import notificationIcons from "../../../assets/images/notificationIcons.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import icon from "../../../assets/icons/cameraicon.svg";
import {
  fetchUserField,
  updateUserPicture,
} from "../../../webservice/students/actions/AuthAction";
import { Cart, Cartheaders, Headersettings, Notificationheaders, } from "../../../assets/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";


const badgeStyle = {
  "& .MuiBadge-badge": {
    color: "#634699",
    backgroundColor: "white",
    width: "24.65px",
    height: "24.65px",
    borderRadius: "6.44px",
  },
};

const USE_STYLES = makeStyles(() =>
  createStyles({
    popover: {
      "&.MuiPopover-root": {
        left:"-135px"
        // left: "857px",
      }
    },
    appbar: {
      height: "140px",
      backgroundColor: "#634699",
      backgroundImage: `url(${Image})`,
      backgroundRepeat: "no-repeat",
      borderRadius: "15px",
      backgroundSize: "90% 90%",
      backgroundPosition:"24px 0px",
      // backgroundPosition: "center center" 
    },
    link: {
      color: "#634699",
      display: "flex",
      textDecoration: "none",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      left: "5px",
    },
    links: {
      color: "#634699",
      display: "flex",
      textDecoration: "none",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      left: "5px",
    },
    image: {
      fontSize: 18,
    },
    profile: {
      marginTop: "-80px",
    },
    card: {
      marginRight: "15px",
      marginLeft: "15px",
      padding: "20px",
      borderRadius: "5px",
      height: "91px",
      border: "1.5px solid #FFFFFF",
      background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
      boxShadow: "0px 2px 5.5px rgba(0, 0, 0, 0.02)",
      backdropFilter: "blur(10.5px)",
      marginTop: "42px",
    },
    cardHeader: {
      padding: "0px",
    },
    action: {
      "&.MuiCardHeader-action": {
        marginTop: "5px",
        marginRight: "25px",
      },
    },
  })
);

const HEADER = () => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { userdetails } = useSelector((state) => state.authState);
  const { userid } = useSelector((state) => state.authState);
  const { picture } = useSelector((state) => state.authState);
  const { userfield } = useSelector((state) => state.authState);
  let history = useHistory();

  let maxMb = 2;
  const [state, setState] = React.useState({
    profileimageurl: "",
  });
  const { profileimageurl } = state;
  React.useEffect(() => {
    if (userfield) {
      setState({
        profileimageurl: userfield[0]?.profileimageurl,
      });
    }
  }, [userfield]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchor, setAnchor] = useState(null);
  const opens = Boolean(anchor);

  const handleClicks = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloses = () => {
    setAnchor(null);
  };
  useEffect(() => {
    dispatch(fetchUserDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const logoutHandler = () => {
   
    dispatch(logoutUser());
    localStorage.removeItem("userToken");
  };
  const handleLogout = () => {
    setIsModalOpen(true);
  };
  React.useEffect(() => {
    dispatch(fetchUserField(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  React.useEffect(() => {
    if (picture?.success) {
      dispatch(fetchUserField(userid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picture]);

  const handleUpload = (event) => {
    const imagefile = event.target.files[0];
    let formData = new FormData();
    let type = imagefile.type?.split("/")?.pop();
    let size = imagefile.size / 1024;
    let mb = size / 1024;
    if (
      (type === "png" || type === "jpeg" || type === "gif" || type === "jpg") &&
      mb < maxMb
    ) {
      formData.append("profile", imagefile);
    } else {
      if (mb > maxMb) {
        window.alert(
          `File too Big, please select a file less than ${maxMb} MB`
        );
        return false;
      } else {
        window.alert(
          "File does not support.  You must apply. gif, jpeg, jpg and png"
        );
        return false;
      }
    }
    dispatch(updateUserPicture(userid, formData));
  };
  const notifications = [
    'New password set successfully!',
    'Profile details have been updated successfully',
    'Your Test Results have been updated',
    'Your Test Results have been updated'
  ];
  return (
    <>
      <Grid>
        <Grid item>
          <AppBar position="static" className={CLASSES.appbar}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Toolbar>
                  <Box>
                    <Grid item xs={12} />
                    <Grid item xs={0}>
                      

                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <Headersettings sx={{width: "0.8em", height: "0.8em"}}/>
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <Link to="/profilescreen" className={CLASSES.links}>
                            Profile
                          </Link>
                        </MenuItem>
                      </Menu>
                     
                      <IconButton
        id="basic-button"
        aria-controls={opens ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={opens ? "true" : undefined}
        onClick={handleClicks}
      >
                              <Notificationheaders sx={{width: "0.8em", height: "0.8em"}}/>

      </IconButton>
      <Menu
      className={CLASSES.popover}
        id="basic-menu"
        anchorEl={anchor}
        open={opens}
        onClose={handleCloses}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      ><div style={{padding:"0px 8px 0px 8px"}}>
        <div style={{background:'#634699',borderRadius:"2px"}}>
        <MenuItem onClick={handleClose}>
        <Typography
                    sx={{
                      fontWeight: 700,
                      fontStyle: "regular",
                      fontSize: "16px",
                      lineHeight: "140%",
                      color:"white",
                      
                    }}
                  >
                    Notifications 
                  </Typography> </MenuItem>
        </div>
          
        
        <Divider />
        {notifications.map((message, index) => (
          <Fragment key={index}><MenuItem onClick={handleClose}>
        
          {/* // <li key={index}>{message}</li> */}
          <Typography
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            fontSize: "12px",
            lineHeight: "130%",
            color:"#634699",
            // padding:"10px"
            
          }}
        >
          {message}<br/><span style={{fontWeight: 400,
            fontStyle: "regular",
            fontSize: "10px",
            lineHeight: "140%",
            color:"#634699",
            marginTop:"10px"
            }}>
              2nd January 2023
          </span>
        </Typography>
       
          </MenuItem> <Divider /></Fragment>
          ))}
    <div onClick={handleCloses}>
                  <Typography
                   onClick={() =>
                    history.push(
                      '/Notifications'
                    )}
                    sx={{
                      fontWeight: 400,
                      fontStyle: "regular",
                      fontSize: "14px",
                      lineHeight: "140%",
                      textAlign:"center",
                      color:"#634699",
                      cursor:"pointer"
                      

                    }}
                  >
                   View All
                  </Typography>
                  </div>
                  </div>
      </Menu>
      <IconButton
       onClick={() =>
        history.push(
          '/CartDetails'
        )}
                        // id="basic-button"
                        // aria-controls={open ? "basic-menu" : undefined}
                        // aria-haspopup="true"
                        // aria-expanded={open ? "true" : undefined}
                        // onClick={handleClick}
                      >
                        {/* <Cartheader */}
                        <Cartheaders sx={{width: "0.8em", height: "0.8em"}}/>
                      </IconButton>
      <IconButton sx={{background:"#FFFFFF",marginLeft:"8px",color:"#634699",padding:"5px",borderRadius:"6px", "&:hover": {
                                  backgroundColor: "#FFFFFF",color:"#634699"
                                },}}
                        size="large"
                        color="inherit"
                        onClick={() => handleLogout()}
                      >
                        {/* <img
                          src={accountIcon}
                          alt="account"
                          className={CLASSES.image}
                        /> */}

                        <div to="/login" className={CLASSES.link}>
                          Sign out
                        </div>
                      </IconButton>
                      <Dialog
                    open={isModalOpen}
                    PaperProps={{
                      style: {
                        background: "#FFFFFF",
                        boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                        borderRadius: "5px",
                        padding: "5%",
                        width: "1000px",
                      },
                    }}
                  >
                    <DialogContent>
                      <Grid container item display="flex" alignItems="center" justifyContent="center">
                        <Grid item xs={4} />
                        <Grid item xs={3.7}>
                          {/* <img src={flag} alt="success" width="120.06px" height="130.08px" /> */}
                        </Grid>
                        <Grid item xs={4} />
                      </Grid>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "40px",
                          lineHeight: "60px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        Sign Out ?! 
                      </Typography>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 400,
                          fontSize: "18px",
                          lineHeight: "36px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        Hey, Are you sure you want to do this?
                      </Typography>
                    </DialogContent>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                       onClick={() => logoutHandler()}
                        sx={{
                          background: "#FFFFFF",
                          color: "#634699",
                          marginBottom: "15px",
                          borderRadius: "5px",
                          textTransform: "none",
                          border: "1px solid #634699",
                          fontWeight: 400,
                          fontSize: "14px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                            color: "#634699",
                            boxShadow: "none",
                          },
                        }}
                      >
                       Yes Please
                      </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                       onClick={closeModal}
                        sx={{
                          background: "#634699",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          textTransform: "none",
                          // marginLeft: "10px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          fontWeight: 400,
                          fontSize: "14px",
                          "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
                        }}
                      >
                         No, Go Back
                      </Button>
                    </div>
                  </Dialog>
                    </Grid>
                  </Box>
                </Toolbar>
              </Grid>
            </Grid>
          </AppBar>
        </Grid>

        <Grid item className={CLASSES.profile}>
          <Card elevation={3} className={CLASSES.card}>
            <CardHeader
              className={CLASSES.cardHeader}
              avatar={
                <Badge
                  sx={badgeStyle}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      className={CLASSES.btn}
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleUpload}
                      />
                      <img
                        src={icon}
                        alt="account"
                        sx={{ width: "14px", height: "12px" }}
                      />
                    </IconButton>
                  }
                >
                  <Avatar
                    alt={profileimageurl}
                    variant="square"
                    sx={{ width: "60px", height: "60px", borderRadius: "10px" }}
                    src={profileimageurl}
                  />
                </Badge>
              }
              titleTypographyProps={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "25.2px",
              }}
              title={userdetails}
              classes={{
                action: CLASSES.action,
              }}
              // action={
              //   <Button
              //     variant="contained"
              //     onClick={() =>
              //       history.push(
              //         '/CartDetails'
              //       )}
              //     sx={{
              //       textTransform: "none",
              //       backgroundColor: "#634699",
              //       padding: "5px 25px 5px 25px ",
              //       boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              //       borderRadius: "3px",
              //       "&:hover": { backgroundColor: "#634699" },
              //       marginLeft: "16px",
              //     }}
              //     size="large"
              //   >
              //     <Cart sx={{ fontSize: 18, marginRight: "50px" }} />
              //     <Typography
              //       sx={{
              //         fontWeight: 400,
              //         fontStyle: "regular",
              //         fontSize: "14px",
              //         lineHeight: "140%",
              //       }}
              //     >
              //       Cart
              //     </Typography>
              //   </Button>
              // }
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HEADER;
