/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class multiChoiceNew.jsx
 * This JSX file is for showing the MultiChoice Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 01 Feb 2023
 */
import React, { useRef } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Card, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { McqIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { romanNumbers } from "../../../../utils/AppConstants";
const USE_STYLES = makeStyles(() => ({
  icon: {
    height: "10px",
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));
const MultiChoiceNew = ({
  selectedAnswer,
  setSelectedAnswer,
  question,
  handleChange,
  count,
  show,
  secondLevelIndex,
  type,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  
}) => {
  const CLASSES = USE_STYLES();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  useEffect(() => {
    if (level === 2) {
      setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer);
    } else if (level === 1) {
      setSelectedAnswer(questionStatusResponse[count]?.answer);
    } else if (level === 3) {
      setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, secondLevelIndex, thirdLevelIndex, level]);
  return (
    <Grid container sx={{ marginTop: "2%" }}>
      <Grid item xs={1.5} md={1.5} />
      <Grid item xs={5.2} md={7.3}>
        {/* <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "10px",
          }}
        >
          1.
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "16px",
            lineHeight: "150%",
            paddingBottom: "15px",
          }}
        >
          Choose the correct Answer:
        </Typography> */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingTop:type==="text"?"0px":"10px"
          }}
        >
          {" "}
          {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
        </Typography>{" "}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "16px",
            lineHeight: "150%",
            paddingBottom: type === "text" ? "0px" : "20px",
          }}
        >
          {parse(question?.questiontext || question?.question || question?.subquestiontext || question?.submqtext)}
        </Typography>
        <Grid item container className={CLASSES.divContainer} />
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "5px",
          }}
        >
          Options:
        </Typography>
        {(question?.answers || question?.mcqanswers || question?.mcqoptions).map((option, index) => {
          return parseInt(question.single) === 1 || !question.single ? (
            <Box
              key={index}
              onClick={() => {
                handleChange(index);
              }}
              sx={{
                background: selectedAnswer === index ? "#634699" : "#FFFFFF",
                boxShadow: !show
                  ? "none"
                  : selectedAnswer === index
                  ? "1px 7px 25px #634699"
                  : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                padding: "1%",
                fontWeight: "400",
                color: selectedAnswer === index ? "#FFFFFF" : "#000000",
                width: "250px",
                margin: "1% 0% 0% 0%",
                display: "flex",
                alignItems: "center",
                cursor: !show ? "auto" : "pointer",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: !show ? "none" : "#634699",
                  color: !show ? "none" : "#FFFFFF",
                },
              }}
            >
              <McqIcon
                sx={{
                  stroke: selectedAnswer === index ? "#FFFFFF" : "#634699",
                }}
                className={CLASSES.icon}
              />
              <Box>{parse(option.answer || option)}</Box>
            </Box>
          ) : (
            <Box
              key={index}
              onClick={() => {
                handleChange(index);
              }}
              sx={{
                background: selectedAnswer.includes(index) ? "#634699" : "#FFFFFF",
                boxShadow: !show
                  ? "none"
                  : selectedAnswer.includes(index)
                  ? "1px 7px 25px #634699"
                  : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                padding: "1%",
                fontWeight: "400",
                color: selectedAnswer.includes(index) ? "#FFFFFF" : "#000000",
                width: "250px",
                margin: "1% 0% 0% 0%",
                display: "flex",
                alignItems: "center",
                cursor: !show ? "auto" : "pointer",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: !show ? "none" : "#634699",
                  color: !show ? "none" : "#FFFFFF",
                },
              }}
            >
              <McqIcon
                sx={{
                  stroke: selectedAnswer.includes(index) ? "#FFFFFF" : "#634699",
                }}
                className={CLASSES.icon}
              />
              <Box>{parse(option.answer || option)}</Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};
export default MultiChoiceNew;
