import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const WarningAlert = ({ open, handleAlert }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleAlert('cancel')}
      PaperProps={{ style: { padding: 10 } }}
      disableEscapeKeyDown
    >
      <DialogTitle>End Test</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to leave this test?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
       
        <Button
          variant='contained'
          onClick={() => handleAlert('exit')}
          size='small'
          sx={{
            textTransform: 'none',
            color: '#fff',
            backgroundColor: '#634699',
            margin: '5px',
            '&:hover': { backgroundColor: '#634699' },
          }}
        >
          Yes
        </Button>
        <Button
          variant='contained'
          onClick={() => handleAlert('cancel')}
          size='small'
          sx={{
            textTransform: 'none',
            color: '#fff',
            backgroundColor: '#634699',
            margin: '5px',
            '&:hover': { backgroundColor: '#634699' },
          }}
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningAlert;
