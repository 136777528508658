import React from "react";
import EssayNew from "./essayNew";
import MatchNew from "./MatchNew";
import MultiChoiceNew from "./multiChoiceNew";
import ShortAnswerNew from "./ShortAnswerNew";
import TrueFalseNew from "./TrueFalseNew";
import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { alphabets, romanNumbers } from "../../../../utils/AppConstants";

const Text = ({
  selectedAnswer,
  setSelectedAnswer,
  question,
  show,
  count,
  secondLevelIndex,
  attemptidd,
  thirdLevelIndex,
  setthirdLevelIndex,
  handleChange,
  level,
  type,
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  return (
    <div>
      <Grid container sx={{}}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.5} md={7.5}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "14px",
              lineHeight: "140%",
              paddingTop: type === "text" ? "0px" : "10px",
            }}
          >
            {" "}
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : `${alphabets[secondLevelIndex]})`}
          </Typography>{" "}
          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {parse(question?.submqtext)}
          </Typography>
          <Grid item xs={1.5} md={1.5} />
          <Grid item xs={5.2} md={7.3}></Grid>
        </Grid>
      </Grid>

      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "text" ? (
        <Text
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "multichoice" ? (
        <MultiChoiceNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "truefalse" ? (
        <TrueFalseNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          thirdLevelIndex={thirdLevelIndex}
          show={true}
          type="text"
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "match" ? (
        <MatchNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          secondLevelIndex={secondLevelIndex}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "shortanswer" ? (
        <ShortAnswerNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          count={count}
          level={level}
          handleChange={handleChange}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "essay" ? (
        <EssayNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          count={count}
          secondLevelIndex={secondLevelIndex}
          attemptidd={attemptidd}
          handleChange={handleChange}
          level={level}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          type="text"
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Text;
