/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class MockTestForm.js
 * This JS file is for designing the MockTest registration form component
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import classes from "./login.module.css";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Dialog,
  Card,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Link, useHistory } from "react-router-dom";
import LoginPurpleBg from "../../../assets/images/login-purple-bg.png";
import LoginWhiteBg from "../../../assets/images/login-white-bg.png";
import { makeStyles } from "@mui/styles";
// import error from "../../../assets/images/error-emoji.svg";
import error from "../../../assets/images/mocktestimage.svg";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  validateUserFunction,
  resetValidateUserFunction,
} from "../../../webservice/students/actions/GuestAccessAction";
import { encrypt } from "../../../encrypt";
import LoadingSpinner from "./LoadingSpinner";
import mailicon from "../../../assets/images/MailIcon.png";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
});
const USE_STYLES = makeStyles({
  testContainer: {
    padding: "50px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  btn: {
    width: "40%",
    "@media (max-width:900px)": {
      width: "80%",
    },
  },
  leftContainer: {
    backgroundColor: "#634699",
    height: "100vh",
    borderRadius: "15px",
    // backgroundImage: `url(${LoginPurpleBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10% 50%",
    backgroundSize: "90% 100%",

    "@media (max-width:900px)": {
      height: "80vh",
    },
  },
  rightContainer: {
    height: "100vh",
    weight: "100vh",
    // backgroundImage: `url(${LoginWhiteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "99% 90%",
    backgroundSize: "25% 25%",
    "@media (max-width:900px)": {
      height: "65vh",
    },
  },
  innerLeftcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "25%",
    fontFamily: "Helvetica",
    color: "white",
    "@media (max-width:900px)": {
      height: "70vh",
      paddingLeft: "20%",
    },
  },
  innerRightcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica",
    alignItems: "center",

    "& .MuiInputBase-root": {
      fontWeight: "600",
    },

    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "0%",
    },
  },
});

function MOCKTESTFORM() {
  const CLASSES = USE_STYLES();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    fullname:"",
    email: "",
    selfphone: "",
    parentphone: "",
  });
  const history = useHistory();
  const { validateUser } = useSelector((state) => state.guestAccessState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { fullname,email, selfphone, parentphone } = state;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    setFormErrors(e);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(state));
    setIsSubmitted(true);
    setIsLoading(true);
  };
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[0-9\b\s+()-]{8,15}.$/;
    const uname = /^[a-zA-Z]+(\s[a-zA-Z]+)+$/;
    if (!uname.test(values.fullname)) {
      errors.fullname = "Enter a valid fullname";
    }
    if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email format";
    }
    if (!phoneRegex.test(values.selfphone)) {
      errors.selfphone = "Enter a valid phone number";
    }
    if (!phoneRegex.test(values.parentphone)) {
      errors.parentphone = "Enter a valid phone number";
    }
    return errors;
  };
  const onCloseHandler = () => {
    setOpen(false);
    setIsLoading(false);
    if (validateUser?.emailsent === true) {
      localStorage.setItem("userToken", encrypt(validateUser?.token));
      history.push("/login/mocktestform/otp");
    }
  };

  useEffect(() => {
    if (isSubmitted && Object.keys(formErrors).length === 0) {
      dispatch(validateUserFunction(email, selfphone, parentphone));
      if (validateUser?.emailsent === false) {
        dispatch(resetValidateUserFunction());
      }
      setOpen(true);
    }
  }, [isSubmitted, formErrors]);

  useEffect(() => {
    if (validateUser) {
      setIsLoading(false);
    }
  }, [validateUser]);

  return (
    <>
      <Grid container item>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.leftContainer}>
            <Grid
              container item
              className={CLASSES.innerLeftcontainer}
              spacing={0}
              alignItems="center"
            >
              <Grid item >
                <Grid container>
                  <Grid item container direction="column" xs={12}>
                    <form onSubmit={handleSubmit}>
                      <Grid item>
                        <Button
                          onClick={() => history.goBack()}
                          sx={{
                            textTransform: "none",
                            padding: "0px 0px 10px 0px",
                            minWidth: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "140%",
                              color: "#FFFFFF",
                              textAlign: "left",
                            }}
                          >
                            Back
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "30px",
                            lineHeight: "130%",
                            color: "#FFFFFF",
                            paddingBottom: "5px",
                          }}
                        >
                          Test Entry
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "140%",
                            color: "#A0AEC0",
                          }}
                        >
                          Enter The following Details
                        </Typography>
                      </Grid>
                      <br />
                      <Grid item>
                        <CssTextField
                          sx={{
                            input: { color: "#fff" },
                            width: "25vw",
                            marginBottom: "15px",
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          InputLabelProps={{ style: { color: "#A0AEC0" } }}
                          label="Your Full Name"
                          required
                          // disabled
                          fullWidth
                          id="fullname"
                          name="fullname"
                          value={fullname}
                          onChange={handleChange}
                        />
                        <p
                          style={{
                            color: "red",
                            textAlign: "left",
                            marginTop: "-15px",
                            fontSize: "12px",
                          }}
                        >
                          {formErrors.fullname}
                        </p>
                      </Grid>
                      <br />
                      <Grid item>
                        <CssTextField
                          sx={{
                            input: { color: "#fff" },
                            width: "25vw",
                            marginBottom: "15px",
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          InputLabelProps={{ style: { color: "#A0AEC0" } }}
                          label="Your Email Address"
                          required
                          fullWidth
                          id="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                        <p
                          style={{
                            color: "red",
                            textAlign: "left",
                            marginTop: "-15px",
                            fontSize: "12px",
                          }}
                        >
                          {formErrors.email}
                        </p>
                      </Grid>
                      <br />
                      <Grid item>
                        <CssTextField
                          sx={{
                            input: { color: "#fff" },
                            width: "25vw",
                            marginBottom: "15px",
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          InputLabelProps={{ style: { color: "#A0AEC0" } }}
                          label="Phone Number"
                          required
                          fullWidth
                          id="selfphone"
                          name="selfphone"
                          value={selfphone}
                          onChange={handleChange}
                        />
                        <p
                          style={{
                            color: "red",
                            textAlign: "left",
                            marginTop: "-15px",
                            fontSize: "12px",
                          }}
                        >
                          {formErrors.selfphone}
                        </p>
                      </Grid>
                      <br />
                      <Grid item>
                        <CssTextField
                          sx={{
                            input: { color: "#fff" },
                            width: "25vw",
                            marginBottom: "15px",
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          InputLabelProps={{ style: { color: "#A0AEC0" } }}
                          label="Parent's Contact Number"
                          required
                          fullWidth
                          id="parentphone"
                          name="parentphone"
                          value={parentphone}
                          onChange={handleChange}
                        />
                        <p
                          style={{
                            color: "red",
                            textAlign: "left",
                            marginTop: "-15px",
                            fontSize: "12px",
                          }}
                        >
                          {formErrors.parentphone}
                        </p>
                      </Grid>
                      <br />
                      <Grid item>
                        <Button
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#634699",
                            textTransform: "none",
                            borderRadius: "5px",
                            width: "25vw",
                            "&:hover": { backgroundColor: "#FFFFFF" },
                            ["@media (max-width:700px)"]: { width: "68vw" },
                          }}
                          type="submit"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "150%",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",

                              color: "#634699",
                            }}
                          >
                            Get Started
                          </Typography>
                        </Button>
                        {
                          // isLoading ? <LoadingSpinner /> :
                          validateUser?.emailsent === true ||
                          validateUser?.emailsent === false ? (
                            <Dialog
                              open={open}
                              // onClose={onCloseHandler}
                              PaperProps={{
                                style: {
                                  maxHeight: "none",
                                },
                              }}
                              BackdropProps={{ invisible: true }}
                            >
                              <Grid container item xs={12}>
                                <Card
                                  className={CLASSES.testContainer}
                                  sx={{ minWidth: 600 }}
                                >
                                  <Grid
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <img src={mailicon} alt="mailicon" />
                                  </Grid>
                                  <br />

                                  <Typography
                                  variant="h1"
                                    sx={{
                                      color: "#634699",
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      lineHeight: "160%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {validateUser?.emailsent === true ? (
                                      <p>
                                        {validateUser?.emailsent === true &&
                                          validateUser.message}
                                      </p>
                                    ) : (
                                      <p>
                                        {validateUser?.emailsent === false &&
                                          validateUser.message}
                                      </p>
                                    )}
                                  </Typography>
                                  <Grid
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                  <Button
                  onClick={onCloseHandler}
                  variant="contained"
                  sx={{
                    backgroundColor: "#634699",
                    textTransform: "none",
                    borderRadius:"5px",
                    marginTop: "20px",
                    paddingLeft:"20%",
                    paddingRight:"20%",
                    "&:hover": { backgroundColor: "#634699" },
                  }}
                >
                 Ok
                </Button></Grid>
                                </Card>
                              </Grid>
                            </Dialog>
                          ) : null
                        }
                      </Grid>

                      <Grid item>
                        <Typography className={classes.regtext}>
                          &nbsp;&nbsp; Don 't have an account? &nbsp;
                          <Link
                            to="/signup"
                            style={{ textDecoration: "none", color: "#FFFFFF" }}
                          >
                            <b>Sign Up</b>
                          </Link>
                        </Typography>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
                {isLoading ? <LoadingSpinner /> : null}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.rightContainer}>
            <Grid
              container item
              className={CLASSES.innerRightcontainer}
              spacing={0}
              alignItems="center"
            >
              <Grid container item align="center">
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item>
                    <img src={error} alt="pic" width="350px" height="380px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default MOCKTESTFORM;
