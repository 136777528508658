/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class QuestionScreen.js
 * This JS file created for handling the Question view screen.
 *
 * @author tincy.thomas@centelon.com
 * @since 31 May 2022
 */

import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Button,
  Checkbox,
  Pagination,
  Dialog,
  ListItem,
} from "@mui/material";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuestionList,
  saveAnswer,
  setAnswersArray,
  fetchQuizStatus,
} from "../../../../webservice/students/actions/TestAttemptAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import MultiChoice from "./MultiChoice";
import Essay from "./Essay";
import ShortAnswer from "./ShortAnswer";
import TrueFalse from "./TrueFalse";
import { makeStyles } from "@mui/styles";
import WarningModal from "./WarningModal";
import { useBeforeUnload } from "../../../../utils/customHooks";
import WarningAlert from "./WarningAlert";
import Subquestion from "./Subquestion";
import Match from "./Match";
import QuestionSideBar from "./QuestionSideBar";

const USE_STYLES = makeStyles({
  btn: {
    width: "55%",
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "#634699",
      color: "#fff",
      "&:hover": { backgroundColor: "#634699" },
    },
  },
});
const QUESTION_SCREEN = (props) => {
  const classes = USE_STYLES();
  const params = useParams();
  const quizid = params.quizid;
  const dispatch = useDispatch();
  const history = useHistory();

  const { questionList } = useSelector((state) => state.testAttemptState);
  const { answerList, answerSaved } = useSelector(
    (state) => state.testAttemptState
  );
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const { userid } = useSelector((state) => state.authState);
  const [currentQuestion, setCurrentQuestion] = React.useState(
    quizStatus.status === "inprogress" && quizStatus?.answer
      ? quizStatus?.answer?.length - 1
      : 0
  );
  const [currentQuestionType, setCurrentQuestionType] = React.useState("");
  const [answers, setAnswers] = React.useState(quizStatus?.answer);
  const [currentAnswer, setCurrentAnswer] = React.useState("");
  const [currentFiles, setCurrentFiles] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlertModal, setAlertModal] = React.useState(false);
  const [userAttemptID, setUserAttemptID] = React.useState(0);

  const maxQuestions = questionList && questionList?.length;
  const attemptidd = quizStatus?.attemptid
    ? quizStatus?.attemptid
    : startattempt?.attempt.id;

  var ctrlKeyDown = false;

  const keydown = (e) => {
    if (
      (e.which || e.keyCode) == 116 ||
      ((e.which || e.keyCode) == 82 && ctrlKeyDown)
    ) {
      // Pressing F5 or Ctrl+R
      e.preventDefault();
    } else if ((e.which || e.keyCode) == 17) {
      // Pressing  only Ctrl
      ctrlKeyDown = true;
    }
  };

  const keyup = (e) => {
    // Key up Ctrl
    if ((e.which || e.keyCode) == 17) ctrlKeyDown = false;
  };

  useBeforeUnload({
    when: true,
    message: "Are you sure you want to leave this test?",
    action: () => props?.onRefreshPage(userAttemptID),
  });

  useEffect(() => {
    dispatch(fetchQuizStatus(userid, quizid));
  }, []);

  useEffect(()=>{
    dispatch(fetchQuestionList(quizid,userAttemptID));

  },[userAttemptID])

  useEffect(() => {
    dispatch(fetchUserId());
    // dispatch(fetchQuestionList(quizid,userAttemptID));

    setOpenAlert(true);
    window.addEventListener("keydown", keydown);
    window.addEventListener("keyup", keyup);
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    return () => {
      window.removeEventListener("keydown", keydown);
      window.removeEventListener("keyup", keyup);
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (attemptidd) {
      setUserAttemptID(attemptidd);
    }
  }, [attemptidd]);

  useEffect(() => {
    props?.takeCurrentQuestion(
      questionList && questionList[currentQuestion].slot,
      maxQuestions
    );

    if (answers?.length) {
      let index = answers.findIndex(
        (x) => x.questionid === questionList[currentQuestion]?.questionid
      );
      if (index > -1) {
        if (answers[index]?.questiontype === "multianswer") {
          setCurrentAnswer(answers[index]?.clozeanswers || "");
        } else if (answers[index]?.questiontype === "match") {
          setCurrentAnswer(answers[index]?.matchanswer || "");
        } else {
          setCurrentAnswer(answers[index]?.answer || "");
        }
      } else {
        setCurrentAnswer("");
      }
    } else {
      setCurrentAnswer("");
    }
  }, [currentQuestion, answers]);

  useEffect(() => {
    if (answers?.length) {
      let index = answers.findIndex(
        (x) => x.questionid === questionList[currentQuestion]?.questionid
      );

      if (answers[index]?.questiontype === "essay") {
        if (index > -1) {
          setCurrentFiles(answers[index]?.files || []);
        } else {
          setCurrentFiles([]);
        }
      }
    }
  }, [answers, currentQuestion]);

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      setCurrentQuestionType(
        questionList && questionList[currentQuestion]?.qtype
      );
    return () => {
      isMounted = false;
    };
  }, [currentQuestion, questionList]);

  const resetQuestion = () => {
    setCurrentQuestionType("");
    setCurrentAnswer("");
    setCurrentFiles([]);
  };

  const HANDLE_PREV_BUTTON = () => {
    resetQuestion();
    setCurrentQuestion((prevActiveQuestion) => prevActiveQuestion - 1);
  };

  const HANDLE_NEXT_BUTTON = () => {
    resetQuestion();
    setCurrentQuestion((prevActiveQuestion) => prevActiveQuestion + 1);
  };

  const handlePaginationChange = (event, value) => {
    resetQuestion();
    setCurrentQuestion(value - 1);
  };

  const collectAnswer = (id, selectedanswer, isUpload, uploadedFiles, type) => {
    dispatch(
      saveAnswer(
        attemptidd,
        questionList && questionList[currentQuestion]?.slot,
        questionList && questionList[currentQuestion]?.qtype,
        selectedanswer,
        id,
        isUpload,
        uploadedFiles
      )
    );
  };

  const submit = () => {
    setModalOpen(false);
    dispatch(setAnswersArray(answers));
    setCurrentQuestion(null);
    setCurrentQuestionType("");
    props?.onConfirmation("submitted");
  };

  const review = () => {
    setModalOpen(false);
    props?.onConfirmation("ongoing");
  };

  const HANDLE_SUBMIT_BUTTON = () => {
    if (!answers || answers.length === 0) {
      setModalOpen(true);
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
      submit();
    }
  };

  useEffect(() => {
    if (answerSaved) {
      dispatch(fetchQuizStatus(userid, quizid));
    }
  }, [answerSaved]);

  useEffect(() => {
    if (quizStatus?.answer?.length) setAnswers(quizStatus?.answer);
  }, [quizStatus?.answer]);

  const handleAlertModal = (action) => {
    if (action === "show") {
      setAlertModal(true);
      return false;
    } else if (action === "cancel") {
      setAlertModal(false);
    } else if (action === "exit") {
      setAlertModal(false);
      setOpenAlert(false);
      HANDLE_SUBMIT_BUTTON();
      // history.goBack();
    }
  };

  const handleReviewStatus = () => {
    const test = props.reviewArray.map((item, idx) => {
      if(idx===currentQuestion) {
        return !item;
      } else {
        return item;
      }
    })
    props.changeReviewArray(test);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "24px",
            lineHeight: "33.6px",
          }}
        >
          Question {questionList && questionList[currentQuestion]?.slot}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {currentQuestionType === "multichoice" ? (
          <MultiChoice
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            setSelectedAnswer={(value, isUpload, type) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload,
                type
              )
            }
          />
        ) : currentQuestionType === "essay" ? (
          <Essay
            attemptidd={attemptidd}
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            selectedFiles={currentFiles}
            setSelectedAnswer={(value, isUpload, uploadedFiles) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload,
                uploadedFiles
              )
            }
          />
        ) : currentQuestionType === "shortanswer" ? (
          <ShortAnswer
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            setSelectedAnswer={(value, isUpload) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload
              )
            }
          />
        ) : currentQuestionType === "truefalse" ? (
          <TrueFalse
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            setSelectedAnswer={(value, isUpload) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload
              )
            }
          />
        ) : currentQuestionType === "multianswer" ? (
          <Subquestion
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            setSelectedAnswer={(value, isUpload) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload
              )
            }
          />
        ) : currentQuestionType === "match" ? (
          <Match
            question={questionList[currentQuestion]}
            selectedAnswer={currentAnswer}
            setSelectedAnswer={(value, isUpload) =>
              collectAnswer(
                questionList[currentQuestion]?.questionid,
                value,
                isUpload
              )
            }
          />
        ) : null}
      </Grid>
      <Grid item xs={8}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleReviewStatus}
              sx={{
                color: "#634699",
                "&.Mui-checked": {
                  color: "#634699",
                },
              }}
              checked={props.reviewArray[currentQuestion]}
            />
          }
          label="Mark for review"
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          onClick={() => HANDLE_PREV_BUTTON()}
          disabled={currentQuestion === 0}
          sx={{
            textTransform: "none",
            color: "#634699",
            backgroundColor: "#E2DDEC",
            margin: "5px",
            "&:hover": { backgroundColor: "#E2DDEC" },
          }}
          size="small"
        >
          Prev
        </Button>

        <Button
          variant="contained"
          onClick={() => HANDLE_NEXT_BUTTON()}
          disabled={currentQuestion === maxQuestions - 1}
          sx={{
            textTransform: "none",
            color: "#634699",
            backgroundColor: "#E2DDEC",
            margin: "5px",
            "&:hover": { backgroundColor: "#E2DDEC" },
          }}
          size="small"
        >
          Next
        </Button>
      </Grid>
      <Grid item xs={8} />
      <Grid item xs={4}>
        <Button
          onClick={() => HANDLE_SUBMIT_BUTTON()}
          variant="contained"
          className={classes.btn}
          sx={{
            textTransform: "none",
            backgroundColor: "#634699",
            padding: "5px 32px",
            "&:hover": { backgroundColor: "#634699" },
          }}
          size="large"
        >
          Submit Test
        </Button>
      </Grid>
      <Grid item xs={0} md={6} />
      <Grid item xs={5} md={1}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "normal",
            color: "#634699",
            fontSize: "1rem",
            width: "6.32vw",
            height: "2.66vh",
          }}
        >
          Questions
        </Typography>
      </Grid>
      <Grid item xs={0} md={1} />
      <Grid item xs={5} md={4}>
        <Pagination
          className={classes.pagination}
          count={maxQuestions}
          variant="outlined"
          shape="rounded"
          page={currentQuestion + 1}
          hideNextButton={true}
          hidePrevButton={true}
          onChange={handlePaginationChange}
        />
      </Grid>
      <Dialog open={modalOpen}>
        <WarningModal onSubmit={submit} onReview={review} />
      </Dialog>

      {/* Test Exit Alert */}
      <WarningAlert open={showAlertModal} handleAlert={handleAlertModal} />
      <Prompt
        when={openAlert}
        // message='Are you sure you want to leave this test?'
        message={() => handleAlertModal("show")}
      />
      <QuestionSideBar/>
    </Grid>
  );
};

export default QUESTION_SCREEN;
