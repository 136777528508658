/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestDetail.js
 * This JS file is intended to create a component used for creating a layout for listing the tests under one test package.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import { Button, Box, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TestDetailCard from "./TestDetailCard";
import TestList from "./TestList";
import CustomizeGrid from "./CustomizeGrid";
import { Route, Switch } from "react-router-dom";
import TestCustomize from "./TestCustomize";
import { Scrollbars } from "react-custom-scrollbars-2";

const USE_STYLES = makeStyles({
  testDetailContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
});

const TEST_DETAIL = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  return (
    <Scrollbars style={{ height: 500 }}>
      <Box px={2}>
        <Grid item xs={12}>
          <Button
            sx={{ color: "#634699", fontWeight: "bold", textTransform: "none" }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Grid>
        <Grid container spacing={2} className={CLASSES.testDetailContainer}>
          <Grid item xs={12}>
            <TestDetailCard />
          </Grid>

          <Grid item xs={12}>
            <Switch>
              <Route path="/test-packages/:id/testcustomize">
                <TestCustomize />
              </Route>
              <Route path="/test-packages/:id">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TestList />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomizeGrid />
                  </Grid>
                </Grid>
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
};

export default TEST_DETAIL;
