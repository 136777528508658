/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestCode.js
 * This JS file is for designing the layout for test verification page.
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 11 oct 2022
 */
import React, { useEffect } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LoginPurpleBg from "../../../assets/images/login-purple-bg.png";
import LoginWhiteBg from "../../../assets/images/login-white-bg.png";
import { makeStyles } from "@mui/styles";
import error from "../../../assets/images/testverificationimage.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeTestStatus } from "../../../webservice/students/actions/TestAttemptAction";
import { startQuizAttempt } from "../../../webservice/students/actions/TestAttemptAction";
import { fetchQuestionList } from "../../../webservice/students/actions/TestAttemptAction";
import { fetchQuizStatus } from "../../../webservice/students/actions/TestAttemptAction";
const USE_STYLES = makeStyles({
  btn: {
    width: "40%",
    "@media (max-width:900px)": {
      width: "80%",
    },
  },
  leftContainer: {
    backgroundColor: "#634699",
    height: "100vh",
    borderRadius: "15px",
    // backgroundImage: `url(${LoginPurpleBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10% 50%",
    backgroundSize: "90% 100%",

    "@media (max-width:900px)": {
      height: "80vh",
    },
  },
  rightContainer: {
    height: "100vh",
    weight: "100vh",
    // backgroundImage: `url(${LoginWhiteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "99% 90%",
    backgroundSize: "25% 25%",
    "@media (max-width:900px)": {
      height: "65vh",
    },
  },
  innerLeftcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "25%",
    fontFamily: "Helvetica",
    color: "white",
    "@media (max-width:900px)": {
      height: "70vh",
      paddingLeft: "20%",
    },
  },
  innerRightcontainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica",
    alignItems: "center",

    "& .MuiInputBase-root": {
      fontWeight: "600",
    },

    "@media (max-width:900px)": {
      height: "50vh",
      paddingLeft: "0%",
    },
  },
});

const TestVerificationScreen = () => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { validateTestCode } = useSelector((state) => state.guestAccessState);
  const { validateUser } = useSelector(
    (state) => state.guestAccessState
  );
  let history = useHistory();
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const attemptidd = quizStatus?.attemptid
    ? quizStatus?.attemptid
    : startattempt?.attempt?.id;
  const handleClick = () => {
    dispatch(fetchQuestionList(validateTestCode.quizid, attemptidd));
    dispatch(changeTestStatus(false));
    history.push({
      pathname: '/my-test-packages/startTest',
      state: validateTestCode
    });
  }
  useEffect(() => {
    dispatch(startQuizAttempt(validateTestCode.quizid));
    dispatch(fetchQuizStatus(validateUser.userid, validateTestCode.quizid));
  }, [dispatch, validateTestCode.quizid, validateUser.userid])
  return (
    attemptidd ?
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.leftContainer}>
            <Grid
              container
              className={CLASSES.innerLeftcontainer}
              spacing={0}
              alignItems="center"
            >
              <Grid item xs>
                <Grid container>
                  <Grid item container direction="column" xs={12}>
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "35px",
                          lineHeight: "130%",
                          color: "#FFFFFF",
                        }}
                      >
                        Hi {validateTestCode?.user} !
                      </Typography>
                    </Grid>

                    <Grid item></Grid>

                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14.0303px",
                          lineHeight: "140%",
                          color: "#FFFFFF",
                        }}
                      >
                        It seems you are appearing for quiz{" "}
                        {validateTestCode?.quizname}
                        <br />
                        If YES ! please click on begin test to initiate the{" "}
                        <br />
                        test.{" "}
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item>
                      <Button onClick={() => { handleClick() }}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          color: "#634699",
                          textTransform: "none",
                          borderRadius: "5px",
                          width: "21.1vw",
                          "&:hover": { backgroundColor: "#FFFFFF" },
                          ["@media (max-width:700px)"]: { width: "68vw" },
                        }}
                        variant="contained"
                      >
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "150%",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",

                            color: "#634699",
                          }}
                        >
                          Begin Test
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={CLASSES.rightContainer}>
            <Grid
              container
              className={CLASSES.innerRightcontainer}
              spacing={0}
              alignItems="center"
            >
              <Grid container align="center">
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item>
                    <img src={error} alt="pic" width="270px" height="280px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      : ""
  );
};

export default TestVerificationScreen;
