import { GET_METHOD } from "./ApiMethods";
import { MoodleURL } from "../config";
import { decrypt } from "../../../encrypt";

export const validateUserApi = async (email, selfphone, parentphone) => {
  try {
    let adminToken = await GET_METHOD(
      `/login/token.php?service=portal&username=${process.env.REACT_APP_ADMIN_USERNAME}&password=${process.env.REACT_APP_ADMIN_PASSWORD}`
    );
    let token = adminToken.data.token;
    let response = await GET_METHOD(
      `${MoodleURL(
        token
      )}&wsfunction=block_autocreate_validateuser&moodlewsrestformat=json&email=${email}&parentphone=${parentphone}&phone=${selfphone}&service=por`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};

export const validateOtpRequest = async (otp, userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=block_autocreate_verifyotp&moodlewsrestformat=json&userid=${userid}&otp=${otp}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};

export const validateTestCodeRequest = async (testCode, userid) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);
    let response = await GET_METHOD(
      `${MoodleURL(
        d
      )}&wsfunction=block_autocreate_validatequizcode&moodlewsrestformat=json&userid=${userid}&code=${testCode}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};
