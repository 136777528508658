/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class schedule.js
 * This JS file is for showing the list of scheduled meetings with the tutor.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */
import React, { Fragment } from "react";
import ScheduleIcon from "../../../assets/images/Upcomingtask.png";

import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ExpandIcon } from "../../../assets/icons";
import {
  Card,
  Box,
  Typography,
  Grid,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  CardContent,
  Collapse,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PENDINGTESTS from "../test/packages/PendingTests";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { fetchEnrolledTests } from "../../../webservice/students/actions/MyTestPackageAction";
import { fetchTestDetails } from "../../../webservice/students/actions/MyTestPackageAction";

const EXPAND_MORE = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const USE_STYLES = makeStyles({
  card: {
    width: "100%",
    height: "383.48px",
    margin: "auto",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
  avatar: {
    borderRadius: "5px",
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
  },
});

const UPCOMINGTASKS = () => {
  const classes = USE_STYLES();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(-1);
  const HANDLE_EXPAND_CLICK = (index, id) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
    dispatch(fetchTestDetails(id, userid));
  };
  const { userid } = useSelector((state) => state.authState);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  const { contentList } = useSelector((state) => state.mytestpackagestate);

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Typography
              style={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "25.2px",
                paddingLeft: "21px",
                paddingTop: "28px",
              }}
            >
              Upcoming Tasks
            </Typography>

            <Scrollbars style={{ height: 330 }}>
              {testList && testList.length
                ? testList.map((test, indextest) => {
                    return (
                      test.progress < 100 && (
                        <Fragment key={test.id}>
                          <List
                            key={test.id}
                            sx={{
                              width: "100%",

                              bgcolor: "background.paper",
                            }}
                          >
                            <Grid container>
                              <Grid item md={12}>
                                <Card>
                                  <CardContent>
                                    <ListItem>
                                      <Grid item md={2}>
                                        <ListItemAvatar>
                                          <Avatar
                           sx={{ width: 30, height: 30 }}
                                            className={classes.avatar}
                                            alt="Task Icon"
                                            src={ScheduleIcon}
                                          ></Avatar>
                                        </ListItemAvatar>
                                      </Grid>
                                      <Grid item md={8}>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              sx={{
                                                color: "#634699",
                                                fontWeight: 700,
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                marginLeft:"10px",
                                              }}
                                            >
                                              {test.fullname}
                                            </Typography>
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={2}>
                                        <Box
                                          display="flex"
                                          justifyContent="flex-end"
                                        >
                                          <EXPAND_MORE
                                            expand={expanded === indextest}
                                            onClick={() =>
                                              HANDLE_EXPAND_CLICK(
                                                indextest,
                                                test.id
                                              )
                                            }
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                          >
                                            {" "}
                                            <ExpandIcon sx={{ fontSize: 20 }} />
                                          </EXPAND_MORE>
                                        </Box>
                                      </Grid>
                                    </ListItem>
                                    {contentList && contentList.length
                                      ? contentList.map(
                                          (contentData, index) => {
                                            return (
                                              <Fragment key={index}>
                                                <Grid item xs={12}>
                                                  {contentData.modules ? (
                                                    contentData.modules.map(
                                                      (module) => (
                                                        <Collapse
                                                          key={module.id}
                                                          in={
                                                            expanded ===
                                                            indextest
                                                          }
                                                          timeout="auto"
                                                          unmountOnExit
                                                        >
                                                          {module.modname ===
                                                            "quiz" &&
                                                            (module.completiondata &&
                                                            module
                                                              .completiondata
                                                              .state === 0 ? (
                                                              <Card
                                                                sx={{
                                                                  backgroundColor:
                                                                    "#E2DDEC",
                                                                  marginBottom:
                                                                    "10px",
                                                                }}
                                                              >
                                                                <CardContent>
                                                                  <PENDINGTESTS
                                                                    module={
                                                                      module
                                                                    }
                                                                  />
                                                                </CardContent>
                                                              </Card>
                                                            ) : null)}
                                                        </Collapse>
                                                      )
                                                    )
                                                  ) : (
                                                    <Collapse
                                                      in={expanded === index}
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <Card
                                                        sx={{
                                                          backgroundColor:
                                                            "#E2DDEC",
                                                          marginBottom: "30px",
                                                        }}
                                                      >
                                                        <CardContent>
                                                          <Typography
                                                            sx={{
                                                              fontWeight: 700,
                                                              fontSize: "16px",
                                                              fontStyle:
                                                                "regular",
                                                              lineHeight:
                                                                "22px",
                                                              color: "#634699",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            No Tests Found
                                                            <br />
                                                          </Typography>
                                                        </CardContent>
                                                      </Card>
                                                    </Collapse>
                                                  )}
                                                </Grid>
                                              </Fragment>
                                            );
                                          }
                                        )
                                      : null}

                                    <Divider variant="inset" component="li" />
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </List>
                        </Fragment>
                      )
                    );
                  })
                : null}
            </Scrollbars>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default UPCOMINGTASKS;
