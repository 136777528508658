/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class essayNew.jsx
 * This JSX file is for showing the Essay Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 02 Feb 2023
 */

import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import parse from "html-react-parser";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import FileInputNew from "./FileInputNew";
import { formatBytes, urltoFile, getThumbnail, romanNumbers } from "../../../../utils/AppConstants";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import "./MathType.css";
const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ck-editor__main > .ck-editor__editable": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "&.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        color: "#634699",
        minHeight: "150.1px",
        borderColor: "#634699",
        border: "0.771363px solid #634699",
        borderRadius: "0px 0px 9.25635px 9.25635px",
        background: "transparent",
        "&.ck .ck-placeholder:before": {
          color: "#634699",
          fontWeight: 400,
          fontStyle: "regular",
          fontSize: "13.8568px",
          lineHeight: "140%",
        },
      },
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "9.25635px 9.25635px 0px 0px",
    },
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

const EssayNew = ({
  attemptidd,
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  show,
  secondLevelIndex,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  type,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editor, setEditor] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [isfileuploaded, setisfileuploaded] = React.useState(0);
  const { uploadfileList } = useSelector((state) => state.testAttemptState);
  const [fileSizeLimit, setFileSizeLimit] = React.useState({
    name: "",
    value: 0,
  });
  React.useEffect(() => {
    if (question && question?.essayoptions?.length) {
      let essayoptions = question?.essayoptions[0];
      if (essayoptions?.maxbytes) {
        let limit = formatBytes(essayoptions?.maxbytes);

        setFileSizeLimit({
          name: limit,
          value: essayoptions?.maxbytes,
        });
      }
    }
  }, [question]);
  const handleThumbnailDelete = (index) => {
    let array = [];
    if (level === 1) {
      array = [...questionStatusResponse[count].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].uploadedFiles = array;
    } else if (level === 2) {
      array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles = array;
    } else if (level === 3) {
      array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles = array;
    }

    dispatch(fetchQuestionStatus(questionStatusResponse));
  };
  const collectFile = async (fileSelected, filesLength) => {
    let isUpload = 0;
    if (filesLength > 0) {
      isUpload = 1;
    } else {
      isUpload = 0;
    }
    setisfileuploaded(isUpload);
    // const data = editor?.getData();
    let formData = new FormData();
    let filesArray = [...files, ...fileSelected];
    let upload = false;
    let fileObjectPromises = [];

    filesArray.forEach((file, index) => {
      if (file?.basedata) {
        fileObjectPromises.push(urltoFile(file.basedata, file.filename, file.filetype));
      }
    });
    const fileObjectArray = await Promise.all(fileObjectPromises);
    fileObjectArray.forEach((fileObject, index) => {
      if (questionStatusResponse[count].type === "multiquestion") {
        formData.append(`upload${attemptidd}_${question.submqid}file${index + 1}`, fileObject);
      } else {
        formData.append(`upload${attemptidd}file${index + 1}`, fileObject);
      }
      if (filesArray.length === index + 1) {
        upload = true;
      }
    });

    if (upload) {
      setSelectedAnswer(formData);
      // setSelectedAnswer(encodeURIComponent(data), isUpload, formData);
    }
  };
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const [editorData, setEditorData] = useState("");
  const HANDLE_CHANGE = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    handleChange(data);
  };
  useEffect(() => {
    // console.log(
    //   "second index",
    //   thirdLevelIndex,
    //   "secondLevelIndex",
    //   secondLevelIndex,
    //   "level",
    //   level
    // );
    if (level === 2) {
      setEditorData(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer);
    } else if (level === 1) {
      setEditorData(questionStatusResponse[count]?.answer);
    } else if (level === 3) {
      setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
    }
  }, [count, secondLevelIndex, thirdLevelIndex, level]);
  const text = question?.submqtext || question?.questiontext || question.question;

  const replacements = {
    "«": "<",
    "»": ">",
    "§": "&",
    "¨": '"',
    "`": "'",
  };

  const reversedText = text.replace(/(«|»|§|¨|`)|(#\w+)/g, (match) => {
    return replacements[match] || match;
  });

  return (
    <div>
      <Grid container sx={{ marginTop: "2%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.2} md={7.3}>
          {/* <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            1.
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "15px",
            }}
          >
            Answer the following questions:-
          </Typography> */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "14px",
              lineHeight: "140%",
              paddingTop:type==="text"?"0px":"10px"
            }}
          >
            {" "}
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
          </Typography>{" "}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {/* {parse(
              question?.submqtext || question?.questiontext || question.question
            )} */}
            {parse(reversedText)}
          </Typography>
          {show ? (
            <React.Fragment>
              <Grid item container className={classes.divContainer} />
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingBottom: "5px",
                }}
              >
                Answer
              </Typography>
              <Grid item xs={8.8}>
                <div className={classes.richTextEditor}>
                  <CKEditor
                    data={editorData}
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          "heading",
                          "MathType",
                          "ChemType",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "imageUpload",
                          "mediaEmbed",
                          "insertTable",
                          "blockQuote",
                          "undo",
                          "redo",
                        ],
                      },
                      language: "en",
                    }}
                    onReady={(editor) => {
                      console.log("editor ready to use", editor);
                    }}
                    onChange={(event, editor) => {
                      HANDLE_CHANGE(event, editor);
                    }}
                    // config={{ placeholder: "Please type your answer here...." }}
                  />
                </div>
              </Grid>
              <Grid container sx={{ marginTop: "3%" }}>
                <Grid item xs={9.6} sx={{ display: "flex" }}>
                  {(
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles ||
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles ||
                    questionStatusResponse[count]?.uploadedFiles
                  ).map((file, index) => {
                    return (
                      <div key={index}>
                        <Box
                          sx={{
                            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.13)",
                            marginLeft: "6px",
                            background: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <IconButton
                              position="fixed"
                              sx={{
                                width: "20px",
                                height: "20px",
                                margin: "8px 2px 8px 10px",
                                backgroundColor: "#634699",
                                border: "#634699",
                                color: "white",
                                cursor: "auto",
                                "&:hover": {
                                  backgroundColor: "#634699",
                                },
                              }}
                            >
                              <Typography
                                variant="h2"
                                sx={{
                                  fontWeight: 400,
                                  fontStyle: "regular",
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "140%",
                                  padding: "7px",
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </IconButton>
                            <Typography
                              variant="h2"
                              sx={{
                                fontWeight: 400,
                                fontStyle: "regular",
                                color: "#634699",
                                fontSize: "14px",
                                lineHeight: "140%",
                                padding: "7px",
                              }}
                            >
                              {file?.name}
                            </Typography>{" "}
                            <IconButton
                              onClick={() => {
                                handleThumbnailDelete(index);
                              }}
                              position="fixed"
                              sx={{
                                width: "20px",
                                height: "20px",
                                margin: "8px",
                                backgroundColor: "transparent",
                                color: "#634699",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <CloseRoundedIcon sx={{ fontSize: 20, fontWeight: "700" }} />
                            </IconButton>
                          </div>
                        </Box>{" "}
                      </div>
                    );
                  })}
                  <FileInputNew
                    onUpload={collectFile}
                    fileSizeLimit={fileSizeLimit}
                    attemptidd={attemptidd}
                    count={count}
                    secondLevelIndex={secondLevelIndex}
                    thirdLevelIndex={thirdLevelIndex}
                    level={level}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default EssayNew;
