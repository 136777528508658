/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/ScheduleAction.js
 * This JS file is intended to provide the action methods with respect to schedule
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  postMeetingSchedule,
  getBookedTimesApi,
  getFilteredEventsApi,
} from "../api/ScheduleApi";

export const createSchedule =
  (userid, packageid, sectionid, title, newCreatedTimestamp, value) =>
  async (dispatch) => {
    try {
      const postSchedule = await postMeetingSchedule(
        userid,
        packageid,
        sectionid,
        title,
        newCreatedTimestamp,
        value
      );
      dispatch({ type: "CREATE_SCHEDULE", payload: postSchedule });
    } catch (error) {
      return error;
    }
  };

export const getBookedTimes =
  (userid, packageid, sectionid, dateStamp) => async (dispatch) => {
    try {
      const getTimes = await getBookedTimesApi(
        userid,
        packageid,
        sectionid,
        dateStamp
      );
      dispatch({ type: "GET_TIMES", payload: getTimes });
    } catch (error) {
      return error;
    }
  };

export const getFilteredEvents =
  (userid, test, section, month, year) => async (dispatch) => {
    try {
      const getEvents = await getFilteredEventsApi(
        userid,
        test,
        section,
        month,
        year
      );
      dispatch({ type: "GET_EVENTS", payload: getEvents });
    } catch (error) {
      return error;
    }
  };

  export const resetBookedTimesState =
  () => async (dispatch) => {
    try {
      dispatch({ type: "RESET_BOOKED_TIMES", payload: null });
    } catch (error) {
      return error;
    }
  };
