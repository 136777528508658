import React from "react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import error from "../../../../assets/images/NotifyErrorIcon.png";

const USE_STYLES = makeStyles(() => ({
  testContainer: {
    padding: "85px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
}));

const Fileuploadvalidatescreen = ({ onClose, message }) => {
  const CLASSES = USE_STYLES();
  return (
    <div>
      <Grid container item xs={12}>
        <Card className={CLASSES.testContainer} sx={{ minWidth: 600 }}>
          <Grid display="flex" alignItems="center" justifyContent="center">
            <img src={error} alt="error" />
          </Grid>
          <br />
          <Typography
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "140%",
              textAlign: "center",
            }}
          >
            Error !
          </Typography>

          <Typography
            sx={{
              color: "#634699",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "160%",
              textAlign: "center",
            }}
          >
           {message}
          </Typography>

          <br />
          <br />

          <Grid display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{
                textTransform: "none",
                backgroundColor: "#FFFFFF",
                border: "1px solid #634699",
                color: "#634699",
                padding: "5px 30px",
                boxShadow: "none",
                "&:hover": { backgroundColor: "#ffffff", boxShadow: "none" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "14px",
                  lineHeight: "150%",
                }}
              >
                Ok, Understood
              </Typography>
            </Button>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default Fileuploadvalidatescreen;
