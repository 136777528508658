import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import MultiChoiceNew from "./multiChoiceNew";
import TrueFalseNew from "./TrueFalseNew";
import MatchNew from "./MatchNew";
import ShortAnswerNew from "./ShortAnswerNew";
import EssayNew from "./essayNew";
import SubQuestionLevelcounts from "./SubQuestionLevelcounts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { useEffect } from "react";
import { calculateQuestionLevels } from "../../../../utils/AppConstants";
import { colorPicker } from "../../../../utils/AppConstants";
const MultiAnswer = ({
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  secondLevelIndex,
  setsecondLevelIndex,
  attemptidd,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  setLevel,
  handleNext,
}) => {
  const dispatch = useDispatch();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  return (
    <div>
      <Grid container sx={{ marginTop: "4%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.5} md={7.5}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            {" "}
            Q{question?.slot ? question.slot : ""})
          </Typography>{" "}
          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "30px",
            }}
          >
            {parse(question?.clozemainquestiontext || question.question || question.questiontext)}
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "5px",
            }}
          >
            Read the above passage and answer the following questions
          </Typography>
          <br />
          <Grid item xs={1.5} md={1.5} />
          <Grid item xs={5.2} md={7.3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {(question?.clozeoptions || question?.clozesubquestions).map((i, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => {
                      handleNext(index);
                      setLevel(calculateQuestionLevels(questionStatusResponse, count, index, 0));
                      setsecondLevelIndex(index);
                    }}
                    sx={{
                      background: colorPicker(
                        questionStatusResponse[count]?.subQuestions[index]?.status,
                        secondLevelIndex,
                        index
                      )[0],
                      color: colorPicker(
                        questionStatusResponse[count]?.subQuestions[index]?.status,
                        secondLevelIndex,
                        index
                      )[2],
                      borderRadius: "3px ",
                      textTransform: "none",
                      padding: "3% 2% 2% 3%",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.03)",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontStyle: "regular",
                        fontSize: "12px",
                        lineHeight: "140%",
                      }}
                    >
                      {question.slot}({index + 1})
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {question?.clozeoptions[secondLevelIndex]?.qtype === "multichoice" ? (
        <MultiChoiceNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.clozeoptions[secondLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="multianswer"
          level={level}
        />
      ) : (
        ""
      )}
      {question?.clozeoptions[secondLevelIndex]?.qtype === "truefalse" ? (
        <TrueFalseNew
        show={true}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.clozeoptions[secondLevelIndex]}
        />
      ) : (
        ""
      )}
      {question?.clozeoptions[secondLevelIndex]?.qtype === "match" ? (
        <MatchNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.clozeoptions[secondLevelIndex]}
        />
      ) : (
        ""
      )}
      {question?.clozeoptions[secondLevelIndex]?.qtype === "shortanswer" ? (
        <ShortAnswerNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.clozeoptions[secondLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          count={count}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          handleChange={handleChange}
          level={level}
        />
      ) : (
        ""
      )}

      {question?.clozeoptions[secondLevelIndex]?.qtype === "essay" ? (
        <EssayNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.clozeoptions[secondLevelIndex]}
          count={count}
          attemptidd={attemptidd}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          handleChange={handleChange}
          secondLevelIndex={secondLevelIndex}
        />
      ) : (
        ""
      )}
      {/* {question?.mqsubquestions[secondLevelIndex]?.submqqtype === "text" ? (
        <Grid item xs={5.5} md={9}>
          <SubQuestionLevelcounts
            question={question?.mqsubquestions[secondLevelIndex]}
            setsecondLevelIndex={setsecondLevelIndex}
            secondLevelIndex={secondLevelIndex}
            count={count}
            thirdLevelIndex={thirdLevelIndex}
            setthirdLevelIndex={setthirdLevelIndex}
          />
        </Grid>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default MultiAnswer;
