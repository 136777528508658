/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class CustomizeGrid.js
 * This JS file is intended for creating a customize button container
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, Button, Typography } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import { EmptyIcon, Emptycarticon, ExpandIcon } from "../../../../assets/icons";

const mystyles = {
  color: "#CBC3DD",
  fontWeight: 700,
  fontSize: "40.8775px",
  lineHeight: "36.94px",
  textAlign: "center",
};
const mystyle = {
  color: "#CBC3DD",
  fontWeight: 700,
  fontSize: "16.38px",
  lineHeight: "36.94px",
  textAlign: "center",
};

const USE_STYLES = makeStyles({
  customizeGridContainer: {
    border: "3px dotted #634699",
    borderRadius: "4px",
    height: "400px",
    marginTop: "10px",
  },
  divStyle: {
    margin: "0 auto",
  },
  icon: {
    color: "gray",
  },
});

const CUSTOMIZE_GRID = () => {
  const CLASSES = USE_STYLES();
  const HISTORY = useHistory();
  const params = useParams();
  const courseid = params.id;

  return (
    <Box px={5}>
      <Grid container className={CLASSES.customizeGridContainer}>
        <Grid item container alignItems="center" justifyContent="center">
          <div className={CLASSES.divStyle}>
          <Grid item >
            <Grid item display="flex" justifyContent="center">
            <Emptycarticon sx={{ fontSize: 32,borderRadius:"3px"}}/> 

              <Typography sx={mystyles}>
              Empty Cart 
              {/* <ExpandIcon sx={{ fontSize: 30,transform: "rotate(180deg)",borderRadius:"3px"}} /> */}
              </Typography></Grid>
              <Typography sx={mystyle}>
              Please Drag and drop the required Topics Here 
              </Typography>
            </Grid><br/>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Button
                  fullWidth
                  onClick={() =>
                    HISTORY.push(`/test-packages/${courseid}/testcustomize`)
                  }
                  sx={{
                    padding: "6px 50px 6px 50px",
                    color: "#fff",
                    textAlign: "center",
                    borderColor: "#634699",
                    textTransform: "none",
                    backgroundColor: "#634699",
                    "&:hover": {
                      backgroundColor: "#7C55C5",
                      borderColor: "#7C55C5",
                    },
                  }}
                >
                  Customize
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CUSTOMIZE_GRID;
