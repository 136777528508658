/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class OrderBill.js
 * This JS file is intended for creating component that showing the total amount of the purchase.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React from "react";
import { Grid, Card, Typography, Divider, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseList,
  fetchCourseById,
  resetCustomizedList,
  fetchCourseContents,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

const ORDERBILL = () => {
  const [price, setPrice] = useState(0);
  let history = useHistory();
  const params = useParams();
  const courseid = params.id;
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const { userid } = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const discount = 0;
  const { coursecontents } = useSelector(
    (state) => state.testPackageListReducer
  );

  const { customizedList } = useSelector(
    (state) => state.testPackageListReducer
  );

  const getListToRender = () => {
    return customizedList ? customizedList : coursecontents;
  };

  useEffect(() => {
    let TotalPrice = 0;
    getListToRender() &&
      getListToRender().map(
        (item) => item.sectioncost && (TotalPrice += parseInt(item.sectioncost))
      );
    setPrice(TotalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchCourseById(courseid));
    dispatch(fetchCourseContents(courseid, userid));
    return () => {
      dispatch(fetchCourseById(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customizeHandler = () => {
    dispatch(resetCustomizedList());
    history.push(`/test-packages/${courseid}`);
  };
  return (
    <Grid container>
      {courseList && courseList?.length
        ? courseList.map((list, index) => {
            return (
              list.id === parseInt(courseid) && (
                <Fragment key={index}>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container>
                        <Grid item xs={6} p={3}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "24px",
                              lineHeight: "33.6px",
                            }}
                          >
                            {list.displayname}
                            <span style={{ color: "#634699" }}></span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          display="flex"
                          justifyContent="flex-end"
                          p={3}
                        >
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "40px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(price)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} p={3}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "24px",
                              lineHeight: "33.6px",
                            }}
                          >
                            Care
                            <br />
                            <span
                              style={{
                                color: "#634699",
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "33.6px",
                              }}
                            >
                              DISCOUNT
                            </span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          display="flex"
                          justifyContent="flex-end"
                          p={3}
                        >
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "40px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(discount)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" sx={{ bgcolor: "#634699" }} />
                      <Grid container>
                        <Grid item xs={6} p={3}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "24px",
                              lineHeight: "33.6px",
                            }}
                          >
                            Total
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          display="flex"
                          justifyContent="flex-end"
                          p={3}
                        >
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "40px",
                              lineHeight: "56px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: list.paypalenrollments[0]?.currency,
                              maximumSignificantDigits: 3,
                            }).format(price + discount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Button
                      onClick={() =>
                        history.push(
                          `/test-packages/checkout/${courseid}/${
                            price + discount
                          }`
                        )
                      }
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: 1,
                        backgroundColor: "#634699",
                        textTransform: "none",
                        "&:hover": { backgroundColor: "#7C55C5" },

                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "19.3651px",
                          lineHeight: "140%",

                          color: "#F1F1F1",
                        }}
                      >
                        Checkout
                      </Typography>
                    </Button>
                    <Button
                      onClick={customizeHandler}
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: 1,
                        borderColor: "#634699",
                        color: "#634699",
                        textTransform: "none",
                        border: "1px solid #634699",
                        borderRadius: "3px",
                        "&:hover": { borderColor: "#7C55C5" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "19.3651px",
                          lineHeight: "140%",
                          color: "#634699",
                        }}
                      >
                        Customize
                      </Typography>
                    </Button>
                  </Grid>
                </Fragment>
              )
            );
          })
        : null}
    </Grid>
  );
};

export default ORDERBILL;
