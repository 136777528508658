/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class CenableStudents.js
 * This JS file is intended to display the details of students along with their images and basic information
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy manu.nair@centelon.com
 * @modifiedDate 17 Feb 2022
 */

import { useEffect } from "react";
import { Card, Typography, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../webservice/students/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";

const USE_STYLES = makeStyles({
  card: {
    marginBottom: "20px",
    width: "100%",
    margin: "auto",
    height: "377.5px",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
});

const CENABLE_STUDENTS = () => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.dashboardState);
  const { userid } = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(fetchUserId());
    dispatch(fetchUserList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container>
          <Card className={CLASSES.card}>
            <CardContent>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#634699",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    paddingLeft: "21px",
                    paddingTop: "28px",
                  }}
                >
                  Cenable Students
                </Typography>
              </Grid>
              <Scrollbars style={{ height: 330 }}>
                <Grid item xs={12}>
                  <List>
                    {userList &&
                      userList.map((student) => (
                        <ListItem key={student.id}>
                          <ListItemAvatar>
                            <Avatar
                              variant="square"
                              sx={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "12px",
                              }}
                              alt={student.profilepic}
                              src={student.profilepic}
                            ></Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  color: "#828282",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                {student.firstname} {student.lastname}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{
                                  color: "#718096",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                }}
                              >
                                {student.email}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Scrollbars>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CENABLE_STUDENTS;
