import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import timerup from "../../../../assets/images/TimerUp.png";
import timerbg from "../../../../assets/images/timerbg.png";

const USE_STYLES = makeStyles({
  warningScreenContainer: {
    paddingLeft: "10%",
    paddingTop: "15px",
  },
  imageContainer: {
    paddingTop: "15vh",
    "@media (max-width:900px)": {
      paddingTop: "10vh",
    },
  },
  textContainer: {
    backgroundImage: `url(${timerbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "25% 25%",
    "@media (max-width:900px)": {
      height: "65vh",
    },
  },
});

const TimerExpired = (props) => {
  const classes = USE_STYLES();
  return (
    <Grid container className={classes.warningScreenContainer}>
      <Grid item xs={7} />
      <Grid item xs={0}>
        <Typography
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "24px",
            lineHeight: "33.6px",
          }}
        >
          Time &nbsp;
          <span
            style={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "red",
              fontSize: "24px",
              lineHeight: "33.6px",
            }}
          >
            00:00
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs={12}
        className={classes.imageContainer}
      >
        <Grid item xs={12} md={6}>
          <img src={timerup} alt="timer up" />
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "4.87rem",
              lineHeight: "140%",
            }}
          >
            Oops...
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#98A0AB",

              fontSize: "2.19rem",
            }}
          >
            Looks like times up !
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#98A0AB",

              fontSize: "1.5rem",
            }}
          >
            Click on the{" "}
            <span
              style={{
                fontWeight: 400,
                fontStyle: "regular",
                color: "#634699",
                fontSize: "1.5rem",
              }}
            >
              submit button below to proceed.
            </span>
          </Typography>
          <Button
            variant="contained"
            onClick={() => props?.submit()}
            sx={{
              width: "9.25vw",
              "@media (max-width:900px)": {
                width: "55vw",
              },
              textTransform: "none",
              color: "#fff",
              backgroundColor: "#634699",
              margin: "5px",
              "&:hover": { backgroundColor: "#634699" },
            }}
            size="small"
          >
            Smash Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimerExpired;
