import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { useEffect } from "react";
import { calculateQuestionLevels, romanNumbers } from "../../../../utils/AppConstants";
import { alphabets } from "../../../../utils/AppConstants";
import { colorPicker } from "../../../../utils/AppConstants";
const SubQuestionLevelcounts = ({
  question,
  secondLevelIndex,
  setsecondLevelIndex,
  count,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  setLevel,
  handleNext,questionslot
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  return (
    <div>
      <Grid container sx={{ marginTop: "3%" }}>
        <Grid item xs={6} md={8} />
        <Grid item xs={4} sx={{ display: "flex" }}>
          <IconButton
            disabled={thirdLevelIndex > 0 ? false : true}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#634699",
              "&:hover": { backgroundColor: "transparent" },
            }}
            size="small"
            onClick={() => {
              handleNext("", "previous");
            }}
          >
            <ArrowLeftIcon sx={{ fontSize: 20 }} />
          </IconButton>
          {(question?.children).map((i, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  handleNext(index, "");
                  setLevel(calculateQuestionLevels(questionStatusResponse, count, secondLevelIndex, index));
                  setthirdLevelIndex(index);
                }}
                sx={{
                  background: colorPicker(
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[index]?.status,
                    thirdLevelIndex,
                    index
                  )[0],
                  color: colorPicker(
                    questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[index]?.status,
                    thirdLevelIndex,
                    index
                  )[2],
                  borderRadius: "2px ",
                  textTransform: "none",
                  padding: "8px",
                  cursor: "pointer",
                  border: "1px solid #634699",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontStyle: "regular",
                    fontSize: "10px",
                    lineHeight: "140%",
                    letterSpacing:"2px"
                  }}
                >
                  {questionslot}{alphabets[secondLevelIndex]}({romanNumbers[index]})
                </Typography>
              </Box>
            );
          })}

          <IconButton
            variant="contained"
            onClick={() => {
              handleNext("", "next");
            }}
            disabled={thirdLevelIndex !== question?.children.length - 1 ? false : true}
            sx={{
              textTransform: "none",
              color: "#634699",
              "&:hover": { backgroundColor: "transparent" },
            }}
            size="small"
          >
            <ArrowRightIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SubQuestionLevelcounts;
