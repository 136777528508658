/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/main/index.js
 * This JS file is intended to give an overall layout to the entire application and main entry point to the layout folder.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 25 Feb 2022
 */

import SIDEBAR from "./sidebar";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dashboard from "../../views/students/dashboard";
import { Route, Switch } from "react-router-dom";
import TestPackages from "../../views/students/course/content";
import TestDetail from "../../views/students/course/content/TestDetail";
import OrderConfirmation from "../../views/students/course/content/OrderConfirmation";
import CHECKOUT from "../../views/students/course/content/checkout";
import MyTestPackages from "../../views/students/test/packages";
import MySchedule from "../../views/students/schedule";
import HEADER from "./header";
import TAKETESTINDEX from "../../views/students/dashboard/TakeTestIndex";
import Success from "../../views/students/schedule/success";
import { useEffect } from "react";
import ORDERBILL from "../../views/students/course/content/OrderBill";
import TestCustomize from "../../views/students/course/content/TestCustomize";
import SuccessAttempt from "../../views/students/test/packages/SuccessAttempt";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserId } from "../../webservice/students/actions/AuthAction";
import { fetchEnrolledTests } from "../../webservice/students/actions/MyTestPackageAction";
import React, { useState } from "react";
import GuardedRoute from "./GuardedRoute";
import NotFound from "../../views/main/NotFound";
import ProfileScreen from "./header/ProfileScreen";
import TestResults from "../../views/students/testresults";
import Notifications from "./header/Notifications";
import CartDetails from "./header/CartDetails";
import Needhelpsection from "./sidebar/Needhelpsection";
import HelpIndex from "./sidebar/HelpIndex";

const USE_STYLES = makeStyles({
  mainContainer: {
    height: "100vh",
    overflow: "hidden",
  },
  rightContainer: {
    margin: "10px",
  },
  leftContainer: {
    flex: 0,
  },
});

const MAIN = () => {
  const classes = USE_STYLES();
  const userToken = window.localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  const [count, setCount] = useState(false);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  
  useEffect(() => {
    if (!userToken) window.location.replace("/login");
  }, [userToken]);

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  useEffect(() => {
    if (testList?.length > 0) {
      setCount(false);
    } else if (testList?.length === 0) {
      setCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testList]);

  return (
    <>
      {userToken && (
        <Grid container spacing={2} className={classes.mainContainer}>
          <Grid item xs className={classes.leftContainer}>
            <SIDEBAR />
          </Grid>
          <Grid item xs className={classes.rightContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HEADER />
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  {testList !== null && (
                    <GuardedRoute
                      path="/dashboard"
                      component={Dashboard}
                      auth={count}
                    />
                  )}
                  <Route path="/login/taketestindex">
                    <TAKETESTINDEX />
                  </Route>
                  <Route path="/test-packages/checkout/:id/:price">
                    <CHECKOUT />
                  </Route>
                  <Route path="/schedule/success/:timestamp">
                    <Success />
                  </Route>
                  <Route path="/test-packages/orderconfirmation/:id">
                    <OrderConfirmation />
                  </Route>
                  <Route path="/test-packages" exact>
                    <TestPackages />
                  </Route>
                  <Route path="/test-packages/:id">
                    <TestDetail />
                  </Route>
                  <Route path="/my-test-packages/success">
                    <SuccessAttempt />
                  </Route>
                  <Route path="/Testresults">
                    <TestResults />
                  </Route>
                  {(testList !== null ) && (
                    <GuardedRoute
                      path="/my-test-packages"
                      component={MyTestPackages}
                      auth={count}
                    />
                  )}
                  {testList !== null && (
                    <GuardedRoute
                      path="/schedule"
                      component={MySchedule}
                      auth={count}
                    />
                  )}
                  <Route path="/orderbills/:id">
                    <ORDERBILL />
                  </Route>
                  <Route path="/test-packages/:id/testcustomize">
                    <TestCustomize />
                  </Route>
                  <Route path="/notfound">
                    <NotFound />
                  </Route>
                  <Route path="/profilescreen">
                    <ProfileScreen />
                  </Route>
                  <Route path="/Notifications">
                    <Notifications />
                  </Route>
                  <Route path="/CartDetails">
                    <CartDetails />
                  </Route>
                  <Route path="/HelpIndex">
                    <HelpIndex />
                  </Route>
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default MAIN;
