/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/reducers/index.js
 * This JS file is intended to provide the reducer for dashboard page.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

const initialState = {
  userList: null,
  taskList: null,
  scheduleList: null,
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        userList: action.payload.data,
      };

    case "GET_TASKS":
      return {
        ...state,
        taskList: action.payload.data,
      };
    case "GET_SCHEDULE":
      return {
        ...state,
        scheduleList: action.payload.data.events,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
