/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class FinalReview.jsx
 * This JSX file is for showing the Review Question Status
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 06 Feb 2023
 */

import { makeStyles } from "@mui/styles";
import { Typography, Button, Grid, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import questionfindingicon from "./../../../../assets/images/questionfindingicon.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSelector } from "react-redux";
import SuccessAttemptNew from "./SuccessAttemptNew";
import { LinearProgress, linearProgressClasses, Box } from "@mui/material";
import Timer from "./timer";
import LOGO from "../../../main/ui_component/logo";
import { useDispatch } from "react-redux";
import { finishAttempt } from "../../../../webservice/students/actions/TestAttemptAction";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { saveAnswer } from "../../../../webservice/students/actions/TestAttemptAction";
const legend = ["Answered", "Not Answered", "Answer Later", "Not Visited"];
const sliderData = [
  "Total Questions",
  "Answered",
  "Answer Later",
  "Not Answered",
  "Not Visited",
];
const colorPicker = (status, count, index) => {
  if (status === "Not Visited") {
    return ["#FFFFFF", "1px solid #634699", "#000000", "6vw", "88px"];
  } else if (status === "Answered") {
    return ["#37AB97", "none", "#FFFFFF", "6vw"];
  } else if (status === "Not Answered") {
    return ["#ED6182", "none", "#FFFFFF", "12.9vw"];
  } else if (status === "Answer Later") {
    return ["#FFC15C", "none", "#000000", "6vw"];
  }
};
const USE_STYLES = makeStyles(() => ({
  examContainer: {
    paddingLeft: "25%",
    "@media (max-width:900px)": {
      paddingLeft: "5%",
    },
    paddingTop: "30px",
    paddingBottom: "100px",
  },
  testContainer: {
    padding: "3%",
    width: "698px",
    boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    background: "#FFFFFF",
  },

  questionSwitch: {
    fontSize: "12px",
    width: "30px",
    textAlign: "center",
    borderRadius: "2px",
    margin: "5px",
    alignItems: "center",
    padding: "4px",
  },
  header: {
    backgroundColor: "#634699",
    borderRadius: "3px",
    color: "#ffffff",
    fontSize: "21px",
    height: "80px",
  },
  logoContainer: {
    marginLeft: "72px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  linearProgress: {
    marginTop: "12px",
    height: 10,
    borderRadius: 5,
    border: "1px solid #EFF1F3",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#634699",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#EFF1F3",
      borderRadius: 6,
    },
  },
}));

const FinalReview = ({
  open,
  setOpen,
  count,
  quiz,
  submit,
  setSubmit,
  setCount,
  question,
}) => {
  const [sliderCount, setSliderCount] = useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const CLASSES = USE_STYLES();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const { questionList } = useSelector((state) => state.testAttemptState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);
  const { startattempt } = useSelector((state) => state.testAttemptState);
  const [review, setReview] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const handleNext = (index, action) => {
    setCount(index);
    setSubmit("In progress");
  };
  const attemptidd = quizStatus?.attemptid
    ? quizStatus?.attemptid
    : startattempt?.attempt?.id;
  const dispatch = useDispatch();
  const HANDLE_SUBMIT_BUTTON = () => {
    dispatch(finishAttempt(attemptidd));
    setModalOpen(true);
  };
  useEffect(() => {
    // Hide the browser's back button
    window.history.pushState(null, null, window.location.pathname);
    
    // Listen for the user's attempt to navigate back
    window.addEventListener("popstate", onPopState);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  const onPopState = (event) => {
    // Prevent the user from navigating back
    window.history.pushState(null, null, window.location.pathname);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return modalOpen ? (
    <SuccessAttemptNew count={count} open={open} setOpen={setOpen} />
  ) : (
    <React.Fragment>
      {/* <Box className={CLASSES.logoContainer}>
        <LOGO width="12%" height="12%" />
      </Box> */}

      {/* <Grid container className={CLASSES.header} xs={12} md={12} sm={12}>
        <Grid item xs={0} md={1.5} sm={1.5} />

        <Grid item xs={4} md={2} sm={2} sx={{ paddingTop: "6px" }}>
          {" "}
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",

              color: "#EFF1F3",
            }}
          >
            {quiz.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "140%",

              color: "#EFF1F3",
            }}
          >
            Time &nbsp; <Timer quiz={quiz} type="header" />
          </Typography>
        </Grid>
        <Grid item xs={0} md={0.5} sm={0.5} />

        <Grid item xs={7} md={5} sm={7}>
          <Grid container xs={12}>
            <Grid item xs={11.5}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingTop: "10px",
                  color: "#EFF1F3",
                }}
              >
                Progress
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "140%",

                  paddingTop: "10px",
                  color: "#EFF1F3",
                  textAlign: "center",
                }}
              >
                {count + 1}/{questionList.length}
              </Typography>
            </Grid>
          </Grid>
          <LinearProgress
            className={CLASSES.linearProgress}
            variant="determinate"
            value={((count + 1) / questionList.length) * 100}
          />
        </Grid>

        <Grid item xs={1} />
      </Grid> */}
      <Grid container item xs={12} className={CLASSES.examContainer}>
        <Card className={CLASSES.testContainer}>
          <Grid item
            container
            xs={12}
            sx={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <Grid item xs={3} />

            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "140%",
                  textAlign: "center",
                }}
              >
                <img
                  src={questionfindingicon}
                  alt="pic"
                  width="15vw"
                  height="15vh"
                />
                &nbsp;{questionStatusResponse.length}&nbsp;Questions
              </Typography>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <div style={{ padding: "0px 50px 0px 55px", marginBottom: "20px" }}>
            <Grid
              container
              item
              xs={12}
              // spacing={1.5}
              sx={{
                border: "1px solid #634699",
                borderRadius: "5px",
                padding: "15px 0px 15px 35px",
              }}
            >
              {legend.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={3} sx={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          minWidth: "22px",
                          height: "22px",
                          padding: "4px",
                          backgroundColor: colorPicker(item, 0, index)[0],
                          border: colorPicker(item, 0, index)[1],
                          // marginLeft: colorPicker(item, 0, index)[4],
                          borderRadius: "2px",
                          boxShadow: "none",
                          cursor: "default",
                          "&:hover": {
                            backgroundColor: colorPicker(item, 0, index)[0],
                            opacity: 5,
                            boxShadow: "none",
                          },
                        }}
                      ></Button>{" "}
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: "12px",
                          // lineHeight: "200%",
                          padding: "3px",
                          opacity: 0.5,
                        }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
          <div>
            <Grid>
              <Grid container item xs={12}>
                <Grid item xs={3.8} />
                <Grid item xs={6}>
                  <IconButton
                    disabled={sliderCount > 0 ? false : true}
                    variant="contained"
                    onClick={() => {
                      setSliderCount(sliderCount - 1);
                    }}
                    sx={{
                      textTransform: "none",
                      color: "#634699",
                      backgroundColor: "#fff",
                      margin: "5px",
                      "&:hover": { backgroundColor: "#fff" },
                    }}
                    size="small"
                  >
                    <ArrowLeftIcon sx={{ fontSize: 30 }} />
                  </IconButton>

                  <span
                    style={{
                      color: "#000000",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "258%",
                      textAlign: "center",
                    }}
                  >
                    {sliderData[sliderCount]}
                  </span>

                  <IconButton
                    variant="contained"
                    onClick={() => {
                      setSliderCount(sliderCount + 1);
                    }}
                    disabled={
                      sliderCount < sliderData.length - 1 ? false : true
                    }
                    sx={{
                      textTransform: "none",
                      color: "#634699",
                      backgroundColor: "#fff",
                      margin: "5px",
                      "&:hover": { backgroundColor: "#fff" },
                    }}
                    size="small"
                  >
                    <ArrowRightIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Grid>
              </Grid>

              <div style={{ paddingLeft: "140px", paddingRight: "140px" }}>
                <Grid container item justifyContent="center">
                  {questionStatusResponse?.map((question, index) => {
                    if (
                      sliderData[sliderCount] === question.status ||
                      sliderData[sliderCount] === "Total Questions"
                    ) {
                      return (
                        <Grid
                          onClick={() => {
                            handleNext(index,"sidebar");
                          }}
                          className={CLASSES.questionSwitch}
                          item
                          key={index}
                          sx={{
                            background: colorPicker(
                              question.status,
                              count,
                              index
                            )[0],
                            cursor: "pointer",
                            marginRight: "12px",
                            border: colorPicker(
                              question.status,
                              count,
                              index
                            )[1],
                            color: colorPicker(
                              question.status,
                              count,
                              index
                            )[2],
                          }}
                        >
                          {index + 1}
                        </Grid>
                      );
                    } else {
                      return false;
                    }
                  })}
                </Grid>
              </div>
              <br />
              <br />
              <Grid
                container item
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => HANDLE_SUBMIT_BUTTON()}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    width: "150px",
                    height: "30px",
                    backgroundColor: "#634699",
                    border: "1px solid #634699",
                    borderRadius: "5px",
                    minWidth: "4px",
                    align: "center",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#634699",
                      boxShadow: "none",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "150%",
                    }}
                  >
                    Submit Test
                  </Typography>
                </Button>
              </Grid>
              <Grid
                container item
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => {
                    setSubmit("In progress");
                  }}
                  variant="contained"
                  sx={{
                    marginTop: "12px",
                    textTransform: "none",
                    width: "150px",
                    height: "30px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #634699",
                    borderRadius: "5px",
                    minWidth: "4px",
                    align: "center",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "150%",
                    }}
                  >
                    Go Back
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </React.Fragment>
  );
};

export default FinalReview;
