import { validateUserApi,validateOtpRequest,validateTestCodeRequest } from "../api/GuestAccessApi";

export const validateUserFunction = (email, selfphone, parentphone) => async (dispatch) => {
    try {
      const validateGuestUser = await validateUserApi(email, selfphone, parentphone);
      dispatch({ type: "VALIDATE_USER", payload: validateGuestUser });
    } catch (error) {
      return error;
    }
  };

  export const resetValidateUserFunction = () => async (dispatch) => {
    try {
      dispatch({ type: "RESET_VALIDATE_USER", payload: null });
    } catch (error) {
      return error;
    }
  };

  export const validateOtpValue = (otp,userid) => async (dispatch) => {
    try {
      const validateOtpResponse = await validateOtpRequest(otp,userid);
      dispatch({ type: "VALIDATE_OTP", payload: validateOtpResponse });
    } catch (error) {
      return error;
    }
  };

  export const resetvalidateOtp = () => async (dispatch) => {
    try {
      
      dispatch({ type: "RESET_VALIDATE_OTP", payload: null });
    } catch (error) {
      return error;
    }
  };


  export const validateTestCodeValue = (testCode,userid) => async (dispatch) => {
    try {
      const validateTestCodeResponse = await validateTestCodeRequest(testCode,userid);
      dispatch({ type: "VALIDATE_TESTCODE", payload: validateTestCodeResponse });
    } catch (error) {
      return error;
    }
  };