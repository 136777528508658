/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class QuestionFeedback.js
 * This JS file is for showing the question feedbacks
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import { Fragment, React, useState } from "react";
import parse from "html-react-parser";
import { useParams, Link } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Pagination,
  FormHelperText,
} from "@mui/material";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizAttempt } from "../../../../webservice/students/actions/MyTestPackageAction";
import { makeStyles } from "@mui/styles";
import { getThumbnail } from "../../../../utils/AppConstants";

const USE_STYLES = makeStyles({
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "#634699",
      color: "#fff",
      "&:hover": { backgroundColor: "#634699" },
    },
  },
});

const QUESTIONFEEDBACK = () => {
  const classes = USE_STYLES();
  const params = useParams();
  const quizid = params.quizid;
  const questionattemtid = params.questionname;

  const { quizAttemptDetail } = useSelector(
    (state) => state.mytestpackagestate
  );
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  useEffect(() => {
    if (userid) dispatch(fetchQuizAttempt(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(parseInt(questionattemtid));
  }, [questionattemtid]);

  const HANDLECHANGE = (event, value) => {
    setPage(value);
    var url = window.location.href;
    var splitPath = url.split("/");
    splitPath[7] = value;
    url = url.replace(/\/[^/]*$/, `/${splitPath[7]}`);
    window.location.replace(url);
  };

  const HANDLEPREVCLICK = () => {
    setPage(page - 1);
    var url = window.location.href;
    var splitPath = url.split("/");
    splitPath[7] = page - 1;
    url = url.replace(/\/[^/]*$/, `/${splitPath[7]}`);
    window.location.replace(url);
  };
  const HANDLENEXTCLICK = () => {
    setPage(page + 1);
    var url = window.location.href;
    var splitPath = url.split("/");
    splitPath[7] = page + 1;
    url = url.replace(/\/[^/]*$/, `/${splitPath[7]}`);
    window.location.replace(url);
  };
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Grid container spacing={2}>
      {quizAttemptDetail && quizAttemptDetail.length
        ? quizAttemptDetail.map((quizItem, index) => {
            return (
              <Fragment key={index}>
                {quizItem.map(
                  (subItems, sIndex) =>
                    subItems.quizmoduleid === parseInt(quizid) &&
                    subItems.attemptno === 1 && (
                      <Fragment key={sIndex}>
                        <Grid item container xs={12}>
                          <Grid item xs={12} key={sIndex}>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontStyle: "regular",
                                color: "#634699",
                                fontSize: "40px",
                                lineHeight: "56px",
                              }}
                            >
                              {subItems.sectionname}
                            </Typography>
                          </Grid>
                        </Grid>

                        {subItems.questionlist.map(
                          (question, index) =>
                            parseInt(question.questionorder) ===
                              parseInt(questionattemtid) && (
                              <Fragment key={index}>
                                <Grid item container xs={12} spacing={1}>
                                  <Grid item xs={12} md={8} sm={12}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontStyle: "regular",
                                        color: "#634699",
                                        fontSize: 24,
                                      }}
                                    >
                                      Question {question.questionorder}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                  >
                                    <StarBorderPurple500OutlinedIcon />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}                                  >
                                    <Button
                                      component={Link}
                                      to="/schedule"
                                      variant="contained"
                                      sx={{
                                        textTransform: "none",
                                        backgroundColor: "#634699",
                                        "&:hover": {
                                          backgroundColor: "#634699",
                                        },
                                      }}
                                    >
                                      <Box>Schedule meeting</Box>
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Card
                                    sx={{
                                      paddingBottom: "25px",
                                      width: "100%",
                                    }}
                                  >
                                    <CardContent>
                                      <Grid container spacing={2}>
                                      {question.qtype==="multianswer"?<Grid item xs={12} md={11}>
                                          <Typography
                                            sx={{
                                              fontWeight: 700,
                                              fontStyle: "regular",
                                              color: "#634699",
                                              fontSize: 26,
                                              lineHeight: "33px",
                                              display: "block",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                             {question.question
                                          ? parse(question.question)
                                          : null}
                                          <br/>  <br/>
                                          {question?.clozesubquestions && question?.clozesubquestions?.map((clozequestion)=>
                                          <>
                                            {parse(clozequestion)} <br/>
                                          </>
                                          
                                         
                                          )}
                                          </Typography>
                                        </Grid>:
                                         <Grid item xs={12} md={11}>
                                          <Typography
                                            sx={{
                                              fontWeight: 700,
                                              fontStyle: "regular",
                                              color: "#634699",
                                              fontSize: 26,
                                              lineHeight: "33px",
                                              display: "block",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {parse(question.question.split("\n")[0])}
                                          </Typography>
                                        </Grid> }
                                        <Grid
                                          item
                                          xs={12}
                                          md={1}
                                          sm={12} 
                                          display="flex"
                                          justifyContent="flex-end"
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 700,
                                              fontStyle: "regular",
                                              color: "#634699",
                                              fontSize: 40,
                                              lineHeight: "33px",
                                            }}
                                          >
                                            {formatter.format(question.mark)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontStyle: "regular",
                                              color: "#634699",
                                              fontSize: 16,
                                            }}
                                          >
                                            Your Answer
                                          </Typography>
                                        </Grid>
                                        {question.qtype==="match"||question.qtype==="multianswer"?
                                        <Grid item xs={12}>
                                        {question.responsesummary !== null ? (
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontStyle: "regular",
                                              color: "#000",
                                              fontSize: 16,
                                              lineHeight: "22px",
                                            }}
                                          >
                                            {parse(question.responsesummary.split(";").join("<br/><br/>"))}
                                          </Typography>
                                        ) : null}
                                      </Grid>:<Grid item xs={12}>
                                          {question.responsesummary !== null ? (
                                            <Typography
                                              sx={{
                                                fontWeight: 400,
                                                fontStyle: "regular",
                                                color: "#000",
                                                fontSize: 16,
                                                lineHeight: "22px",
                                              }}
                                            >
                                              {parse(question.responsesummary)}
                                            </Typography>
                                          ) : null}
                                        </Grid>}
                                        
                                        <Grid item xs={12}>
                                          <Grid container>
                                            {question?.filesattached?.map(
                                              (file, index) => {
                                                let type = file?.filetype
                                                  ?.split("/")
                                                  ?.pop();
                                                let thumbnail = getThumbnail(
                                                  file?.fileurl ||
                                                    file?.basedata,
                                                  type
                                                );

                                                return (
                                                  <Grid
                                                    key={`thumbnail-${
                                                      index + 1
                                                    }`}
                                                    item
                                                    xs={3}
                                                  >
                                                    <img
                                                      src={thumbnail}
                                                      alt=""
                                                      width="90%"
                                                    />
                                                    <FormHelperText>
                                                      {file?.filename || ""}
                                                    </FormHelperText>
                                                  </Grid>
                                                );
                                              }
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Card
                                    sx={{ paddingBottom: "25px" }}
                                  >
                                    <CardContent>
                                      <Grid item xs={12}>
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            fontStyle: "regular",
                                            color: "#634699",
                                            fontSize: 16,
                                          }}
                                        >
                                          Correct Answer
                                        </Typography>
                                      </Grid>
                                      {question.qtype==="match"||question.qtype==="multianswer"?<Grid item xs={12}>
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            fontStyle: "regular",
                                            color: "#000",
                                            fontSize: 16,
                                            lineHeight: "22px",
                                          }}
                                        >
                                          {parse(question.rightanswer.split(";").join("<br/><br/>"))}
                                        </Typography>
                                      </Grid>:
                                      <Grid item xs={12}>
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          fontStyle: "regular",
                                          color: "#000",
                                          fontSize: 16,
                                          lineHeight: "22px",
                                        }}
                                      >
                                        {question.rightanswer}
                                      </Typography>
                                    </Grid>
                                      }
                                      
                                    </CardContent>
                                  </Card>
                                </Grid>

                                {question.comment ? (
                                  <Grid item xs={12}>
                                    <Card
                                      sx={{ paddingBottom: "25px" }}
                                    >
                                      <CardContent>
                                        <Grid item xs={12}>
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontStyle: "regular",
                                              color: "#634699",
                                              fontSize: 16,
                                            }}
                                          >
                                            The feedback
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Typography
                                            sx={{
                                              fontWeight: 400,
                                              fontStyle: "regular",
                                              color: "#000",
                                              fontSize: 16,
                                              lineHeight: "22px",
                                            }}
                                          >
                                            {parse(question.comment)}
                                          </Typography>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ) : null}

                                <Grid item xs={12} sm={12} md={12}>
                                  <Grid container columnSpacing={6} spacing={2}>
                                    <Grid item xs={4} md={10} />
                                    <Grid
                                      item
                                      xs={4}
                                      md={1}
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={HANDLEPREVCLICK}
                                        disabled={page === 1}
                                        sx={{
                                          textTransform: "none",
                                          backgroundColor: "#634699",
                                          "&:hover": {
                                            backgroundColor: "#634699",
                                          },
                                        }}
                                      >
                                        <Box>Prev</Box>
                                      </Button>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      md={1}
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={HANDLENEXTCLICK}
                                        disabled={
                                          page === subItems.questionlist.length
                                        }
                                        sx={{
                                          textTransform: "none",
                                          backgroundColor: "#634699",
                                          "&:hover": {
                                            backgroundColor: "#634699",
                                          },
                                        }}
                                      >
                                        <Box>Next</Box>
                                      </Button>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Pagination
                                        variant="outlined"
                                        shape="rounded"
                                        className={classes.pagination}
                                        count={subItems.questionlist.length}
                                        page={page}
                                        onChange={HANDLECHANGE}
                                        hideNextButton
                                        hidePrevButton
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Fragment>
                            )
                        )}
                      </Fragment>
                    )
                )}
              </Fragment>
            );
          })
        : null}
    </Grid>
  );
};

export default QUESTIONFEEDBACK;
