/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class webservice/students/actions/AuthAction.js
 * This JS file is intended to provide the action methods with respect to authentication
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 16 march 2022
 */

import {
  getLogin,
  getSignup,
  getUserId,
  getUserDetails,
  generateUserToken,
  getUserFields,
  getExistingEmail,
  getExistingUsername,
  updateUser,
  getUserImage,
} from "../api/AuthApi";

export const loginUser = (email, password) => async (dispatch) => {
  try {
    const loginResult = await getLogin(email, password);
    dispatch({ type: "GET_LOGIN", payload: loginResult });
  } catch (error) {
    return error;
  }
};

export const fetchUserId = () => async (dispatch) => {
  try {
    const userIdResult = await getUserId();
    dispatch({ type: "GET_USER_ID", payload: userIdResult });
  } catch (error) {
    return error;
  }
};

export const fetchUserDetails = () => async (dispatch) => {
  try {
    const userDetails = await getUserDetails();
    dispatch({ type: "GET_USER_DETAILS", payload: userDetails });
  } catch (error) {
    return error;
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: "LOGOUT", payload: null });
  } catch (error) {
    return error;
  }
};
export const signUp =
  (firstname, lastname, username, email, password, phone2) =>
  async (dispatch) => {
    try {
      const signupResult = await getSignup(
        firstname,
        lastname,
        username,
        email,
        password,
        phone2
      );
      dispatch({ type: "GET_SIGNUP", payload: signupResult });
    } catch (error) {
      return error;
    }
  };

export const createToken = (userid) => async (dispatch) => {
  try {
    const userTokenCreation = await generateUserToken(userid);
    dispatch({ type: "CREATE_TOKEN", payload: userTokenCreation });
  } catch (error) {
    return error;
  }
};

export const fetchUserField = (userid) => async (dispatch) => {
  try {
    const getUser = await getUserFields(userid);
    dispatch({ type: "GET_USER", payload: getUser });
  } catch (error) {
    return error;
  }
};

export const checkExistingEmail = (name, value) => async (dispatch) => {
  try {
    const getEmail = await getExistingEmail(name, value);
    dispatch({ type: "GET_EXISTING_EMAIL", payload: getEmail });
  } catch (error) {
    return error;
  }
};

export const checkExistingUsername = (name, value) => async (dispatch) => {
  try {
    const getUsername = await getExistingUsername(name, value);
    dispatch({ type: "GET_EXISTING_USERNAME", payload: getUsername });
  } catch (error) {
    return error;
  }
};

export const resetValidations = () => async (dispatch) => {
  try {
    dispatch({ type: "RESET_VALIDATIONS", payload: [] });
  } catch (error) {
    return error;
  }
};

export const resetAdminValue = () => async (dispatch) => {
  try {
    dispatch({ type: "RESET_SIGNUP_ADMINVALUE", payload: [] });
  } catch (error) {
    return error;
  }
};

export const updateUserFields = (state, userid) => async (dispatch) => {
  try {
    const updatedFields = await updateUser(state, userid);
    dispatch({ type: "UPDATE_USER", payload: updatedFields });
  } catch (error) {
    return error;
  }
};
export const updateUserPicture = (userid, formData) => async (dispatch) => {
  try {
    const updatedPicture = await getUserImage(userid, formData);
    dispatch({ type: "UPDATE_PICTURE", payload: updatedPicture });
  } catch (error) {
    return error;
  }
};
