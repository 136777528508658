/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class QuestionSideBar.js
 * This JS file is intended to reuse the sidebar component for questionscreen.
 * @author vidhyadarshini.s@centelon.com
 * @since 13 Jan 2023
 *
 */

import { makeStyles } from "@mui/styles";
import { Typography, Button, Grid, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import stopclocktimer from "./../../../../assets/images/stopclocktimer.png";
import questionfindingicon from "./../../../../assets/images/questionfindingicon.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Qsidebaropenicon from "./../../../../assets/images/Qsidebaropenicon.png";
import Qsidebarcloseicon from "./../../../../assets/images/Qsidebarcloseicon.png";
import { finishAttempt, resetAnswers } from "../../../../webservice/students/actions/TestAttemptAction";
import { useDispatch, useSelector } from "react-redux";
import Timer from "./timer";
import WarningModal from "./WarningModal";
import FinalReview from "./FinalReview";
import { calculateQuestionLevels } from "../../../../utils/AppConstants";
import { colorPicker } from "../../../../utils/AppConstants";
const drawerWidth = 325;
const legend = ["Active", "Answered", "Not Visited", "Answer Later", "Not Answered"];
const sliderData = ["Total Questions", "Answered", "Not Visited", "Answer Later", "Not Answered"];
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DRAWER_HEADER = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const USE_STYLES = makeStyles(() => ({
  answersavequestion: {
    backgroundColor: "#37AB97",
    color: "#fff",
    fontSize: "10px",
    border: "1px solid #37AB97",
    width: "30px",
    height: "30px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "5px",
  },
  questionSwitch: {
    fontSize: "12px",
    width: "30px",
    textAlign: "center",
    borderRadius: "2px",
    margin: "5px",
    alignItems: "center",
    padding: "4px",
  },
  notreviewquestion: {
    backgroundColor: "#FFC15C",
    color: "#fff",
    fontSize: "10px",
    border: "1px solid #FFC15C",
    width: "30px",
    height: "30px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "5px",
  },
  notansweredquestion: {
    backgroundColor: "#ED6182",
    color: "#fff",
    fontSize: "10px",
    border: "1px solid #ED6182",
    width: "30px",
    height: "30px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "5px",
  },
  notvisitedquestion: {
    backgroundColor: "#fff",
    color: "0fff",
    fontSize: "10px",
    border: "1px solid #634699",
    width: "30px",
    height: "30px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "5px",
  },
  drawerPaper: {
    border: "none",
    backgroundColor: "#FFFFFF",
  },
  link: {
    textDecoration: "none",
    color: "#c4c4c4",
  },
  activeClassName: {
    "& > .MuiListItem-button": {
      border: "1px solid #634699",
      borderRadius: "5px",
      color: "#000",
      backgroundColor: "#fff",

      "& .MuiListItemIcon-root": {
        backgroundColor: "#634699",
        color: "#fff",
        padding: "5px",
        borderRadius: "5px",
      },
    },
  },
  iconClass: {
    borderRadius: "5px",
    padding: "5px",
    color: "#634699",
    backgroundColor: "#fff",
    minWidth: "10px",
  },
  text: {
    "& > .MuiTypography-root": {
      padding: "15px",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  button: {
    "& > .MuiListItem-button": {
      marginLeft: "10px",
      minWidth: "3px",
    },
  },
}));

const QuestionSideBar = ({
  open,
  setOpen,
  count,
  handleNext,
  quiz,
  submit,
  setSubmit,
  setLevel,
  setCount,
  setsecondLevelIndex,
  setthirdLevelIndex,
}) => {
  const [sliderCount, setSliderCount] = useState(0);
  const CLASSES = USE_STYLES();
  const theme = useTheme();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const { questionList } = useSelector((state) => state.testAttemptState);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Drawer anchor="right" variant="permanent" open={open} classes={{ paper: CLASSES.drawerPaper }}>
        <br />
        <div style={{ padding: "10px 17px 10px 35px" }}>
          {open && (
            <Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "140%",
                    }}
                  >
                    <img src={questionfindingicon} alt="pic" width="15vw" height="15vh" />
                    &nbsp;{questionStatusResponse.length}&nbsp;Questions
                  </Typography>
                </Grid>
                <Grid item xs={3.5} />
              </Grid>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={3.5}>
                {legend?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={4} sx={{ display: "flex" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            minWidth: "23px",
                            height: "23px",
                            padding: "4px",
                            backgroundColor: colorPicker(item, 0, index)[0],
                            border: colorPicker(item, 0, index)[1],
                            borderRadius: "2px",
                            boxShadow: "none",
                            cursor: "default",
                            "&:hover": {
                              backgroundColor: colorPicker(item, 0, index)[0],
                              opacity: 5,
                              boxShadow: "none",
                            },
                          }}
                        ></Button>{" "}
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 400,
                            fontSize: "11px",
                            padding: "3px",
                            opacity: 0.5,
                          }}
                        >
                          {item}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </div>
        <DRAWER_HEADER>
          {open ? (
            <>
              <IconButton
                onClick={handleDrawerClose}
                position="fixed"
                open={open}
                sx={{
                  width: "35px",
                  height: "35px",
                  marginTop: "20px",
                  marginRight: "230px",
                  backgroundColor: "white",
                  position: "fixed",
                  right: "75px",
                  top: "172px",
                  border: "#634699",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#634699",
                  },
                }}
              >
                {theme.direction === "rtl" ? (
                  <img src={Qsidebarcloseicon} alt="close" width="30px" />
                ) : (
                  <img src={Qsidebarcloseicon} alt="close" width="30px" />
                )}
              </IconButton>
            </>
          ) : (
            <IconButton
              sx={{
                width: "35px",
                height: "35px",
                marginTop: "170px",
                marginRight: "30px",
                backgroundColor: "white",
                border: "#634699",
                color: "white",
                position: "fixed",
                right: "19px",
                top: "18px",
                "&:hover": {
                  backgroundColor: "#634699",
                },
              }}
              onClick={handleDrawerOpen}
              edge={false}
            >
              <img src={Qsidebaropenicon} alt="open" width="30px" />
            </IconButton>
          )}
        </DRAWER_HEADER>
        {open && (
          <div style={{ padding: "1px 17px 10px 35px" }}>
            <Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={0.7} />
                <Grid item xs={6}>
                  <IconButton
                    disabled={sliderCount > 0 ? false : true}
                    variant="contained"
                    onClick={() => {
                      setSliderCount(sliderCount - 1);
                    }}
                    sx={{
                      textTransform: "none",
                      color: "#634699",
                      backgroundColor: "#fff",
                      margin: "5px",
                      "&:hover": { backgroundColor: "#fff" },
                    }}
                    size="small"
                  >
                    <ArrowLeftIcon sx={{ fontSize: 30 }} />
                  </IconButton>

                  <span
                    style={{
                      color: "#000000",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "258%",
                      textAlign: "center",
                    }}
                  >
                    {sliderData[sliderCount]}
                  </span>

                  <IconButton
                    variant="contained"
                    onClick={() => {
                      setSliderCount(sliderCount + 1);
                    }}
                    disabled={sliderCount < sliderData.length - 1 ? false : true}
                    sx={{
                      textTransform: "none",
                      color: "#634699",
                      backgroundColor: "#fff",
                      margin: "5px",
                      "&:hover": { backgroundColor: "#fff" },
                    }}
                    size="small"
                  >
                    <ArrowRightIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Grid>
              </Grid>
              I
              <Grid container>
                {questionStatusResponse?.map((question, index) => {
                  if (sliderData[sliderCount] === question.status || sliderData[sliderCount] === "Total Questions") {
                    return questionList[index]?.tag === "parta" ? (
                      <Grid
                        onClick={() => {
                          handleNext(index);
                          setLevel(calculateQuestionLevels(questionStatusResponse, index, 0, 0));
                          setCount(index);
                        }}
                        className={CLASSES.questionSwitch}
                        item
                        key={index}
                        sx={{
                          background: colorPicker(question.status, count, index)[0],
                          marginRight: "12px",
                          border: colorPicker(question.status, count, index)[1],
                          color: colorPicker(question.status, count, index)[2],
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </Grid>
                    ) : (
                      ""
                    );
                  } else {
                    return false;
                  }
                })}
              </Grid>
              II
              <Grid container>
                {questionStatusResponse?.map((question, index) => {
                  if (sliderData[sliderCount] === question.status || sliderData[sliderCount] === "Total Questions") {
                    return questionList[index]?.tag === "partb" ? (
                      <Grid
                        onClick={() => {
                          handleNext(index);
                          setLevel(calculateQuestionLevels(questionStatusResponse, index, 0, 0));
                          setCount(index);
                        }}
                        className={CLASSES.questionSwitch}
                        item
                        key={index}
                        sx={{
                          background: colorPicker(question.status, count, index)[0],
                          marginRight: "12px",
                          border: colorPicker(question.status, count, index)[1],
                          color: colorPicker(question.status, count, index)[2],
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </Grid>
                    ) : (
                      ""
                    );
                  } else {
                    return false;
                  }
                })}
              </Grid>
              <br /> <br />
              <Grid container display="flex" alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    textTransform: "none",
                    width: "100px",
                    height: "30px",
                    backgroundColor: "#634699",
                    border: "1px solid #634699",
                    borderRadius: "5px",
                    minWidth: "4px",
                    align: "center",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#634699",
                      boxShadow: "none",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "140%",
                    }}
                  >
                    Go Back
                  </Typography>
                </Button>
              </Grid>
              <Grid container display="flex" alignItems="center" justifyContent="center">
                <Button
                  onClick={() => {
                    setSubmit(true);
                  }}
                  variant="contained"
                  sx={{
                    marginTop: "12px",
                    textTransform: "none",
                    width: "100px",
                    height: "30px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #634699",
                    borderRadius: "5px",
                    minWidth: "4px",
                    align: "center",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#634699",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "140%",
                    }}
                  >
                    Submit Test
                  </Typography>
                </Button>
                {/* <Dialog
                  open={modalOpen}
                  PaperProps={{
                    style: {
                      background: "#FFFFFF",
                      boxSizing: "2px 4px 18px rgba(0, 0, 0, 0.25)",
                      borderRadius: "5px",
                      position: "absolute",
                      width: "600px",
                      height: "480px",
                    },
                  }}
                >
                  <FinalReview
                    handleNext={handleNext}
                    count={count}
                    open={open}
                    setOpen={setOpen}
                  />
                </Dialog> */}
              </Grid>
            </Grid>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default QuestionSideBar;
